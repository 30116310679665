import './PageRastreio.css';
import BoxInputRastreio from '../../../Components/BoxInputRastreio/BoxInputRastreio';
import TitlePage from "../../../Components/TitlePage/TitlePage"
import Table from '../../../Components/Table/Table';

function PageRastreio() {
  const dadosExemplo = [
    {
      "CNPJ": "0029899490016",
      "IE": "(32) 3541-0312",
      "Nome fantasia": "Moveis Atenas",
      "Telefone": "Rua Expedicionário Rui Balbi",
      "Cidade/uf": "Uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0033077020084",
      "IE": "(32) 3531-2172",
      "Nome fantasia": "Akisala",
      "Telefone": "Rua Ângelo Sperandio",
      "Cidade/uf": "uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "0037970770029",
      "Nome fantasia": "VIA ENCANTO",
      "Telefone": "Rua João Groppo",
      "Cidade/uf": "uba/MG",
      "endereço": "Jardim Inês Groppo"
    },
    {
      "CNPJ": "675290864117",
      "IE": "(11) 4685-1779",
      "Nome fantasia": "MILED ELKADRI",
      "Telefone": "Rua Iside Betro Lupo Issa",
      "Cidade/uf": "Taboao da Serra/SP",
      "endereço": "Jardim São Judas Tadeu"
    },
    {
      "CNPJ": "0290567386",
      "IE": "(54) 9920-8881",
      "Nome fantasia": "MLA",
      "Telefone": "AVENIDA RUBEM BENTO ALVES",
      "Cidade/uf": "CAXIAS DO SUL/RS",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "148872969116",
      "IE": "(11) 2611-6124",
      "Nome fantasia": "EDERSON SOARES DE OLIVEIRA - COLCHOES",
      "Telefone": "Avenida do Oratório",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim Guairaca"
    },
    {
      "CNPJ": "0039629960028",
      "IE": "(32) 3532-6352",
      "Nome fantasia": "J.C DIAS BARBOSA ME",
      "Telefone": "Rua Gonçalves Dias",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mateus Schetino"
    },
    {
      "CNPJ": "6997928450060",
      "Nome fantasia": "JCM",
      "Telefone": "Rua José Augusto Marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "0100005551",
      "IE": "(54) 3455-1212",
      "Nome fantasia": "CARRARO MOVEIS",
      "Telefone": "Avenida Nelson Carraro",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "Santo Antão"
    },
    {
      "CNPJ": "0021157380050",
      "Nome fantasia": "ZURC DECOR",
      "Telefone": "Rua Elias Antônio Laud",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "83615377",
      "IE": "(32) 98892-8737",
      "Nome fantasia": "Atual eletro",
      "Telefone": "Rua Maria Sotero Silveira Fonseca",
      "Cidade/uf": "Sao Geraldo/MG",
      "endereço": "Bairro Industrial"
    },
    {
      "CNPJ": "0041956580069",
      "IE": "(32) 9984-8348",
      "Nome fantasia": "DALLAS MOVEIS",
      "Telefone": "RUA TRAJANO VIANA",
      "Cidade/uf": "GUIDOVAL/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "0040229050000",
      "Nome fantasia": "ATACADAO MARAY MOVEIS E ELETROS",
      "Telefone": "EST. PARA MORADINHA",
      "Cidade/uf": "Uba/MG",
      "endereço": "BARRA DO EMBOQUE"
    },
    {
      "CNPJ": "0035320400004",
      "IE": "(32) 3533-0294",
      "Nome fantasia": "MOVEISNET",
      "Telefone": "Avenida Olegário Maciel",
      "Cidade/uf": "Uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "083.088.16-4",
      "IE": "(27) 99645-9888",
      "Nome fantasia": "Megaroom",
      "Telefone": "Rua Itacibá",
      "Cidade/uf": "vila velha/ES",
      "endereço": "Praia de Itaparica"
    },
    {
      "CNPJ": "003091200.00-31",
      "IE": "(32) 3531-5353",
      "Nome fantasia": "Facilita Decor",
      "Telefone": "Avenida Olegário Maciel",
      "Cidade/uf": "uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "0032754380078",
      "IE": "(32) 3533-0380",
      "Nome fantasia": "ZAMBI MOVEIS DECORAÇOES",
      "Telefone": "Avenida Olegário Maciel",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0012396090003",
      "IE": "(32) 99924-2430",
      "Nome fantasia": "DISTRIBUIDORA MOVEIS PARMA",
      "Telefone": "Ladeira Alexandre Leonel",
      "Cidade/uf": "juiz de fora/MG",
      "endereço": "São Mateus"
    },
    {
      "CNPJ": "0037073430079",
      "Nome fantasia": "PASCINI REPRESENTAÇÕES",
      "Telefone": "Rua Sebastião Ferreira da Silva",
      "Cidade/uf": "vicosa/MG",
      "endereço": "Belvedere"
    },
    {
      "CNPJ": "0963554026",
      "IE": "(54) 99907-8886",
      "Nome fantasia": "MLA",
      "Telefone": "Rua Vinte e Quatro de Outubro",
      "Cidade/uf": "Porto Alegre/RS",
      "endereço": "Auxiliadora"
    },
    {
      "CNPJ": "ISENTO",
      "IE": "(32) 99926-4737",
      "Nome fantasia": "VAGNER TREINAMENTOS",
      "Telefone": "Rua Ismael de Oliveira",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "0032318880069",
      "Nome fantasia": "MOZZONI",
      "Telefone": "Rua Dico Teixeira",
      "Cidade/uf": "uba/MG",
      "endereço": "Dico Teixeira"
    },
    {
      "CNPJ": "083515810",
      "Nome fantasia": "LAKAZA DESING E DECORAÇÔES",
      "Telefone": "10A AVENIDA GUIDO BRUNINI",
      "Cidade/uf": "PIUMA/ES",
      "endereço": "MONTE AGHA"
    },
    {
      "CNPJ": "6156575590081",
      "Nome fantasia": "LJ MOVEIS",
      "Telefone": "AV. VISCONDE DO RIO BRANCO",
      "Cidade/uf": "Sao Geraldo/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "100196020",
      "Nome fantasia": "Menu Moveis",
      "Telefone": "Rua Eugênio Valduga",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "São Francisco"
    },
    {
      "CNPJ": "0100186343",
      "IE": "(54) 2124-5094",
      "Nome fantasia": "Menu Moveis Mais",
      "Telefone": "Rua Domênico Zanetti",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "Imigrante"
    },
    {
      "CNPJ": "3127541100080",
      "IE": "(33) 3314-2412",
      "Nome fantasia": "COMERCIAL BERTOLACE",
      "Telefone": "AVENIDA SETE DE SETEMBRO,",
      "Cidade/uf": "Ipanema/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "0032854710061",
      "IE": "(32) 98830-6585",
      "Nome fantasia": "BMR MOVEIS",
      "Telefone": "RUA CANDIDO MARTINS DE OLIVEIRA NETO",
      "Cidade/uf": "UBA/MG",
      "endereço": "SANTA BERNARDETE"
    },
    {
      "CNPJ": "3673927970124",
      "IE": "(32) 3225-8756",
      "Nome fantasia": "CASA FEITOSA",
      "Telefone": "Avenida Doutor Simeão de Faria",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Santa Cruz"
    },
    {
      "CNPJ": "0037990090031",
      "IE": "(11) 8413-6582",
      "Nome fantasia": "UBÁ DECOR",
      "Telefone": "Rua Sebastião Luiz Cardoso",
      "Cidade/uf": "uba/MG",
      "endereço": "Vila Casal"
    },
    {
      "CNPJ": "3472316.00-60",
      "IE": "(32) 3529-6178",
      "Nome fantasia": "LAKASA",
      "Telefone": "Rua Lincoln Rodrigues Costa",
      "Cidade/uf": "uba/MG",
      "endereço": "Boa Vista"
    },
    {
      "CNPJ": "127381274111",
      "IE": "(11) 4580-0026",
      "Nome fantasia": "Rainha Online",
      "Telefone": "Avenida Júlio Buono,",
      "Cidade/uf": "sao paulo/SP",
      "endereço": "Vila Gustavo"
    },
    {
      "CNPJ": "083662790",
      "Nome fantasia": "PALACIO MOVEIS",
      "Telefone": "RUA AVENIDA VISTA ALEGRE",
      "Cidade/uf": "SOORETAMA/ES",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "626653201119",
      "IE": "(11) 4476-9335",
      "Nome fantasia": "HIAM ALI SALEH",
      "Telefone": "Rua Oratório",
      "Cidade/uf": "Santo Andre/SP",
      "endereço": "Parque das Nações"
    },
    {
      "CNPJ": "142216085110",
      "IE": "(11) 2919-8080",
      "Nome fantasia": "M A MUSTAFA COM DE MOV LTDA EPP",
      "Telefone": "Avenida Mateo Bei",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "São Mateus"
    },
    {
      "CNPJ": "128698722114",
      "IE": "(11) 4043-9376",
      "Nome fantasia": "W.B. DE F. S. FARIAS MOVEIS",
      "Telefone": "Estrada do Alvarenga",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Balneário Mar Paulista"
    },
    {
      "CNPJ": "003281121.00-16",
      "Nome fantasia": "DNX COMERCIO LTDA",
      "Telefone": "Rua Matilde da Rocha Balbi",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "083.550.52-6",
      "Nome fantasia": "MOVEIS KING LTDA",
      "Telefone": "Avenida São Mateus",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Araçá"
    },
    {
      "CNPJ": "699034372.00-91",
      "IE": "(32) 3539-3293",
      "Nome fantasia": "R BAIÃO",
      "Telefone": "Rua Vereador Benedito Augusto Vieira",
      "Cidade/uf": "uba/MG",
      "endereço": "Vila Regina"
    },
    {
      "CNPJ": "003073031.00-43",
      "IE": "(32) 8867-5436",
      "Nome fantasia": "METODO INTERIORES COMERCIO E TRANSPORTES DE MOVEIS",
      "Telefone": "RUA 16 DE MAIO",
      "Cidade/uf": "sao joao nepomuceno/MG",
      "endereço": "SÃO JOSE"
    },
    {
      "CNPJ": "0039516920004",
      "Nome fantasia": "SERGIO PASCINI LTDA",
      "Telefone": "Rua Sebastião Ferreira da Silva",
      "Cidade/uf": "vicosa/MG",
      "endereço": "Belvedere"
    },
    {
      "CNPJ": "083569758",
      "IE": "(11) 3659-7477",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "Rua Idalino Carvalho",
      "Cidade/uf": "Viana/ES",
      "endereço": "Parque Industrial"
    },
    {
      "CNPJ": "0021333430027",
      "IE": "(32) 99835-7866",
      "Nome fantasia": "MINAS DECOR EIRELLI",
      "Telefone": "SITIO BOA VISTA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "SITIO"
    },
    {
      "CNPJ": "083647902",
      "IE": "(32) 99817-3225",
      "Nome fantasia": "Moveis Bom Preço",
      "Telefone": "Rua Benedito de Oliveira Coutinho",
      "Cidade/uf": "guarapari/ES",
      "endereço": "Praia do Morro"
    },
    {
      "CNPJ": "002727131.00-37",
      "IE": "(32) 3021-3568",
      "Nome fantasia": "DIKAZA MOVEIS E DECORACOES LTDA",
      "Telefone": "Rua Mauri Martins de Oliveira",
      "Cidade/uf": "uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "003199527.00-08",
      "IE": "(32) 3541-0312",
      "Nome fantasia": "MG",
      "Telefone": "Rua Rio Grande do Sul",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0032297050062",
      "IE": "(32) 3532-4997",
      "Nome fantasia": "Mobília Mobília",
      "Telefone": "Rua José Augusto Marcos",
      "Cidade/uf": "uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "147068605114",
      "IE": "(11) 99300-2981",
      "Nome fantasia": "SANTIAGO & CINTRA CONSULTORIA LTDA",
      "Telefone": "Rua Vieira de Morais",
      "Cidade/uf": "sao paulo/SP",
      "endereço": "Campo Belo"
    },
    {
      "CNPJ": "0032395320021",
      "Nome fantasia": "MOVEIS E MOVEIS",
      "Telefone": "Avenida Senador Levindo Coelho",
      "Cidade/uf": "Uba/MG",
      "endereço": "Antonina Coelho"
    },
    {
      "CNPJ": "260172391",
      "IE": "(11) 2405-4132",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "Rua Gustavo Henschel",
      "Cidade/uf": "Blumenau/SC",
      "endereço": "Itoupavazinha"
    },
    {
      "CNPJ": "003761459.00-40",
      "Nome fantasia": "ASX COMERCIO",
      "Telefone": "Rua Amazonas",
      "Cidade/uf": "uba/MG",
      "endereço": "Chiquito Gazolla"
    },
    {
      "CNPJ": "39320900019",
      "IE": "(32) 3529-6010",
      "Nome fantasia": "CASAS MERLIN",
      "Telefone": "Rua Maria Burato Migliorini",
      "Cidade/uf": "Uba/MG",
      "endereço": "San Raphael II"
    },
    {
      "CNPJ": "108756610110",
      "IE": "(11) 0217-9345",
      "Nome fantasia": "MITSUHIKO ENDO",
      "Telefone": "Avenida Conselheiro Carrão",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Carrão"
    },
    {
      "CNPJ": "0041951070046",
      "Nome fantasia": "DECOR SOFT",
      "Telefone": "AV. RAUL ALVES FERREIRA",
      "Cidade/uf": "RODEIRO/MG",
      "endereço": "AROEIRAS"
    },
    {
      "CNPJ": "002789288.00-62",
      "Nome fantasia": "MODERNA MOBILIA",
      "Telefone": "Rua Márcio Nicolato",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "INDUSTRIAL"
    },
    {
      "CNPJ": "0100005551",
      "IE": "(13) 3495-2294",
      "Nome fantasia": "COMERCIO DE MOVEIS N S DE FATIMA P G LTDA",
      "Telefone": "Avenida Presidente Kennedy",
      "Cidade/uf": "Praia Grande/SP",
      "endereço": "Caiçara"
    },
    {
      "CNPJ": "283073420113",
      "IE": "(13) 3372-6460",
      "Nome fantasia": "SHEYLA  SANTANA DOS SANTOS",
      "Telefone": "Avenida Nove de Abril",
      "Cidade/uf": "Cubatao/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "130227087110",
      "IE": "(11) 4886-1571",
      "Nome fantasia": "GONÇALVES COMERCIO DE MOVEIS EIRELI",
      "Telefone": "Avenida Campanella",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim Itapemirim"
    },
    {
      "CNPJ": "123122344113",
      "IE": "(11) 94788-2421",
      "Nome fantasia": "INTERIOR DECORE E MOVEIS EIRELI",
      "Telefone": "Avenida João XXIII",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Formosa"
    },
    {
      "CNPJ": "0025856870050",
      "IE": "(32) 99993-3622",
      "Nome fantasia": "DECORE CASA",
      "Telefone": "Rua José Lourenço da Silva",
      "Cidade/uf": "Uba/MG",
      "endereço": "da Luz"
    },
    {
      "CNPJ": "130801703114",
      "IE": "(11) 4770-3926",
      "Nome fantasia": "MARCIO DA CONCEIÇÃO SILVA MOVEIS",
      "Telefone": "Rua Francisco Calado",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim Centenário"
    },
    {
      "CNPJ": "083643842",
      "Nome fantasia": "Moveis Bom Preço",
      "Telefone": "Rua Benedito de Oliveira Coutinho",
      "Cidade/uf": "guarapari/ES",
      "endereço": "Praia do Morro"
    },
    {
      "CNPJ": "34225960048",
      "IE": "(32) 98506-1117",
      "Nome fantasia": "Akisala",
      "Telefone": "Rua Ângelo Sperandio",
      "Cidade/uf": "uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "0026831780067",
      "IE": "(32) 99817-3235",
      "Nome fantasia": "Maria do Rosario Corbelli",
      "Telefone": "Jonatas Barbosa Cavalieri",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0033694820027",
      "IE": "(32) 99958-6360",
      "Nome fantasia": "VIA ENCANTO",
      "Telefone": "Rua Marieta Augusta Silva Marcos",
      "Cidade/uf": "uba/MG",
      "endereço": "Olaria"
    },
    {
      "CNPJ": "002752956.00-18",
      "IE": "(32) 3529-6178",
      "Nome fantasia": "IMPERIAL MAGAZINE EIRELI",
      "Telefone": "Rua Francisco Teixeira Nascimento",
      "Cidade/uf": "uba/MG",
      "endereço": "São José"
    },
    {
      "CNPJ": "083564926",
      "Nome fantasia": "Outlet das Fábricas",
      "Telefone": "Avenida Carlos Lindenberg",
      "Cidade/uf": "vila velha/ES",
      "endereço": "Nova América"
    },
    {
      "CNPJ": "145841770111",
      "IE": "(11) 94770-5757",
      "Nome fantasia": "LOJAS D",
      "Telefone": "Rua Albertina Mallan Zaffia",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Emir"
    },
    {
      "CNPJ": "114258850116",
      "IE": "(11) 2231-5114",
      "Nome fantasia": "CENTER MOV LAUZANE LTDA ME",
      "Telefone": "AV DR FRANCISCO RANIERI",
      "Cidade/uf": "SAO PAULO/SP",
      "endereço": "LAUZANE PTA"
    },
    {
      "CNPJ": "0042159150061",
      "Nome fantasia": "DALLAS MOVEIS",
      "Telefone": "RUA TRAJANO VIANA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "003321032.00-20",
      "IE": "(32) 3541-0312",
      "Nome fantasia": "VALERIA PACHECO RINCO 92680682653",
      "Telefone": "Avenida Olegário Maciel",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0024989080017",
      "IE": "(32) 3541-0312",
      "Nome fantasia": "FABRICIO ANTONIO GONÇALVEZ 06892199623",
      "Telefone": "Avenida Olegário Maciel",
      "Cidade/uf": "uba/MG",
      "endereço": "INDUSTRIAL"
    },
    {
      "CNPJ": "003222222.00-92",
      "Nome fantasia": "BYMOBILLE COMERCIO DE MOVEIS LTDA",
      "Telefone": "Rua João Guilhermino",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "1017194727",
      "IE": "(41) 3015-5694",
      "Nome fantasia": "BUROHAUS",
      "Telefone": "Rua das Carmelitas",
      "Cidade/uf": "Curitiba/PR",
      "endereço": "Boqueirão"
    },
    {
      "CNPJ": "178255673",
      "IE": "(27) 99984-2812",
      "Nome fantasia": "CIELO ESTOFADOS",
      "Telefone": "RUA PARAIBA",
      "Cidade/uf": "Itamaraju/BA",
      "endereço": "Itamaraju"
    },
    {
      "CNPJ": "114242689116",
      "IE": "(11) 5073-0872",
      "Nome fantasia": "MUDY MOV DEC LTDA",
      "Telefone": "Avenida Miguel Estefno",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Saúde"
    },
    {
      "CNPJ": "141636220113",
      "IE": "(11) 5873-5987",
      "Nome fantasia": "ODAIR JOAQUIM DE JESUS",
      "Telefone": "Rua Vitor Bastos",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim São Bento Novo"
    },
    {
      "CNPJ": "083.179.12-7",
      "IE": "(27) 3371-9826",
      "Nome fantasia": "Vivance Lar",
      "Telefone": "Avenida Padre Manoel da Nóbrega",
      "Cidade/uf": "linhares/ES",
      "endereço": "Interlagos"
    },
    {
      "CNPJ": "0036503540019",
      "Nome fantasia": "CASA BOX",
      "Telefone": "Rua dos Operários",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0017017650093",
      "IE": "(32) 98892-5551",
      "Nome fantasia": "ZARCO INTERIORES",
      "Telefone": "Rua João Guilhermino",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0030570220033",
      "IE": "(32) 99835-7866",
      "Nome fantasia": "FATTO ESOFADOS",
      "Telefone": "SITIO BOA VISTA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "ZONA RURAL"
    },
    {
      "CNPJ": "0040297630067",
      "IE": "(32) 98423-6292",
      "Nome fantasia": "PRIMAZZIA BRAZIL",
      "Telefone": "Rua José Augusto Marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "0021248030060",
      "IE": "(43) 3303-7717",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "Rua Jaceguai",
      "Cidade/uf": "Belo Horizonte/MG",
      "endereço": "Prado"
    },
    {
      "CNPJ": "0032811080082",
      "Nome fantasia": "CDM Comercio LTDA",
      "Telefone": "Rua Irineu Gomes da Silva",
      "Cidade/uf": "uba/MG",
      "endereço": "Galdino Alvim"
    },
    {
      "CNPJ": "083602461",
      "IE": "(32) 3541-0312",
      "Nome fantasia": "Moveis Atenas",
      "Telefone": "AV : Itapemirim",
      "Cidade/uf": "itapemirim/ES",
      "endereço": "Itaipava"
    },
    {
      "CNPJ": "003141788.00-74",
      "IE": "(32) 8848-8175",
      "Nome fantasia": "BELLA DECOR",
      "Telefone": "Rua José Lourenço da Silva",
      "Cidade/uf": "uba/MG",
      "endereço": "da Luz"
    },
    {
      "CNPJ": "0032644520010",
      "IE": "(11) 2020-2525",
      "Nome fantasia": "ZURC DECOR",
      "Telefone": "Rua Elias Antônio Laud",
      "Cidade/uf": "uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "0039096530136",
      "IE": "(11) 2631-8258",
      "Nome fantasia": "INOVAKASA",
      "Telefone": "Rua Presidente Antônio Carlos",
      "Cidade/uf": "varginha/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "796562377116",
      "IE": "(11) 3090-6714",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "Avenida Natalia Zarif",
      "Cidade/uf": "Guarulhos/SP",
      "endereço": "Jardim São Geraldo"
    },
    {
      "CNPJ": "353346508118",
      "IE": "(19) 3329-0030",
      "Nome fantasia": "DROM",
      "Telefone": "Avenida Visconde de Indaiatuba",
      "Cidade/uf": "Indaiatuba/SP",
      "endereço": "Vila Vitória I"
    },
    {
      "CNPJ": "002518304.00-95",
      "Nome fantasia": "DECORACOES MOVEIS",
      "Telefone": "Rua SANTOS MIQUELITO",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "796604402114",
      "IE": "(11) 4119-8453",
      "Nome fantasia": "CALEGARI CAPELLI",
      "Telefone": "Rua Cabo João Teruel Fregoni",
      "Cidade/uf": "Guarulhos/SP",
      "endereço": "Ponte Grande"
    },
    {
      "CNPJ": "148991008112",
      "IE": "(11) 2541-2173",
      "Nome fantasia": "COMERCIAL DE MOVEIS CISPER LTDA",
      "Telefone": "Avenida Olavo Egídio de Souza Aranha",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Parque Císper"
    },
    {
      "CNPJ": "635511595113",
      "IE": "(11) 4125-3211",
      "Nome fantasia": "NOHAD K ZEITOUN MOVEIS",
      "Telefone": "Rua Marechal Deodoro",
      "Cidade/uf": "Sao Bernardo do Campo/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "004131719.00-33",
      "Nome fantasia": "WEMOV",
      "Telefone": "Praça São Paulo",
      "Cidade/uf": "Muriae/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "528.096.390.116",
      "IE": "(12) 3522-4840",
      "Nome fantasia": "RAIMUNDO NETO CAVALCANTE",
      "Telefone": "Rua Doutor Aníbal Gouvea",
      "Cidade/uf": "Pindamonhangaba/SP",
      "endereço": "Nossa Senhora do Perpétuo Socorro"
    },
    {
      "CNPJ": "002266183.00-14",
      "Nome fantasia": "Especiale",
      "Telefone": "Rua José Augusto Marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Bairro Ponte Preta"
    },
    {
      "CNPJ": "0040222820047",
      "Nome fantasia": "HOUSE MOBILIA",
      "Telefone": "AV. RAUL ALVES FERREIRA",
      "Cidade/uf": "RODEIRO/MG",
      "endereço": "AROEIRAS"
    },
    {
      "CNPJ": "083484760",
      "Nome fantasia": "PALACIO COMERCIO ELETRONICO",
      "Telefone": "AVENIDA ELVIRA TOZI BROEDEL",
      "Cidade/uf": "SOORETAMA/ES",
      "endereço": "CANAA"
    },
    {
      "CNPJ": "5633804430010",
      "IE": "(32) 3577-3300",
      "Nome fantasia": "LOPAS",
      "Telefone": "SITIO AGUA LIMPA,",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "RODEIRO"
    },
    {
      "CNPJ": "286509878112",
      "IE": "(11) 98787-0438",
      "Nome fantasia": "F J  DE QUEIROZ COLCHOES",
      "Telefone": "Rua Araguaia",
      "Cidade/uf": "Diadema/SP",
      "endereço": "Conceição"
    },
    {
      "CNPJ": "112855007117",
      "IE": "(11) 5979-9539",
      "Nome fantasia": "LOJA DE MOVEIS CASARAO LIMITADA",
      "Telefone": "Estrada Ecoturística de Parelheiros",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim Casa Grande"
    },
    {
      "CNPJ": "796552585117",
      "IE": "(11) 2402-2685",
      "Nome fantasia": "JOSE ALI FARES MOVEIS ME",
      "Telefone": "Avenida Otávio Braga de Mesquita",
      "Cidade/uf": "Guarulhos/SP",
      "endereço": "Vila Flórida"
    },
    {
      "CNPJ": "454026465111",
      "IE": "(11) 4796-8554",
      "Nome fantasia": "CASA DE MOVEIS SULTÃO LTDA",
      "Telefone": "Rua Coronel Souza Franco",
      "Cidade/uf": "Mogi das Cruzes/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0033291530080",
      "Nome fantasia": "TAVOLI DECOR LTDA",
      "Telefone": "Rua Fortunato Moura Estevão - Distrito Indústrial,",
      "Cidade/uf": "Visconde do Rio Branco/MG",
      "endereço": "DISTRITO iNDUSTRIAL"
    },
    {
      "CNPJ": "0400322231500",
      "Nome fantasia": "KAMEL",
      "Telefone": "Rua C",
      "Cidade/uf": "Uba/MG",
      "endereço": "BELVEDERE"
    },
    {
      "CNPJ": "0024162430020",
      "IE": "(32) 3528-5182",
      "Nome fantasia": "Casa dos Moveis Uba",
      "Telefone": "Rua Ismael de Oliveira",
      "Cidade/uf": "uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "0039338500071",
      "IE": "(31) 99676-7523",
      "Nome fantasia": "MOVEIS MIX",
      "Telefone": "AV JOAO POLICARPO DE MIRANDA,",
      "Cidade/uf": "senador firmino/MG",
      "endereço": "BOA VISTA"
    },
    {
      "CNPJ": "438058427118",
      "IE": "(14) 99673-3003",
      "Nome fantasia": "REFORMAR SOFA E CIA",
      "Telefone": "Rua Doutor Issamu Miura",
      "Cidade/uf": "Marilia/SP",
      "endereço": "Jardim América"
    },
    {
      "CNPJ": "7203056650019",
      "Nome fantasia": "CHF MOVEIS",
      "Telefone": "RUA PROJETADA",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "RODEIRO"
    },
    {
      "CNPJ": "0039678180030",
      "IE": "(11) 2305-9352",
      "Nome fantasia": "Mandaê",
      "Telefone": "Rua Diamante",
      "Cidade/uf": "Contagem/MG",
      "endereço": "Arvoredo 2ª Seção"
    },
    {
      "CNPJ": "635974656110",
      "IE": "(11) 2896-7712",
      "Nome fantasia": "SUPERCASA ATACADISTA EIRELI",
      "Telefone": "Avenida Piraporinha",
      "Cidade/uf": "Sao Bernardo do Campo/SP",
      "endereço": "Planalto"
    },
    {
      "CNPJ": "388106248115",
      "IE": "(11) 97359-3635",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "AV Emilio Chechinato gp4",
      "Cidade/uf": "Itupeva/SP",
      "endereço": "Sâo Roque"
    },
    {
      "CNPJ": "003129397.00-34",
      "IE": "(32) 3531-0459",
      "Nome fantasia": "LINDECOR",
      "Telefone": "Rua Orestes Durigueto",
      "Cidade/uf": "uba/MG",
      "endereço": "Nossa Senhora de Fátima"
    },
    {
      "CNPJ": "003261191.00-81",
      "IE": "(32) 8863-5003",
      "Nome fantasia": "SPAZZIO",
      "Telefone": "Rua Domitila Castanon",
      "Cidade/uf": "uba/MG",
      "endereço": "Eldorado"
    },
    {
      "CNPJ": "0037393970058",
      "IE": "(32) 98502-9433",
      "Nome fantasia": "BELLA DECOR",
      "Telefone": "Rua Maestro João Enesrto",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "9084452448",
      "IE": "(43) 3303-7717",
      "Nome fantasia": "FCB Brasil",
      "Telefone": "Rua Guaratinga",
      "Cidade/uf": "Arapongas/PR",
      "endereço": "Parque Industrial II"
    },
    {
      "CNPJ": "118.671.690.112",
      "IE": "(11) 2305-9352",
      "Nome fantasia": "Mandaê",
      "Telefone": "Avenida Torres de Oliveira",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jaguaré"
    },
    {
      "CNPJ": "657269894110",
      "IE": "(13) 3564-2412",
      "Nome fantasia": "SORAYA DOS SANTOS ABOU ARABI ME",
      "Telefone": "Avenida Vereador Valter Melarato",
      "Cidade/uf": "Sao Vicente/SP",
      "endereço": "Conjunto Residencial Humaitá"
    },
    {
      "CNPJ": "379164930118",
      "IE": "(11) 4641-5857",
      "Nome fantasia": "CICERO MIGUEL DA SILVA CONFECCOES",
      "Telefone": "Estrada Água Chata",
      "Cidade/uf": "Itaquaquecetuba/SP",
      "endereço": "Parque Piratininga"
    },
    {
      "CNPJ": "142592928110",
      "IE": "(11) 3209-5483",
      "Nome fantasia": "MARILDA TEIXEIRA SAMPAIO PEREIRA ME",
      "Telefone": "Rua Glicério",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Liberdade"
    },
    {
      "CNPJ": "1310133910",
      "IE": "(54) 9920-8881",
      "Nome fantasia": "DREAM FITNESS",
      "Telefone": "Rua São João",
      "Cidade/uf": "Sapiranga/RS",
      "endereço": "Santa Fé"
    },
    {
      "CNPJ": "0020207120048",
      "IE": "(32) 3541-2542",
      "Nome fantasia": "CASA MODERMA MOVEIS",
      "Telefone": "Rua Antônio Altaf",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Cascatinha"
    },
    {
      "CNPJ": "ISENTO",
      "IE": "(19) 3211-3660",
      "Nome fantasia": "SOCIEDADE REGIONAL DE ENSINO E SAUDE LTDA",
      "Telefone": "Rua da Abolição",
      "Cidade/uf": "Campinas/SP",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "083717099",
      "Nome fantasia": "MOVEIS KING",
      "Telefone": "Avenida Espírito Santo",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Canivete"
    },
    {
      "CNPJ": "635018212113",
      "IE": "(11) 4363-2965",
      "Nome fantasia": "SIGIFREDO GARCIA HERNANDES & CIA LTDA",
      "Telefone": "Avenida do Taboão",
      "Cidade/uf": "Sao Bernardo do Campo/SP",
      "endereço": "Taboão"
    },
    {
      "CNPJ": "155.211.276",
      "IE": "(27) 3372-2922",
      "Nome fantasia": "VIVANCE LAR COMERCIO ELETRONICO EIRELI",
      "Telefone": "Rua Jesuíno Martins de Souza",
      "Cidade/uf": "teixeira de freitas/BA",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0018181640055",
      "IE": "(11) 97359-3635",
      "Nome fantasia": "DORA BELA",
      "Telefone": "Rua Jurandir Peron",
      "Cidade/uf": "uba/MG",
      "endereço": "Agroceres"
    },
    {
      "CNPJ": "0035154530063",
      "IE": "(32) 3532-2898",
      "Nome fantasia": "+ Moveis e Decorações",
      "Telefone": "Rua Rio Grande do Sul",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0100183956",
      "Nome fantasia": "Lilies",
      "Telefone": "Rua Pedro Batista Menegotto",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "Fatima"
    },
    {
      "CNPJ": "254843590",
      "IE": "(54) 9920-8881",
      "Nome fantasia": "MLA",
      "Telefone": "CENTRO",
      "Cidade/uf": "Anchieta/SC",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "127414020110",
      "IE": "(11) 2937-7125",
      "Nome fantasia": "SUPER C COM DE MOV COLC DEC E  UTILI DOM EIRELI",
      "Telefone": "Avenida Papa João Paulo I",
      "Cidade/uf": "Guarulhos/SP",
      "endereço": "Cidade Parque São Luiz"
    },
    {
      "CNPJ": "373292635112",
      "IE": "(11) 99149-2929",
      "Nome fantasia": "GLAMBOX",
      "Telefone": "Avenida Portugal",
      "Cidade/uf": "Itapevi/SP",
      "endereço": "Itaqui"
    },
    {
      "CNPJ": "0260040908",
      "IE": "(54) 99907-8886",
      "Nome fantasia": "MLA",
      "Telefone": "EST JOSE PEDRUZZI",
      "Cidade/uf": "CARLOS BARBOSA/RS",
      "endereço": "TORINO"
    },
    {
      "CNPJ": "0037948120050",
      "IE": "(32) 98807-4757",
      "Nome fantasia": "LAGA DECOR LTDA",
      "Telefone": "Rua João Batista Rodrigues",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "0042077950021",
      "Nome fantasia": "CASA E SALA UBA",
      "Telefone": "Rua Irineu Gomes da Silva",
      "Cidade/uf": "Uba/MG",
      "endereço": "Galdino Alvim"
    },
    {
      "CNPJ": "367392797.00-43",
      "Nome fantasia": "Gilberto Fernandes",
      "Telefone": "Avenida Doutor Simeão de Faria",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Nova Era"
    },
    {
      "CNPJ": "0024627220081",
      "Nome fantasia": "Bianchi Decorações",
      "Telefone": "Av. Paulino Fernandes,",
      "Cidade/uf": "UBA/MG",
      "endereço": "Paulino Fernandes"
    },
    {
      "CNPJ": "004207107.00-03",
      "Nome fantasia": "R & G MOVEIS E DECORACOES LTDA",
      "Telefone": "Avenida Padre Arnaldo Jansen",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santa Luzia"
    },
    {
      "CNPJ": "003618430.00-08",
      "Nome fantasia": "DECORA MOBILIA",
      "Telefone": "Rua Oséias Maranhão",
      "Cidade/uf": "Uba/MG",
      "endereço": "Oséas Maranhão"
    },
    {
      "CNPJ": "6996649330059",
      "Nome fantasia": "EletroAlmeida",
      "Telefone": "Rua São José",
      "Cidade/uf": "uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "003241239.00-02",
      "IE": "(32) 3531-6514",
      "Nome fantasia": "COMERCIO DE MOVEIS DI KAZA LTDA",
      "Telefone": "Rua João Batista Rodrigues",
      "Cidade/uf": "uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "002435565.00-57",
      "IE": "(32) 9841-2420",
      "Nome fantasia": "BEOLAR",
      "Telefone": "RUA CAETANO ROBERTI",
      "Cidade/uf": "tocantins/MG",
      "endereço": "VALE DO OURO"
    },
    {
      "CNPJ": "286.272.033.114",
      "IE": "(11) 2375-3942",
      "Nome fantasia": "Bembaratomoveis",
      "Telefone": "Avenida da Água Funda",
      "Cidade/uf": "Diadema/SP",
      "endereço": "Taboão"
    },
    {
      "CNPJ": "533011308110",
      "IE": "(12) 3156-1843",
      "Nome fantasia": "MARYELLE RIBEIRO DA SILVA",
      "Telefone": "RUA DO PIQUETE",
      "Cidade/uf": "Piquete/SP",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "003779737.00-36",
      "IE": "(32) 3577-1702",
      "Nome fantasia": "Vivaro Decor",
      "Telefone": "Rua. Prefeito Adolfo Nicolato",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "Gastão Francisco da Silva"
    },
    {
      "CNPJ": "6996281030009",
      "IE": "(32) 3539-7500",
      "Nome fantasia": "PANORAMA MOVEIS",
      "Telefone": "Rua Jonathas Barbosa Cavaliere",
      "Cidade/uf": "Uba/MG",
      "endereço": "Agostinho Sales Amato"
    },
    {
      "CNPJ": "708248763110",
      "IE": "(19) 3244-2652",
      "Nome fantasia": "ROSSANA TEIXEIRA PEREIRA",
      "Telefone": "Rua João Bissotto Filho",
      "Cidade/uf": "Valinhos/SP",
      "endereço": "Ortizes"
    },
    {
      "CNPJ": "0024626790003",
      "IE": "(32) 9968-7005",
      "Nome fantasia": "BYMOBILLE COMERCIO DE MOVEIS LTDA",
      "Telefone": "AVENIDA DOS EX COMBATENTES,",
      "Cidade/uf": "UBA/MG",
      "endereço": "SANTA LUZIA"
    },
    {
      "CNPJ": "148884771110",
      "IE": "(11) 5973-5196",
      "Nome fantasia": "SAMARA S OSMAN MOVEIS",
      "Telefone": "Rua Rubens de Oliveira",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Parque Residencial Cocaia"
    },
    {
      "CNPJ": "149596798110",
      "IE": "(11) 5872-2918",
      "Nome fantasia": "OMAR AHAMAD EL GHANDOUR MOVEIS",
      "Telefone": "Rua Henrique Sam Mindlin",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim São Bento Novo"
    },
    {
      "CNPJ": "0450102890",
      "IE": "(54) 9907-8886",
      "Nome fantasia": "MLA",
      "Telefone": "Rua Arcangelo Milesi",
      "Cidade/uf": "Farroupilha/RS",
      "endereço": "Monte Pasqual"
    },
    {
      "CNPJ": "0032022610016",
      "IE": "(32) 3532-6352",
      "Nome fantasia": "J.C DIAS BARBOSA ME",
      "Telefone": "Rua São Pedro",
      "Cidade/uf": "uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "0028669250098",
      "IE": "(32) 9949-6691",
      "Nome fantasia": "SILMAG ESTOFADOS",
      "Telefone": "Rua Mario Rodrigues do Nascimento",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "128421190114",
      "IE": "(18) 99137-5645",
      "Nome fantasia": "KBANE",
      "Telefone": "Rua Serra de Jairé",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Quarta Parada"
    },
    {
      "CNPJ": "451010275112",
      "IE": "(51) 99565-7434",
      "Nome fantasia": "CASA D",
      "Telefone": "Rua Doutor Ernani da Gama Corrêa",
      "Cidade/uf": "Mirassol/SP",
      "endereço": "Portal da Cidade Amiga"
    },
    {
      "CNPJ": "112157105110",
      "IE": "(11) 3399-1212",
      "Nome fantasia": "RENASCENCE MOV E DEC LTDA EPP",
      "Telefone": "Rua Conselheiro Furtado",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Liberdade"
    },
    {
      "CNPJ": "003937445.00-20",
      "Nome fantasia": "M E M DECOR",
      "Telefone": "Rua Luiz de Matos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Vila Regina"
    },
    {
      "CNPJ": "002868469.00-60",
      "Nome fantasia": "LOJA MEU NOVO LAR",
      "Telefone": "Rua Antônio Batista",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "002900393.00-82",
      "IE": "(32) 9828-2167",
      "Nome fantasia": "COMERCIAL CASA UAI",
      "Telefone": "AVENIDA Dos Andradas",
      "Cidade/uf": "uba/MG",
      "endereço": "Sao Sebastiao"
    },
    {
      "CNPJ": "003025170.00-97",
      "Nome fantasia": "Zari Decor Móveis e Decorações",
      "Telefone": "Rua Miguel Candian",
      "Cidade/uf": "uba/MG",
      "endereço": "Meu Sonho"
    },
    {
      "CNPJ": "0016152640083",
      "IE": "(11) 97359-3635",
      "Nome fantasia": "STAR BELLA NEGOCIOS E SERVOÇOS LTDA",
      "Telefone": "Rua Jurandir Peron",
      "Cidade/uf": "Uba/MG",
      "endereço": "Agroceres"
    },
    {
      "CNPJ": "260583367",
      "IE": "(18) 99137-5645",
      "Nome fantasia": "KBANE",
      "Telefone": "AV. ANDRE RODRIGUES DE FREITAS,",
      "Cidade/uf": "ITAPOA/SC",
      "endereço": "BALNEARIO PEROLA"
    },
    {
      "CNPJ": "699167312.00-48",
      "Nome fantasia": "SALLETO INDUSTRIA",
      "Telefone": "Avenida Jesus Candian",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "5635547150024",
      "Nome fantasia": "CARIOCA MOVEIS",
      "Telefone": "ROD PREFEITO ADOLFO NICOLATO",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "RODEIRO"
    },
    {
      "CNPJ": "0036487910090",
      "IE": "(32) 3529-6178",
      "Nome fantasia": "Fênix Móveis",
      "Telefone": "Rua Eliza Amaral Peron",
      "Cidade/uf": "Uba/MG",
      "endereço": "Agroceres"
    },
    {
      "CNPJ": "6995889850161",
      "IE": "(32) 9914-8867",
      "Nome fantasia": "SOUZA MATERIAIS DE CONSTRUÇÃO LTDA",
      "Telefone": "Rua Quinze de Novembro",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "004259041.00-85",
      "Nome fantasia": "Cielo Móveis",
      "Telefone": "Rua Azaléia",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes"
    },
    {
      "CNPJ": "0041133160000",
      "Nome fantasia": "FABRI DECOR",
      "Telefone": "Rua José Augusto Marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "003614472.00-65",
      "Nome fantasia": "MAJESTOSO ESTOFADOS",
      "Telefone": "Rua João Sperandio",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "082911975",
      "Nome fantasia": "friovix",
      "Telefone": "Avenida Civit I",
      "Cidade/uf": "Serra/ES",
      "endereço": "Barro Branco"
    },
    {
      "CNPJ": "083347950",
      "IE": "(47) 3310-3100",
      "Nome fantasia": "PANORAMA MOVEIS",
      "Telefone": "Avenida Nossa Senhora da Penha",
      "Cidade/uf": "Vitoria/ES",
      "endereço": "Santa Lúcia"
    },
    {
      "CNPJ": "0040754760081",
      "IE": "(32) 99817-3235",
      "Nome fantasia": "MOVEIS BOM PREÇO",
      "Telefone": "Rua Francisco Teixeira de Abreu",
      "Cidade/uf": "Uba/MG",
      "endereço": "Palmeiras"
    },
    {
      "CNPJ": "114993114119",
      "IE": "(11) 5824-7050",
      "Nome fantasia": "NEUZA GALEGO MOVEIS",
      "Telefone": "Rua João da Cruz e Sousa",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim das Rosas (Zona Sul)"
    },
    {
      "CNPJ": "0038210080075",
      "IE": "(32) 3531-2699",
      "Nome fantasia": "MUNDO SYM VIRTUAL LTDA",
      "Telefone": "Avenida José Rezende Brando",
      "Cidade/uf": "Uba/MG",
      "endereço": "Bom Pastor"
    },
    {
      "CNPJ": "116394635114",
      "IE": "(11) 2051-9600",
      "Nome fantasia": "SAMIRA HASSAN ALI MOVEIS",
      "Telefone": "Avenida do Imperador",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Limoeiro"
    },
    {
      "CNPJ": "0042232070085",
      "IE": "(32) 99995-1536",
      "Nome fantasia": "MOVEIS DALLAS",
      "Telefone": "RUA TRAJANO VIANA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "256.606.218",
      "IE": "(49) 3227-3400",
      "Nome fantasia": "MB STUDIO",
      "Telefone": "Avenida Juscelino Kubitschek de Oliveira",
      "Cidade/uf": "lages/SC",
      "endereço": "Restinga Seca"
    },
    {
      "CNPJ": "083.339.64-7",
      "Nome fantasia": "Ebba Moveis",
      "Telefone": "Rua João Gama",
      "Cidade/uf": "linhares/ES",
      "endereço": "Interlagos"
    },
    {
      "CNPJ": "155899652",
      "Nome fantasia": "Ebba Moveis BA",
      "Telefone": "Rua Jesuíno Martins de Souza",
      "Cidade/uf": "teixeira de freitas/BA",
      "endereço": "Centro"
    },
    {
      "CNPJ": "003761524.00-50",
      "Nome fantasia": "EOX COMERCIO",
      "Telefone": "RUA ANTONIO CANESCHI BIGONHA",
      "Cidade/uf": "uba/MG",
      "endereço": "Louriçal"
    },
    {
      "CNPJ": "9049748813",
      "IE": "(41) 99183-7592",
      "Nome fantasia": "PAULINHO SINTETICOS",
      "Telefone": "Rua David Tows",
      "Cidade/uf": "Curitiba/PR",
      "endereço": "Xaxim"
    },
    {
      "CNPJ": "0500083380",
      "IE": "(54) 3035-9119",
      "Nome fantasia": "MLA",
      "Telefone": "RUA DOMINGOS PAGANELLI,",
      "Cidade/uf": "GARIBALDI/RS",
      "endereço": "SÃO FRANCISCO"
    },
    {
      "CNPJ": "0041060500043",
      "IE": "(32) 99912-7336",
      "Nome fantasia": "Bom Lar Moveis",
      "Telefone": "Rua da Mantiqueira",
      "Cidade/uf": "Uba/MG",
      "endereço": "Cristo Redentor"
    },
    {
      "CNPJ": "0035279570028",
      "Nome fantasia": "REI DE CASA MOVEIS E DECORAÇOES",
      "Telefone": "Rua Farmacêutico Hésio Rodrigues Andrade",
      "Cidade/uf": "Uba/MG",
      "endereço": "Jardim Inês Groppo"
    },
    {
      "CNPJ": "0016773850061",
      "Nome fantasia": "RDCSHOP",
      "Telefone": "Rua João Batista Rodrigues",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "0042959350073",
      "Nome fantasia": "VIVARO DECOR MOVEIS E ESTOFADOS LTDA",
      "Telefone": "Rua. Prefeito Adolfo Nicolato",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "Gastão Francisco da Silva"
    },
    {
      "CNPJ": "0042737260060",
      "Nome fantasia": "3L ESTOFADOS LTDA",
      "Telefone": "rua José Augusto marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "12145543",
      "Nome fantasia": "SÃO JOSE ESTOFADOS",
      "Telefone": "Avenida Sávio Cota de Almeida Gama",
      "Cidade/uf": "Volta Redonda/RJ",
      "endereço": "Retiro"
    },
    {
      "CNPJ": "004141994.00-07",
      "Nome fantasia": "ARTE E DECCOR POLTRONAS LTDA",
      "Telefone": "Rua José Feital Teixeira",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "0031543380050",
      "Nome fantasia": "DW MÓVEIS",
      "Telefone": "Rua São Pedro",
      "Cidade/uf": "Uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "197.021.870.114",
      "IE": "(11) 111111111-",
      "Nome fantasia": "JOY",
      "Telefone": "RUA JOAO VASQUES EBANHAS",
      "Cidade/uf": "Balsamo/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0100193773",
      "IE": "(54) 99907-8886",
      "Nome fantasia": "MLA",
      "Telefone": "Rua Ambrósio Pasqual",
      "Cidade/uf": "Farroupilha/RS",
      "endereço": "Monte Pasqual"
    },
    {
      "CNPJ": "0038802900094",
      "IE": "(32) 98706-8789",
      "Nome fantasia": "LETICIA FERREIRA LOPES",
      "Telefone": "Rua Santa Catarina",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santa Bernadete"
    },
    {
      "CNPJ": "254147109116",
      "IE": "(12) 3883-7306",
      "Nome fantasia": "ISABELA REZENDE RANGEL FERNANDES",
      "Telefone": "Rua Altino Arantes",
      "Cidade/uf": "Caraguatatuba/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0041958140058",
      "IE": "(32) 9984-8348",
      "Nome fantasia": "DALLAS MOVEIS",
      "Telefone": "RUA TRAJANO VIANA",
      "Cidade/uf": "GUIDOVAL/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "148.723.848.110",
      "IE": "(11) 2308-4438",
      "Nome fantasia": "Your Gift",
      "Telefone": "Rua Xiró",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim das Laranjeiras"
    },
    {
      "CNPJ": "0021800730071",
      "IE": "(32) 9995-8503",
      "Nome fantasia": "G O MARANGON",
      "Telefone": "Avenida Comendador Jacinto Soares de Souza Lima",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "5632367450051",
      "Nome fantasia": "LEIFIL MOVEIS LTDA",
      "Telefone": "AV. DAS INDUSTRIAS",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "BELO HORIZONTE"
    },
    {
      "CNPJ": "0031204610061",
      "Nome fantasia": "OCD MOVEIS LTDA",
      "Telefone": "RUA PRESIDENTE GETULIO VARGAS",
      "Cidade/uf": "SAO JOAO NEPOMUCENO/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "0040925720038",
      "Nome fantasia": "ZARA DECOR",
      "Telefone": "Rua Doutor Domingos Peluso",
      "Cidade/uf": "Uba/MG",
      "endereço": "Vila Regina"
    },
    {
      "CNPJ": "083170103",
      "Nome fantasia": "A2E NEGOCIOS DIGITAIS LTDA",
      "Telefone": "Rua João Francisco Calmon",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Araçá"
    },
    {
      "CNPJ": "0035896800053",
      "Nome fantasia": "GUILHERME DE OLIVEIRA PROVEZANO",
      "Telefone": "Rua Farmacêutico Mário de Azevedo",
      "Cidade/uf": "Uba/MG",
      "endereço": "Jardim Glória"
    },
    {
      "CNPJ": "528221530113",
      "IE": "(12) 98803-2706",
      "Nome fantasia": "GARIMPO OUTLET LTDA",
      "Telefone": "Rua José Frasquilho Carlota",
      "Cidade/uf": "Pindamonhangaba/SP",
      "endereço": "Vila Suiça"
    },
    {
      "CNPJ": "286074677116",
      "IE": "(11) 4091-3660",
      "Nome fantasia": "CASA MOVEIS",
      "Telefone": "Rua Polônia",
      "Cidade/uf": "Diadema/SP",
      "endereço": "Taboão"
    },
    {
      "CNPJ": "083178236",
      "IE": "(27) 3732-4110",
      "Nome fantasia": "BERTOLACE ELETRODOMESTICOS EIRELI",
      "Telefone": "AVENIDA CARLOS DE MEDEIROS,",
      "Cidade/uf": "Baixo Guandu/ES",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "113484270116",
      "IE": "(11) 2944-8872",
      "Nome fantasia": "TERRA SECA",
      "Telefone": "Rua Bruno Zabala",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Conjunto Residencial José Bonifácio"
    },
    {
      "CNPJ": "215005548114",
      "IE": "(11) 4692-1996",
      "Nome fantasia": "KHARFAN MAGAZINE MOVEIS E DECORAÇOES LTDA",
      "Telefone": "AV FERDINANDO JUNGERS",
      "Cidade/uf": "Biritiba-Mirim/SP",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "635365561110",
      "IE": "(11) 4178-0394",
      "Nome fantasia": "MARY SOUFIA COMERCIO DE MOVEIS E DECORACOES",
      "Telefone": "Avenida do Taboão",
      "Cidade/uf": "Sao Bernardo do Campo/SP",
      "endereço": "Taboão"
    },
    {
      "CNPJ": "0034750280046",
      "Nome fantasia": "RENOVE CASA E DECORAÇÃO",
      "Telefone": "RUA CAPITAO JOAO ALVES DE AZEVEDO",
      "Cidade/uf": "Rodeiro/MG",
      "endereço": "CRUZEIRO"
    },
    {
      "CNPJ": "003496319.00-28",
      "Nome fantasia": "JUNIOR DECOR",
      "Telefone": "Rua José da Costa Marques",
      "Cidade/uf": "Uba/MG",
      "endereço": "Ponte Preta"
    },
    {
      "CNPJ": "114393446117",
      "IE": "(11) 2295-6562",
      "Nome fantasia": "LOJAS FENICIA LTDA",
      "Telefone": "Rua Maria Vieira Ribeiro",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Carrão"
    },
    {
      "CNPJ": "392336810110",
      "IE": "(12) 3959-2545",
      "Nome fantasia": "FSB COMERCIO DE MOVEIS LTDA",
      "Telefone": "Rua Quinze de Novembro",
      "Cidade/uf": "Jacarei/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "234139068116",
      "IE": "(12) 99763-2923",
      "Nome fantasia": "BONFIM LOURENÇO SOBRINHO",
      "Telefone": "Rua do Porto",
      "Cidade/uf": "Cacapava/SP",
      "endereço": "Jardim Rafael"
    },
    {
      "CNPJ": "112973366117",
      "IE": "(11) 3961-2530",
      "Nome fantasia": "HAMID ABDUL LAFIT FARES ME",
      "Telefone": "Avenida Deputado Emílio Carlos",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Limão"
    },
    {
      "CNPJ": "9089528639",
      "Nome fantasia": "IDH TRANSPORTES DE CARGAS LTDA",
      "Telefone": "Rua Ibaiti",
      "Cidade/uf": "Pinhais/PR",
      "endereço": "Emiliano Perneta"
    },
    {
      "CNPJ": "004259078.00-01",
      "Nome fantasia": "L.D. PARO COMERCIO VAREJISTA DE MOVEIS",
      "Telefone": "Rua Fábio Xavier Barletta",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes IV"
    },
    {
      "CNPJ": "0035131300029",
      "Nome fantasia": "MV DESIGN DE MOVEIS",
      "Telefone": "RUA COQUEIRAL",
      "Cidade/uf": "Uba/MG",
      "endereço": "RESIDENCIAL PAULINO"
    },
    {
      "CNPJ": "082514801",
      "Nome fantasia": "friovix",
      "Telefone": "Avenida Civit I",
      "Cidade/uf": "Serra/ES",
      "endereço": "Barro Branco"
    },
    {
      "CNPJ": "0019713350049",
      "IE": "(32) 98711-0398",
      "Nome fantasia": "MEGA MOVEIS",
      "Telefone": "AV. PRESIDENTE COSTA E SILVA",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "SAO PEDRO"
    },
    {
      "CNPJ": "0038429500057",
      "Nome fantasia": "BRAGA COMERCIO DE MOVEIS",
      "Telefone": "Rua Ezequiel Moreira de Abreu",
      "Cidade/uf": "Uba/MG",
      "endereço": "São Domingos"
    },
    {
      "CNPJ": "633782323119",
      "IE": "(13) 3216-1858",
      "Nome fantasia": "HOME SANTISTA LTDA",
      "Telefone": "Rua Amador Bueno",
      "Cidade/uf": "Santos/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0042548420043",
      "Nome fantasia": "W.G",
      "Telefone": "Rua Fábio Xavier Barletta",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes IV"
    },
    {
      "CNPJ": "0042168490066",
      "Nome fantasia": "GRAN DECORE",
      "Telefone": "Rua João Groppo",
      "Cidade/uf": "Uba/MG",
      "endereço": "Jardim Inês Groppo"
    },
    {
      "CNPJ": "116783302112",
      "Nome fantasia": "Moveis Dib Ltda",
      "Telefone": "Avenida Cupecê",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Cidade Ademar"
    },
    {
      "CNPJ": "0040251220095",
      "Nome fantasia": "MALU DECOR",
      "Telefone": "Rua  QUARESMEIRA",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes IV"
    },
    {
      "CNPJ": "9094646682",
      "Nome fantasia": "IDH TRANSPORTES DE CARGAS LTDA",
      "Telefone": "Rua Alberto de Oliveira",
      "Cidade/uf": "Maringa/PR",
      "endereço": "Zona 06"
    },
    {
      "CNPJ": "720330254.00-32",
      "Nome fantasia": "Bom Pastor",
      "Telefone": "RUA EDMAR DA SILVA BRAGA",
      "Cidade/uf": "Visconde do Rio Branco/MG",
      "endereço": "COLONIA"
    },
    {
      "CNPJ": "0043082130046",
      "Nome fantasia": "Agarb Decor",
      "Telefone": "Avenida Alvimar Miquelito",
      "Cidade/uf": "Uba/MG",
      "endereço": "Eldorado"
    },
    {
      "CNPJ": "083684913",
      "Nome fantasia": "FAURA",
      "Telefone": "Avenida Hans Schmoger",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Nossa Senhora da Conceição"
    },
    {
      "CNPJ": "003506710.00-01",
      "Nome fantasia": "VIRTUAL MOVEIS",
      "Telefone": "Rua José Marcelo da Rocha",
      "Cidade/uf": "Uba/MG",
      "endereço": "São Francisco de Assis"
    },
    {
      "CNPJ": "7209659180052",
      "IE": "(32) 3551-8500",
      "Nome fantasia": "MOVEIS MATOS E LOPES LTDA",
      "Telefone": "ALBERTO FRANCISCO ANTONIOL",
      "Cidade/uf": "Visconde do Rio Branco/MG",
      "endereço": "VILA APRAZIVEL"
    },
    {
      "CNPJ": "0035294880067",
      "Nome fantasia": "CASA MODELO HOME E DISIGNER",
      "Telefone": "RUA SEBASTIÃO TRINDADE MAGATON",
      "Cidade/uf": "UBA/MG",
      "endereço": "PONTE PRETA"
    },
    {
      "CNPJ": "0034458290029",
      "Nome fantasia": "CASTELLAR  MOBILLE DESIGN",
      "Telefone": "Rua Luiz Bigonha",
      "Cidade/uf": "Uba/MG",
      "endereço": "Louriçal"
    },
    {
      "CNPJ": "42517300046",
      "Nome fantasia": "MINDLOG",
      "Telefone": "Rua Gumercindo Couto e Silva",
      "Cidade/uf": "Belo Horizonte/MG",
      "endereço": "Itapoã"
    },
    {
      "CNPJ": "0040454740000",
      "IE": "(32) 8848-8175",
      "Nome fantasia": "BELLA DECOR",
      "Telefone": "Rua Orides do Nascimento",
      "Cidade/uf": "Uba/MG",
      "endereço": "Antônio Maranhão"
    },
    {
      "CNPJ": "0450003094",
      "IE": "(54) 9920-8881",
      "Nome fantasia": "BURATI PASSO VELHO",
      "Telefone": "VILA RIO BURATTI,",
      "Cidade/uf": "FARROUPILHA/RS",
      "endereço": "TERCEIRO DISTRITO"
    },
    {
      "CNPJ": "0420080104",
      "IE": "(54) 9920-8881",
      "Nome fantasia": "MLA",
      "Telefone": "RUA PORTÃO",
      "Cidade/uf": "ESTANCIA VELHA/RS",
      "endereço": "DISTRITO INDUSTRIAL"
    },
    {
      "CNPJ": "004128358.00-55",
      "IE": "(32) 98711-0398",
      "Nome fantasia": "Móveis e Ponto",
      "Telefone": "Av. Presidente Costa e Silva",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Bairro São Pedro"
    },
    {
      "CNPJ": "001218090.00-82",
      "IE": "(32) 99105-2875",
      "Nome fantasia": "Fenícia Mobiliário",
      "Telefone": "Rua Engenheiro Geraldo Magela de Mattos Sanábio",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Jóquei Clube"
    },
    {
      "CNPJ": "420069116112",
      "IE": "(12) 99746-2985",
      "Nome fantasia": "DIOGO DA COSTA HIPOLITO",
      "Telefone": "Rua Antônio Escada",
      "Cidade/uf": "Lorena/SP",
      "endereço": "Vila Santa Edwiges"
    },
    {
      "CNPJ": "0041013130014",
      "IE": "(32) 99984-8348",
      "Nome fantasia": "DALLAS MOVEIS",
      "Telefone": "RUA TRAJANO VIANA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "0100109462",
      "IE": "(54) 99907-8886",
      "Nome fantasia": "PRIMA DESIGN",
      "Telefone": "Avenida São Roque",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "Aparecida"
    },
    {
      "CNPJ": "420110180117",
      "IE": "(12) 3301-1205",
      "Nome fantasia": "LUCIA HELENA DA SILVA OLIVEIRA",
      "Telefone": "Avenida Coronel José Vicente",
      "Cidade/uf": "Lorena/SP",
      "endereço": "Vila Hepacare"
    },
    {
      "CNPJ": "081101945",
      "Nome fantasia": "A.C.P. INDUSTRIA DE MOVEIS LTDA",
      "Telefone": "Avenida dos Moveleiros",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Canivete"
    },
    {
      "CNPJ": "284719331",
      "Nome fantasia": "IDH TRANSPORTES DE CARGAS LTDA",
      "Telefone": "R. DORIVALDO MONTEIRO NOGUEIRA",
      "Cidade/uf": "Nova Alvorada do Sul/MS",
      "endereço": "MARIA DE LOURDES"
    },
    {
      "CNPJ": "003745336.00-56",
      "Nome fantasia": "Villa Mobi",
      "Telefone": "Rua Oscar Filgueiras Filho",
      "Cidade/uf": "Pirauba/MG",
      "endereço": "Distrito Industrial"
    },
    {
      "CNPJ": "0040259570087",
      "IE": "(32) 98474-1970",
      "Nome fantasia": "CASA 3 COMMERCE",
      "Telefone": "RUA FARMACEUTICO MARIO DE AZEVEDO",
      "Cidade/uf": "Uba/MG",
      "endereço": "JARDIM GLORIA"
    },
    {
      "CNPJ": "370104439118",
      "IE": "(11) 3659-7477",
      "Nome fantasia": "XV DE NOVEMBRO MOVEIS E DECORACOES EIRELI",
      "Telefone": "AVENIDA QUINZE DE NOVEMBRO,",
      "Cidade/uf": "ITAPECERICA DA SERRA/SP",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "0041546300040",
      "Nome fantasia": "DALLAS MOVEIS",
      "Telefone": "TERCEIRA AVENIDA TRAJANO VIANA",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "CENTRO"
    },
    {
      "CNPJ": "004320540.00-46",
      "Nome fantasia": "STANZA DECOR",
      "Telefone": "Rua Vereador Nicolino de Filipo",
      "Cidade/uf": "Uba/MG",
      "endereço": "Talma"
    },
    {
      "CNPJ": "083160981",
      "Nome fantasia": "LCR COMERCIO DE MOVEIS LTDA",
      "Telefone": "Rua Edson Antônio Breda",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Canivete"
    },
    {
      "CNPJ": "90699386-44",
      "Nome fantasia": "Eletrolux",
      "Telefone": "Rua João Lunardelli",
      "Cidade/uf": "Curitiba/PR",
      "endereço": "Cidade Industrial"
    },
    {
      "CNPJ": "140086440111",
      "IE": "(11) 5824-9143",
      "Nome fantasia": "CEDRO DO LIBANO MOVEIS EIRELI",
      "Telefone": "Estrada de Itapecerica",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Capão Redondo"
    },
    {
      "CNPJ": "6156575590243",
      "Nome fantasia": "SHOPKASA",
      "Telefone": "PREFEITO JOAO VICENTE FERREIRA FILHO",
      "Cidade/uf": "SAO GERALDO/MG",
      "endereço": "ANTONIO JOSE DA SILVA PORTUGUES"
    },
    {
      "CNPJ": "142947248119",
      "IE": "(11) 2581-4341",
      "Nome fantasia": "ADRIANA FREITAS DE SOUZA COLCHOES E MOVEIS",
      "Telefone": "Rua Salsa Parrilha",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Jardim Maia"
    },
    {
      "CNPJ": "112573456115",
      "IE": "(11) 3659-7477",
      "Nome fantasia": "ALI Y DAHOUK MOVEIS ME",
      "Telefone": "Rua Mariquinha Esteves",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Parque Planalto"
    },
    {
      "CNPJ": "0040498040046",
      "Nome fantasia": "João Victor",
      "Telefone": "Rua Teixeira Soares",
      "Cidade/uf": "Uba/MG",
      "endereço": "Industrial"
    },
    {
      "CNPJ": "004348872.00-98",
      "Nome fantasia": "PROCONFORT COMERCIO DE MOVEIS LTDA",
      "Telefone": "Rua Ipê",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes"
    },
    {
      "CNPJ": "3676480515111",
      "Nome fantasia": "MINDLOG",
      "Telefone": "Avenida Francisco Bernardino",
      "Cidade/uf": "Juiz de Fora/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "141567186117",
      "IE": "(11) 5874-0741",
      "Nome fantasia": "MAGAZINE MEGA HOUSE MOVEIS LTDA EPP",
      "Telefone": "Avenida Ellis Maas",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Capão Redondo"
    },
    {
      "CNPJ": "0040842870083",
      "Nome fantasia": "MG COMERCIO DE MOVEIS EIRELI",
      "Telefone": "rod. UBA/Guidoval",
      "Cidade/uf": "Guidoval/MG",
      "endereço": "rodovia"
    },
    {
      "CNPJ": "0030112610021",
      "Nome fantasia": "GMARI ESTOFADOS",
      "Telefone": "Avenida Padre Arnaldo Jansen",
      "Cidade/uf": "Uba/MG",
      "endereço": "Santana"
    },
    {
      "CNPJ": "083198970",
      "Nome fantasia": "MEGAMAMUTE COMERCIO ONLINE DE ELTRONICOS E INFORMATICA",
      "Telefone": "Rua José Acácio Ferreira",
      "Cidade/uf": "Viana/ES",
      "endereço": "Parque Industrial"
    },
    {
      "CNPJ": "298.377.660.110",
      "Nome fantasia": "MINDLOG",
      "Telefone": "Rua Marcelino Pinto Teixeira",
      "Cidade/uf": "Embu das Artes/SP",
      "endereço": "Gramado"
    },
    {
      "CNPJ": "109629598118",
      "IE": "(11) 2918-4590",
      "Nome fantasia": "MOBILIADORA ITIDALE COMERCIO DE MOVEIS LTDA",
      "Telefone": "Avenida Sapopemba",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Sapopemba"
    },
    {
      "CNPJ": "003498000.00-61",
      "Nome fantasia": "MADPLAST MOVEIS E ACESSORIOS LTDA",
      "Telefone": "Rua Marieta Augusta Silva Marcos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Olaria"
    },
    {
      "CNPJ": "258637102",
      "Nome fantasia": "MUA ECOMMERCE",
      "Telefone": "Avenida Nereu Ramos - E",
      "Cidade/uf": "Chapeco/SC",
      "endereço": "Centro"
    },
    {
      "CNPJ": "6992325590019",
      "Nome fantasia": "PIZZIOLO",
      "Telefone": "Rua José Gomes Braga",
      "Cidade/uf": "Uba/MG",
      "endereço": "Boa Vista"
    },
    {
      "CNPJ": "420153640114",
      "IE": "(12) 3125-9363",
      "Nome fantasia": "PEDRO MUNIZ MAIA",
      "Telefone": "Avenida Sete de Setembro",
      "Cidade/uf": "Lorena/SP",
      "endereço": "Cruz"
    },
    {
      "CNPJ": "0010352990066",
      "IE": "(32) 3577-1664",
      "Nome fantasia": "ART BEL",
      "Telefone": "AV DAS INDUSTRIAS",
      "Cidade/uf": "RODEIRO/MG",
      "endereço": "BELO HORIZONTE"
    },
    {
      "CNPJ": "0010159320062",
      "Nome fantasia": "Estofamar",
      "Telefone": "Rodovia MG 447 do km 01 ao km 08",
      "Cidade/uf": "Uba/MG",
      "endereço": "Eixo Rodoviário"
    },
    {
      "CNPJ": "6992961560095",
      "Nome fantasia": "Mavaular Móveis",
      "Telefone": "Rod Ubá - Rodeira,KM 3 - Caixa Postal  50",
      "Cidade/uf": "Uba/MG",
      "endereço": "Zona Rural"
    },
    {
      "CNPJ": "003221290.00-74",
      "Nome fantasia": "ROGGI MOVEIS E DECORAÇÃO",
      "Telefone": "Rua Wilson Campos",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "0041962500012",
      "Nome fantasia": "FACILITA DECOR",
      "Telefone": "Rua Azaléia",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes"
    },
    {
      "CNPJ": "127.398.966.114",
      "Nome fantasia": "IDH TRANSPORTES DE CARGAS LTDA",
      "Telefone": "Estrada Municipal",
      "Cidade/uf": "Guarulhos/SP",
      "endereço": "Água Chata"
    },
    {
      "CNPJ": "149654760113",
      "Nome fantasia": "Flavia Rosimeire Ramos dos Santos Me",
      "Telefone": "Rua do Outono",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Itaberaba"
    },
    {
      "CNPJ": "6998796440098",
      "Nome fantasia": "ESTOFAMAR",
      "Telefone": "MG 447 RODOVIA - UBA MG",
      "Cidade/uf": "Uba/MG",
      "endereço": "RODOVIA UBA"
    },
    {
      "CNPJ": "003808985.00-30",
      "Nome fantasia": "FZ ESTOFADOS LTDA",
      "Telefone": "Rua Doutor Domingos Peluso",
      "Cidade/uf": "Uba/MG",
      "endereço": "Vila Regina"
    },
    {
      "CNPJ": "90845453-73",
      "Nome fantasia": "BRAVO",
      "Telefone": "Rua Montese",
      "Cidade/uf": "Curitiba/PR",
      "endereço": "Parolin"
    },
    {
      "CNPJ": "083.778.20-9",
      "Nome fantasia": "CONECTMOVEIS",
      "Telefone": "Rua Espanha",
      "Cidade/uf": "Linhares/ES",
      "endereço": "Jardim Laguna"
    },
    {
      "CNPJ": "0032461970130",
      "Nome fantasia": "Outlet Só Sofá",
      "Telefone": "Avenida Edson Pacheco,",
      "Cidade/uf": "UBA/MG",
      "endereço": "Ligação"
    },
    {
      "CNPJ": "004124836.00-44",
      "Nome fantasia": "ARMCHAIR DECOR",
      "Telefone": "Rua Domitila Castanon",
      "Cidade/uf": "Uba/MG",
      "endereço": "Eldorado"
    },
    {
      "CNPJ": "0026903810072",
      "IE": "(32) 9963-0140",
      "Nome fantasia": "ARMAZEM UBA",
      "Telefone": "Rua Ângelo Sperandio",
      "Cidade/uf": "Uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "004048469.00-78",
      "Nome fantasia": "DALLU DECOR",
      "Telefone": "Rua Eliza Amaral Peron",
      "Cidade/uf": "Uba/MG",
      "endereço": "Agroceres"
    },
    {
      "CNPJ": "083830960",
      "Nome fantasia": "MLP MOVEIS LTDA",
      "Telefone": "AV PREF. JOSE V SCHERRER",
      "Cidade/uf": "Piuma/ES",
      "endereço": "ACAIACA"
    },
    {
      "CNPJ": "0017946560168",
      "Nome fantasia": "FRIO Peças",
      "Telefone": "Rod Fernão dias",
      "Cidade/uf": "Extrema/MG",
      "endereço": "Bairro Jardim"
    },
    {
      "CNPJ": "0100187200",
      "IE": "(51) 99812-0148",
      "Nome fantasia": "Movel Na Caixa",
      "Telefone": "Rua Giovanni Baptista Fracalossi",
      "Cidade/uf": "bento goncalves/RS",
      "endereço": "São Valentin"
    },
    {
      "CNPJ": "083361049",
      "IE": "(32) 99817-3235",
      "Nome fantasia": "Moveis Bom Preço",
      "Telefone": "Rua Benedito de Oliveira Coutinho",
      "Cidade/uf": "guarapari/ES",
      "endereço": "Praia do Morro"
    },
    {
      "CNPJ": "0031627650091",
      "Nome fantasia": "SUPER COMPRAS",
      "Telefone": "Rua José Betio",
      "Cidade/uf": "uba/MG",
      "endereço": "Mangueira Rural"
    },
    {
      "CNPJ": "123456789",
      "IE": "(11) 97359-3635",
      "Nome fantasia": "BROWM",
      "Telefone": "Rua João Colalilo",
      "Cidade/uf": "maua/SP",
      "endereço": "Jardim Zaira"
    },
    {
      "CNPJ": "9066232180",
      "IE": "(43) 9113-8055",
      "Nome fantasia": "Julia Arns",
      "Telefone": "Rua Ponta Grossa",
      "Cidade/uf": "londrina/PR",
      "endereço": "Dom Bosco"
    },
    {
      "CNPJ": "0100197760",
      "IE": "(54) 99975-7855",
      "Nome fantasia": "LAZA SHOP",
      "Telefone": "Rua Marcos Valduga",
      "Cidade/uf": "Bento Goncalves/RS",
      "endereço": "Santa Helena"
    },
    {
      "CNPJ": "6996280950082",
      "IE": "(32) 3539-2300",
      "Nome fantasia": "PANORAMA MOVEIS",
      "Telefone": "Avenida Vereador Rafael Girardi 64",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0022459290039",
      "IE": "(32) 8404-3975",
      "Nome fantasia": "SMART DESIGN MOVELARIA",
      "Telefone": "Rua José Caneschi",
      "Cidade/uf": "Uba/MG",
      "endereço": "Louriçal"
    },
    {
      "CNPJ": "112625312111",
      "IE": "(11) 3838-2211",
      "Nome fantasia": "LEO MADEIRAS",
      "Telefone": "Rua Bartolomeu Paes",
      "Cidade/uf": "Sao Paulo/SP",
      "endereço": "Vila Anastácio"
    },
    {
      "CNPJ": "442374677112",
      "IE": "(11) 3593-1957",
      "Nome fantasia": "Rogerio Xavier de Carvalho Moveis",
      "Telefone": "Avenida Dom José Gaspar",
      "Cidade/uf": "Maua/SP",
      "endereço": "Matriz"
    },
    {
      "CNPJ": "0041601580088",
      "IE": "(32) 99962-5129",
      "Nome fantasia": "Móveis Elsio",
      "Telefone": "Rua Coronel Júlio Soares",
      "Cidade/uf": "Uba/MG",
      "endereço": "Centro"
    },
    {
      "CNPJ": "528187962110",
      "IE": "(12) 3522-6836",
      "Nome fantasia": "CAMILARI COLCHOES LTDA",
      "Telefone": "Rua dos Andradas",
      "Cidade/uf": "Pindamonhangaba/SP",
      "endereço": "Centro"
    },
    {
      "CNPJ": "0028976180038",
      "Nome fantasia": "TH DECORAÇOES",
      "Telefone": "KM 04 RODOVIA MG 447",
      "Cidade/uf": "Visconde do Rio Branco/MG",
      "endereço": "centro"
    },
    {
      "CNPJ": "37260700033",
      "Nome fantasia": "Simplifique Tech",
      "Telefone": "Vila Pedro Cortez",
      "Cidade/uf": "Uba/MG",
      "endereço": "São Domingos"
    },
    {
      "CNPJ": "6998784210033",
      "Nome fantasia": "CAROLINA BABY",
      "Telefone": "SANTA MARIA",
      "Cidade/uf": "Uba/MG",
      "endereço": "Diamante de Ubá"
    },
    {
      "CNPJ": "720330254.04-58",
      "Nome fantasia": "Bom Pastor",
      "Telefone": "RUA CIRA GOMES SARAIVA",
      "Cidade/uf": "Visconde do Rio Branco/MG",
      "endereço": "COLONIA"
    },
    {
      "CNPJ": "0043323800010",
      "Nome fantasia": "London Moveis",
      "Telefone": "Avenida Paulino Fernandes",
      "Cidade/uf": "Uba/MG",
      "endereço": "Paulino Fernandes IV"
    }
  ];
  
  const colunasExemplo = ['CNPJ', 'IE',"Nome fantasia", 'Telefone', 'Cidade/uf', 'endereço'];
  return (
    <div className="PageRastreio">

      <TitlePage 
        title="Rastreie seu pedido" 
        subTitle="Insira o código de rastreamento para acompanhar a entrega do seu pedido">
      </TitlePage>

      <BoxInputRastreio/>
    </div>
  );
}

export default PageRastreio;
