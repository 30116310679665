import './BoxInputCotacao.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useState, useEffect } from 'react';
import { calculaFrete, calculaFrete2 } from '../../Service/CotacaoService'
  import InputMask from 'react-input-mask'; 

function BoxInputCotacao({ onResultCotacao, onRequestCotacao, tipoInput }) {
  
  const [tipoFrete, setTipoFrete] = useState(tipoInput);
  
  const [resquestCotacao, setRequestCotacao] = useState({
    cepOrigem: "",
    cepDestino: "",
    pesoPacote: "",
    altura: "",
    largura: "",
    diametro: "",
    valorNotaFiscal: ""
  });

  const [maskCotacao, setMaskCotacao] = useState({
    cepOrigem: "",
    cepDestino: "",
    pesoPacote: "",
    altura: "",
    largura: "",
    diametro: "",
    valorNotaFiscal: ""
  });


  const [respCotacao, setRespCotacao] = useState()

  const [metragemCubica, setMetragemCubica] = useState(0);
  const [cidadeOrigem, setCidadeOrigem] = useState("-")
  const [cidadeDestino, setCidadeDestino] = useState("-")

  
  //Monitora o CEP
  useEffect(() => {
    setTimeout(() => {
      
  
      if(resquestCotacao.cepOrigem.length == 9){
        fetch('https://brasilapi.com.br/api/cep/v1/' + resquestCotacao.cepOrigem).then(resp => {
          if (!resp.ok) {
            setCidadeOrigem("-")
          }
          return resp.json(); // Converte a resposta para JSON
        })
        .then(data => {
          setCidadeOrigem(data.city + "/" + data.state)
          if(data.errors) setCidadeOrigem("-")
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }

      if(resquestCotacao.cepDestino.length == 9){
        fetch('https://brasilapi.com.br/api/cep/v1/' + resquestCotacao.cepDestino).then(resp => {
          if (!resp.ok) {
            setCidadeDestino("-")
          }
          return resp.json(); // Converte a resposta para JSON
        })
        .then(data => {
          setCidadeDestino(data.city + "/" + data.state)
          if(data.errors) setCidadeDestino("-")
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      

      setMetragemCubica((resquestCotacao.altura * resquestCotacao.diametro * resquestCotacao.largura).toFixed(2))
    }, 300);
  });
  

  //Monitora o tipoInput
  useEffect(() => {
    if (tipoInput == 3) {
      console.log("setando o pesoFinal")
      setRequestCotacao({
        ...resquestCotacao,
        pesoPacote: 0
      });
    }
  }, [tipoInput]);

  
  //Se a resposta for zero é porque está buscando as informações
  const handleClick = () => {


    onResultCotacao(0);
    onRequestCotacao(resquestCotacao);
    console.log("request cotacao ->", resquestCotacao);
    
    var pesoPacote = parseFloat(resquestCotacao.pesoPacote);
    var volCubico = metragemCubica;


    calculaFrete2(resquestCotacao.cepDestino.replace("-",""), resquestCotacao.cepOrigem.replace("-",""), pesoPacote, resquestCotacao.valorNotaFiscal, tipoFrete, volCubico).then(resp => {

      const resp1 = [
        ...resp,  
        {
          request: resquestCotacao
        }]
        setRespCotacao(resp1);
        onResultCotacao(resp1);
        console.log("RESP ->", resp1)

    }).catch(error => {
      console.error("Erro ao calcular frete:", error);
    });


  };

  function maskM(string){
    
    if(string == ""){
      string = " m"
    }

    var onlyNumber

    //Caso esteja apagando
    if(string.slice(-1) == " ")
    {
       onlyNumber = string.substring(0, string.length -3)
    }
    
    //Caso esteja escrevendo
    if(string.slice(-1) != " ")
    {
      onlyNumber = string.replace(" m", "")
    }

    onlyNumber = onlyNumber.replace(".", "")
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)

    if (intNumber == ""){
      return "0." + decimalNumber + " m"
    }

    if (intNumber[0] == 0 && intNumber[1] == 0 & intNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }

    if (intNumber[0] == 0 && intNumber.length>1){
      intNumber = intNumber.substring(1, intNumber.length)
    }

    
    return intNumber + "." + decimalNumber + " m"
  }

  function maskKg(string){

    
    if(string == ""){
      string = " kg"
    }

    var onlyNumber

    //Caso esteja apagando
    if(string.slice(-1) == "k")
    {
       onlyNumber = string.substring(0, string.length -3)
    }
    
    //Caso esteja escrevendo
    if(string.slice(-1) != "k")
    {
      onlyNumber = string.replace(" kg", "")
    }

    onlyNumber = onlyNumber.replace(".", "")
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)

    if (intNumber == ""){
      return "0." + decimalNumber + " kg"
    }

    if (intNumber[0] == 0 && intNumber[1] == 0 & intNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }

    if (intNumber[0] == 0 && intNumber.length>1){
      intNumber = intNumber.substring(1, intNumber.length)
    }

    
    return intNumber + "." + decimalNumber + " kg"
  }

  function maskRs(string){
    
    if(string == ""){
      string = "R$ 00.00"
    }

    var onlyNumber = string.replace('R$ ', '')
    onlyNumber = onlyNumber.replace('.', '')
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)

    if (intNumber[0] == 0 && intNumber[1] == 0 & onlyNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }
    return "R$ " + intNumber + "." + decimalNumber
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setRequestCotacao({
      ...resquestCotacao,
      [name]: value,
    });
  };

  const handleInputMaskChange = (event) => {
    const { name, value } = event.target;

  
    setMaskCotacao({
      ...maskCotacao,
      [name]: value,
    });

    if(name == 'valorNotaFiscal'){
      const valorNum = parseFloat(maskM(value).replace("R$ ", ""))
      return setRequestCotacao({
        ...resquestCotacao,
        [name]: valorNum
      })
    }

    if(name == 'pesoPacote'){
      const valorNum = parseFloat(maskKg(value).replace(" kg", ""))
      
      return setRequestCotacao({
        ...resquestCotacao,
        [name]: valorNum
      })
    }

    const valorNum = parseFloat(maskM(value).replace(" m²", ""))
    setRequestCotacao({
      ...resquestCotacao,
      [name]: valorNum
    })

  };
  
  

  return (
    <div className="BoxInputCotacao">

       {
        tipoInput == 1 && (
          <>
           <Container>
            <Row style={{ textAlign: 'left' }}>
              <a className='titleBox'>Informações do pacote</a>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <a className='subTitleBox'>Preencha todos os campos para realizar sua cotação</a>
            </Row>
          </Container>
          <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 5%', flexWrap: "wrap" }}>

            <InputMask mask="99999-999"  maskChar="" value={resquestCotacao.cepOrigem} onChange={handleInputChange}>
              {(inputProps) => <Input content="Cep Origem" width="20%" name="cepOrigem" {...inputProps} />}
            </InputMask>

            <InputMask mask="99999-999" maskChar="" value={resquestCotacao.cepDestino} onChange={handleInputChange}>
              {(inputProps) => <Input content="Cep Destino" width="20%" name="cepDestino" {...inputProps} />}
            </InputMask>
            
            <Input backgroundColor="rgb(230, 230, 230)" cursor="not-allowed" content="Cidade Origem" width="20%"  value={cidadeOrigem}></Input>
            <Input backgroundColor="rgb(230, 230, 230)" cursor="not-allowed" content="Cidade Destino" width="20%" value={cidadeDestino}></Input>
          </div>
          <div style={{ marginTop: "4vh", display: 'flex', gap: '2vh 5%'}}>
          
            <Input content="Peso do pacote" width="25%" name="pesoPacote" value={maskKg(maskCotacao.pesoPacote)} onChange={handleInputMaskChange}></Input>
            <Input content="Valor da nota fiscal" width="25%" name="valorNotaFiscal" value={maskRs(maskCotacao.valorNotaFiscal)} onChange={handleInputMaskChange}></Input>

          </div>

          <div style={{ marginTop: "4vh", display: 'flex', gap: '2vh 5%'}}>
            <Input content="Altura" prefix="R$" name="altura" width="15%" value={maskM(maskCotacao.altura)} onChange={handleInputMaskChange}></Input>
            <Input content="Largura" name="largura"  width="15%" value={maskM(maskCotacao.largura)} onChange={handleInputMaskChange}></Input>
            <Input content="Diametro" name="diametro"  width="15%" value={maskM(maskCotacao.diametro)} onChange={handleInputMaskChange}></Input>
            <Input backgroundColor="rgb(230, 230, 230)" cursor="not-allowed" content="vol. cúbico" width="15%" value={metragemCubica  + " m³"}></Input>
          </div>
        </>
    )}

      {
        tipoInput == 3 && (
          <>
           <Container>
            <Row style={{ textAlign: 'left' }}>
              <a className='titleBox'>Informações do pacote</a>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <a className='subTitleBox'>Preencha todos os campos para realizar sua cotação</a>
            </Row>
          </Container>

          <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 5%', flexWrap: "wrap" }}>

            <InputMask mask="99999-999"  maskChar="" value={resquestCotacao.cepOrigem} onChange={handleInputChange}>
              {(inputProps) => <Input content="Cep Origem" width="20%" name="cepOrigem" {...inputProps} />}
            </InputMask>

            <InputMask mask="99999-999" maskChar="" value={resquestCotacao.cepDestino} onChange={handleInputChange}>
              {(inputProps) => <Input content="Cep Destino" width="20%" name="cepDestino" {...inputProps} />}
            </InputMask>
            
            <Input backgroundColor="rgb(230, 230, 230)" cursor="not-allowed" content="Cidade Origem" width="20%"  value={cidadeOrigem}></Input>
            <Input backgroundColor="rgb(230, 230, 230)" cursor="not-allowed" content="Cidade Destino" width="20%" value={cidadeDestino}></Input>
          </div>
          
            <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 5%', flexWrap: "wrap" }}>
                <Input content="Valor CTE" width="25%" name="valorNotaFiscal" value={maskRs(maskCotacao.valorNotaFiscal)} onChange={handleInputMaskChange}></Input>
            </div>
          </>
        )
      }
     
      

   
      
      <div style={{ marginTop: "10vh", display: 'flex', justifyContent: 'end' }}>
        <Button title="Realizar cotação!" width="100%" height="6vh" onClick={handleClick}></Button>
      </div>
    </div>
  );
}

export default BoxInputCotacao;
