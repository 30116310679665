const apiUrl = 'https://us-central1-logiflow-backend.cloudfunctions.net/api/';
// const apiUrl = 'http://localhost:3000/';


async function getMensagem(idTransportadora) {
  
  try {
    const response = await fetch(apiUrl + "mensagem/getMensagens/" +idTransportadora, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    console.log("response -> ", await response)

    if(response.status != 404){
      const data = await response.json();
      console.log('resposta getMensagens -> ', data)
  
      return data;
    }
    
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

async function createMensagem(arrayMensagens) {
  try {
    const response = await fetch(apiUrl + "mensagem/createMensagem", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(arrayMensagens)
    });

    
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

export { getMensagem, createMensagem }