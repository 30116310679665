import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxInputSuporte from '../../../Components/BoxSuporte/BoxSuporte';

import './pageSuporte.css';


function Suporte() {
    return (
        <div className="Suporte">

            <TitlePage title="Suporte" subTitle="Tem algum problema ou sugestão? fale com a gente" />
            <div style={{ display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>

                <TitlePage title="Informações Relevantes" />

                <div style={{ display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>
                <BoxInputSuporte />
                </div>
            </div>



        </div>
    );
}

export default Suporte;
