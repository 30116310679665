import './Table.css';
import { useState } from 'react';
import { FaSort, FaSortUp, FaSortDown, FaSearch } from 'react-icons/fa';
import Base64Image from '../Base64Image/Base64Image';

function Table({ dados, colunas, botao, base64Data, onLinhaClicada }) {
  const [sortColumns, setSortColumns] = useState([]); // Colunas pelas quais a tabela está ordenada
  const [sortDirections, setSortDirections] = useState([]); // Direções de ordenação (ascendente ou descendente)
  const [searchValue, setSearchValue] = useState(''); // Valor de pesquisa


  const handleButtonClick = (rowIndex) => {
    const linhaClicada = sortedAndFilteredData[rowIndex];
    onLinhaClicada(linhaClicada);
  };

  const handleColumnClick = (colIndex) => {
    const existingIndex = sortColumns.indexOf(colIndex);

    if (existingIndex !== -1) {
      const newSortDirections = [...sortDirections];
      if (newSortDirections[existingIndex] === 'asc') {
        newSortDirections[existingIndex] = 'desc';
      } else if (newSortDirections[existingIndex] === 'desc') {
        newSortDirections.splice(existingIndex, 1);
        setSortColumns([...sortColumns.slice(0, existingIndex), ...sortColumns.slice(existingIndex + 1)]);
      }
      setSortDirections(newSortDirections);
    } else {
      setSortColumns([...sortColumns, colIndex]);
      setSortDirections([...sortDirections, 'asc']);
    }
  };

  const handleSortIcon = (colIndex) => {
    const sortIndex = sortColumns.indexOf(colIndex);
    if (sortIndex !== -1) {
      const index = sortIndex + 1;
      return sortDirections[sortIndex] === 'asc' ? <span><a style={{ fontSize: "8px" }}>{index}</a><FaSortUp /></span> : <span><a style={{ fontSize: "8px" }}>{index}</a><FaSortDown /></span>;
    }
    return <FaSort />;
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredData = dados.filter((linha) =>
    colunas.some((coluna) =>
      linha[coluna] && linha[coluna].toString().toLowerCase().includes(searchValue.toLowerCase())
    )
  );

  const sortedAndFilteredData = [...filteredData].sort((a, b) => {
    for (let i = 0; i < sortColumns.length; i++) {
      const colIndex = sortColumns[i];
      const direction = sortDirections[i] === 'asc' ? 1 : -1;

      if (a[colunas[colIndex]] < b[colunas[colIndex]]) {
        return -1 * direction;
      }
      if (a[colunas[colIndex]] > b[colunas[colIndex]]) {
        return 1 * direction;
      }
    }
    return 0;
  });

  return (
    <div className="divTabela">
      <div className='bgTabela'>
        <table>
          <thead>
            <tr>
              <th colSpan='60'>
                <div contenteditable tabindex="1" className="searchContainer">
                  <FaSearch className="searchIcon" /> {/* Ícone de pesquisa */}
                  <input
                    type="text"
                    placeholder="Pesquisar..."
                    value={searchValue}
                    onChange={handleSearchChange}
                  />
                </div>
              </th>
            </tr>
            <tr>

              {base64Data && (
                <th></th>
              )}

              {colunas.map((coluna, index) => (
                <th key={index} onClick={() => handleColumnClick(index)}>
                  {coluna} {handleSortIcon(index)}
                </th>
              ))}
              {botao && (
                <th style={{ width: '20%', textAlign: 'center' }}>Clique e conheça</th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedAndFilteredData.map((linha, index) => (
              <tr key={index}>

                {base64Data && (
                  <td style={{ width: '50px' }}>
                    <Base64Image base64Data={linha['imagePerfil']} width="70" height="70" borderRadius="5px"></Base64Image>
                  </td>
                )}

                {colunas.map((coluna, colIndex) => (
                  <td key={colIndex}>
                    {linha[coluna]}
                  </td>
                ))}

                {botao && (
                  <td>
                    <div className="botaoTable" onClick={() => handleButtonClick(index)}>
                      {botao}
                    </div>
                  </td>
                )}

              </tr>
            ))}
          </tbody>
        </table>
      </div>

    </div>
  );
}

export default Table;






