import React from 'react';
import './ModalSuport.css';
import Button from '../Button/Button';
import Input from '../Input/Input';
import styled from 'styled-components';
import TitlePage from '../TitlePage/TitlePage';

const InsertInput = styled.textarea`
  height: 40vh;
  border-radius: 10px;
  border: 1px solid grey;
  transition: 1s;
  padding: 0.5rem;
  resize: none;
  margin-bottom: 1vw;

  &:focus {
    outline: none;
    border-color: #9E3FFD;
    box-shadow: 0 0 5px black;
    transition: 1s;
  }
`;

function ModalSuport(props) {
  return (
    <div className='ModalSuport'>
      <div className='DivModalSuport'>
        <div style={{ marginTop: "-1vh", width: "100%", textAlign: "end", fontWeight: "bold" }}>
          <a style={{ cursor: "pointer" }} onClick={props.onClose}>X</a>
        </div>

        <TitlePage
          title="Mande uma mensagem para nossos especialistas"
          subTitle="A equipe especializada da Logflow irá ler e analisar sua demanda, e te responderemos em menos de 24 horas, enviando uma mensagem para o email cadastrado"
        />

        <Input content="Assunto" width="98%" />

        <p className='demandaTitle'>Mensagem</p>
        <InsertInput
          style={{ width: "97%", marginLeft: props.marginLeft, backgroundColor: props.backgroundColor }}
          value={props.value}
          onChange={props.onChange}
          name={props.name}
          type={props.type}
        />

        <Button title="Enviar mensagem para equipe logFlow" width="100%" height="10%" />
      </div>
    </div>
  );
}

export default ModalSuport;
