import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxInputPacote from '../../../Components/BoxInputPacote/BoxInputPacote';
import BoxResultCotacao from '../../../Components/BoxResultCotacao/BoxResultCotacao';

import './Armazenagem.css';
import BoxResultProduto from '../../../Components/BoxProduct/BoxProduct';

import { useEffect, useState } from 'react';
import { getTransportadoras } from '../../../Service/TransportadoraService';

import Table from '../../../Components/Table/Table';

function Armazenagem() {

    const [precoMetros2FromBox, setPrecoMetros2FromBox] = useState(null);
    const [transportadorasComArmazenagem, setTransportadorasComArmazenagem] = useState([])
    const [dadosArmazenagem, setDadosArmazenagem] = useState([])

    useEffect(() => { 
        getTransportadoras().then(resp => {
          console.log("RESPOSTA do getTransportadoras -> ", resp)
          let arrayFiltrado = []
          arrayFiltrado.push(resp.filter((e) => e.precoArmazenagem))
          setTransportadorasComArmazenagem(resp.filter((e) => e.precoArmazenagem))
          
        });
        
      }, []);

      const handlePrecoMetros2 = (precoMetros2) => {
        // Set the received value in the state
        setPrecoMetros2FromBox(precoMetros2);
        setDadosArmazenagem(dadosArmazenagem => []);
        transportadorasComArmazenagem.forEach(element => {
            setDadosArmazenagem(prevData =>[
                ...prevData,
                {
                    'Nome da transportadora': element.nome,
                    'valor': 'R$ ' + (precoMetros2 * element.precoArmazenagem)
                }
            ])
          }); 
      };

      const colunasArmazenagem = ['Nome da transportadora', 'valor'];

    return (
        <div className="Armazenagem">

            <TitlePage title="Armazenagem" subTitle="Faça uma cotação para armazenar sua encomenda" />
            <div style={{ display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>
                <BoxInputPacote onButtonClick={handlePrecoMetros2}/>
            </div>

            <div style={{ display: 'flex', flexDirection: "row", gap: "5vh 5vw", width: '100%', flexWrap: 'wrap', marginTop: "5vh" }}>

                
                <BoxResultProduto
                    nomeProduto="Picking"
                    content="Contrate uma equipe especializada para realizar a separação dos produtos de acordo com os pedidos, garantido agilidade e precisão no processo de separação e envio"
                />
                <BoxResultProduto
                    nomeProduto="Packing"
                    content="Realize a embalagem dos produtos de forma adequada, garantindo que cheguem ao destino final em perfeitas condições"
                />
            </div>

            <div style={{marginTop: '80px'}}>
                <TitlePage title="Cotações disponíveis" />
                <Table dados={dadosArmazenagem} colunas={colunasArmazenagem} botao={'Realizar cotação'}></Table>
            </div>

        </div>
    );
}

export default Armazenagem;
