import './PagePassword.css';
import BoxInputPassword from '../../../Components/BoxInputPassword/BoxInputPassword';


function PagePassword() {

    return (
        <div className='PagePassword'>
            <BoxInputPassword></BoxInputPassword>
        </div>
    );
}

export default PagePassword;

