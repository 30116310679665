import React, { useState, useEffect } from 'react';
import { FaTimes, FaExclamationCircle, FaCheckCircle } from 'react-icons/fa'; // Importando ícones
import './Alert.css';

function Alert(props) {
  const [isRed, setIsRed] = useState(false);
  const [isBlue, setIsBlue] = useState(false);
  const [isGreen, setIsGreen] = useState(false);
  const [isYellow, setIsYellow] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  useEffect(() => {
    setIsClosing(false); // Resetting isClosing when the color changes

    switch (props.color) {
      case 'green':
        setIsGreen(true);
        setIsRed(false);
        setIsBlue(false);
        setIsYellow(false);
        break;
      case 'blue':
        setIsBlue(true);
        setIsRed(false);
        setIsGreen(false);
        setIsYellow(false);
        break;
      case 'yellow':
        setIsYellow(true);
        setIsRed(false);
        setIsGreen(false);
        setIsBlue(false);
        break;
      default:
        setIsRed(true);
        setIsBlue(false);
        setIsGreen(false);
        setIsYellow(false);
        break;
    }
  }, [props.color]);

  const handleCloseAlert = () => {
    setIsClosing(true); // Applying closing animation
    setTimeout(() => {
      setIsRed(false);
      setIsBlue(false);
      setIsGreen(false);
      setIsYellow(false);
      setIsClosing(false); // Resetting closing animation
    }, 300); // Adjust the timeout based on your CSS transition duration
  };

  return (
    <div style={{ height: props.height, width: props.width}}>
      {isRed && (
        <div className={`alert warning ${isClosing ? 'alert-closing' : ''}`}>
          <FaExclamationCircle size={40} /> 
          <p>
            {props.message}
          </p>
          <span className="alert-close" onClick={handleCloseAlert}>
            <FaTimes /> {/* Ícone de fechar */}
          </span>
        </div>
      )}
      {isBlue && (
        <div className={`alert info ${isClosing ? 'alert-closing' : ''}`}>
          <p>{props.message}</p>
          <span className="alert-close" onClick={handleCloseAlert}>
            <FaTimes /> {/* Ícone de fechar */}
          </span>
        </div>
      )}
      {isGreen && (
        <div className={`alert success ${isClosing ? 'alert-closing' : ''}`}>
           <FaCheckCircle size={40}/>
          <p>
            {props.message}
          </p>
          <span className="alert-close" onClick={handleCloseAlert}>
            <FaTimes /> {/* Ícone de fechar */}
          </span>
        </div>
      )}
      {isYellow && (
        <div className={`alert tip ${isClosing ? 'alert-closing' : ''}`}>
          <FaExclamationCircle size={40} />
          <p>
            {props.message}
          </p>
          <span className="alert-close" onClick={handleCloseAlert}>
            <FaTimes /> {/* Ícone de fechar */}
          </span>
        </div>
      )}
    </div>
  );
}

export default Alert;