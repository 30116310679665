import "./PageCotacaoLotacao.css"
import BoxInputCotacao from '../../../Components/BoxInputCotacao/BoxInputCotacao'
import TitlePage from '../../../Components/TitlePage/TitlePage';
import { useState } from 'react';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import Table from '../../../Components/Table/Table';
import { getFreightLotacao } from '../../../Service/TransportadoraService';
import Base64Image from "../../../Components/Base64Image/Base64Image";

import { useEffect } from "react";
import { getUser } from "../../../Service/LoginService";
import { FaRegClock } from 'react-icons/fa'



function PageCotacaoLotacao() {


  const [arrayLotacao, setArrayLotacao] = useState([]);
  const fetchListCotacao = []
  const [loadingCotacao, setLoadingCotacao] = useState(0);

  const arrayVeiculos = [
    "Carreta",
    "Carreta LS",
    "Trucado",
    "Van",
    "Moto",
    "Picape",
    "Baú",
    "Cegonha",
    "Gaiola",
    "Baú frigorífico",
    "Baú refrigerado",
    "Caçamba",
    "Graneleiro",
  ];

  const arrayMateriais = [
    "Aço",
    "Aluminio",
    "Madeira",
    "Veiculo",
    "Tecido",
    "Animal vivo",
    "Animal abatido",
    "Joia",
    "Móveis",
    "Eletrodomésticos",
    "órgãos humanos"
  ];



  function calcularTempoRestante(dataHora) {
    const agora = Math.floor(Date.now() / 1000);

    const segundosRestantes = dataHora - agora;

    if (segundosRestantes <= 0) {
      return 'Esgotado';
    }

    const horas = Math.floor(segundosRestantes / 3600);
    const minutos = Math.floor((segundosRestantes % 3600) / 60);
    const segundos = Math.floor(segundosRestantes % 60);

    if (horas >= 99) {
      return `+ 99 horas `;
    }
    if (horas >= 1) {
      return `${horas < 10 ? '0' + horas : horas}:${minutos < 10 ? '0' + minutos : minutos}:${segundos < 10 ? '0' + segundos : segundos}`;
    } else {
      return `${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
    }
  }


  var arrayLotacaoTemp = []

  useEffect(() => {
    fetchDataAndSetState();
  }, []);

  async function fetchDataAndSetState() {
    setLoadingCotacao(1);
    const getListLotacao = await getFreightLotacao();
    console.log("GetListLotacao -> ", getListLotacao);

    const uniqueArrayLotacaoTemp = getListLotacao.reduce((uniqueArray, item) => {
      const objectLotacao = {
        nome: item.nome,
        imagePerfil: item.imagePerfil,
        telefoneTransportadora: item.telefoneTransportadora,
        cidadeDestino: item.cidadeDestino,
        cidadeOrigem: item.cidadeOrigem,
        materiais: item.materiais,
        veiculos: item.veiculos,
        valor: item.valor,
        horarioEnvio: item.horarioEnvio,
        observacao: item.observacao
      };

      // Verifica se o objeto já existe no array
      const exists = uniqueArray.some((existingItem) => {
        return (
          existingItem.nome === objectLotacao.nome &&
          existingItem.imagePerfil === objectLotacao.imagePerfil &&
          existingItem.cidadeDestino === objectLotacao.cidadeDestino &&
          existingItem.cidadeOrigem === objectLotacao.cidadeOrigem &&
          existingItem.materiais === objectLotacao.materiais &&
          existingItem.veiculos === objectLotacao.veiculos &&
          existingItem.valor === objectLotacao.valor &&
          existingItem.horarioEnvio === objectLotacao.horarioEnvio &&
          existingItem.observacao === objectLotacao.observacao
        );
      });

      // Se o objeto não existe, adiciona ao array
      if (!exists) {
        uniqueArray.push(objectLotacao);
      }

      return uniqueArray;
    }, []);

    console.log("Array lotacao -> ", uniqueArrayLotacaoTemp);
    // Ordena o array com base no menor valor retornado por calcularTempoRestante
    uniqueArrayLotacaoTemp.sort((a, b) => {
      const tempoA = calcularTempoRestante(a.horarioEnvio);
      const tempoB = calcularTempoRestante(b.horarioEnvio);

      console.log("tempo A -> ", tempoA, "Tempo B -> ", tempoB)


      if (tempoA === 'Esgotado' && tempoB === 'Esgotado') return 0;
      if (tempoA === 'Esgotado') return 1;
      if (tempoB === 'Esgotado') return -1;

      var [minutosA, segundosA] = tempoA.split(':');
      var [minutosB, segundosB] = tempoB.split(':');
      var [horasA, horasB] = [0, 0]


      if (tempoA.includes("hora")) {
        console.log("entrou no do hora")
        horasA = tempoA.split(' hora')[0];
        minutosA = 0;
        segundosA = 0;
      }

      if (tempoA[0] == "+") {
        console.log("entrou no do 99")
        horasA = 99;
        minutosA = 0;
        segundosA = 0;
      }

      if (tempoB.search(" hora")) {
        horasB = tempoB.split(' hora')[0];
        minutosB = 0;
        segundosB = 0;
      }

      if (tempoB[0] == "+") {
        horasB = 99;
        minutosB = 0;
        segundosB = 0;
      }

      console.log("horasA -> ", horasA, "horasB -> ", horasB)
      // Converte para minutos para facilitar a comparação
      const segundosTotalA = parseInt(horasA) * 3600 + parseInt(minutosA) * 60 + parseInt(segundosA);
      const segundosTotalB = parseInt(horasB) * 3600 + parseInt(minutosB) * 60 + parseInt(segundosB);
      console.log("segundosTotalA -> ", segundosTotalA, "segundosTotalB -> ", segundosTotalB)
      return segundosTotalA - segundosTotalB;
    });
    setLoadingCotacao(0);
    setArrayLotacao(uniqueArrayLotacaoTemp);
  }






  useEffect(() => {
    const intervalId = setInterval(() => {
      // Atualiza o tempo restante para cada item em arrayLotacao
      setArrayLotacao(prevArray => {
        return prevArray.map(item => ({
          ...item,
          tempoRestante: calcularTempoRestante(item.horarioEnvio)
        }));
      });
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);


  const handleClickMessage = function(numeroTelefone, cidadeOrigem, cidadeDestino) {
    if(numeroTelefone == undefined){
      alert("O usuário não possui um número de telefone cadastrado")
      return 0
    }
    console.log("clicou e o numero de telefone é: ", numeroTelefone)
    var telefoneFormatado = "55" + numeroTelefone.replace("(", "").replace(")", "").replace("-", "")
    var texto = "Sou cliente da logiflow, e gostaria de conversar com você sobre o frete lotação com origem em " + cidadeOrigem + " e com destino em " + cidadeDestino  
    const mensagem = encodeURIComponent(texto)
    window.open('https://wa.me//'+telefoneFormatado+'?text='+mensagem, '_blank').focus();

  }




  return (
    <div className="PageTesteCotacao">

      <TitlePage
        title="Veja os fretes de lotação disponiveis"
        subTitle="Frete lotação é quando o transportador já especifica a rota do frete">
      </TitlePage>

      {loadingCotacao === 1 ? (
        <p><Oval stroke="#9E3FFD" /></p>
      ) : (
        <div style={{ marginTop: '10vh' }}>
          <div style={{ display: 'flex', flexDirection: 'col', gap: "5vw 5vw", width: '100%', flexWrap: 'wrap', justifyContent: "center" }}>



            <div style={{ width: `100%` }}>
              {
                arrayLotacao.map(itemLotacao =>
                  
                  <div className='divResultLotacao' onClick={() => handleClickMessage(itemLotacao.telefoneTransportadora, itemLotacao.cidadeOrigem, itemLotacao.cidadeDestino)}>
                    <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                      <div className="divInfoUser">
                        <Base64Image base64Data={itemLotacao.imagePerfil} width="70" height="70" borderRadius="5px"></Base64Image>
                        <p style={{ margin: `0px`, fontSize: `10px` }}>{itemLotacao.nome}</p>
                      </div>

                      <div className="divCidadesLotacao">
                        <p style={{ margin: `0` }}>{itemLotacao.cidadeOrigem}</p>
                        <i className="arrowDown"></i>
                        <p style={{ margin: `0` }}>{itemLotacao.cidadeDestino}</p>
                      </div>

                      <div className="divTagsLotacao">
                        <p className="titleDiv">Materiais permitidos</p>
                        {
                          itemLotacao.materiais.map(material => (
                            <div className="itemTagLotacao">
                              <p>{arrayMateriais[material]}</p>
                            </div>
                          ))
                        }
                        <p className="titleDiv" style={{ marginTop: "20px" }}>Veiculos disponiveis</p>
                        {
                          itemLotacao.veiculos.map(material => (
                            <div className="itemTagLotacao">
                              <p>{arrayVeiculos[material]}</p>
                            </div>
                          ))
                        }
                      </div>

                      <div className="divObservacao">
                        <p className="titleDiv">Observacão</p>
                        <h6>{itemLotacao.observacao}</h6>
                      </div>

                      <div style={{display: 'flex', flexDirection: 'column', alignContent: 'center', justifyContent: 'center', height: "100%", alignSelf: 'center'}}>
                        <p className="titleDiv">Tempo para saída do frete</p>
                        <div className="divHoraRestante">
                  
                          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <FaRegClock />
                          </div>
                          <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                        
                            {calcularTempoRestante(itemLotacao.horarioEnvio)}
                          </div>

                        </div>
                      </div>
                      

                      <div className="divPreco">
                        <h4>{(itemLotacao.valor == 0 || itemLotacao.valor == null)? "Negociável" : ("R$ " + itemLotacao.valor.toFixed(2))}</h4>
                      </div>
                    </div>

                    <div style={{ lineHeight: '0', margin: '0', fontSize: '10px'}}>
                      <p>clique e converse com a transportadora</p>
                    </div>

                  </div>
                )
              }
            </div>

          </div>
        </div>
      )}


    </div>
  );
}

export default PageCotacaoLotacao;
