import './PagePerfil.css';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import Input from '../../../Components/Input/Input';
import { useState, useEffect, useContext } from 'react';
import { LoginContext } from '../../../Context/LoginContext';
import ImageUploader from '../../../Components/UploadImage/UploadImage';
import InputMask from 'react-input-mask';
import PageUploadTransportadora from '../UploadTransportadora/PageUploadTransportadora';
import Button from '../../../Components/Button/Button';
import { loginCheck, uploadUser } from '../../../Service/LoginService';
import Oval from 'react-loading-icons/dist/esm/components/oval';

function PagePerfil() {

  const [requestPerfil, setRequestPerfil] = useState(
    JSON.parse(localStorage.dataUser)
  )

  const [isLoading, setIsLoading] = useState(0)
  const [compressedData, setCompressedData] = useState(null);
  const [arrayPosicaoItensSelecionados, setArrayPosicaoItensSelecionados] = useState(JSON.parse(localStorage.dataUser).arrayTags ? JSON.parse(localStorage.dataUser).arrayTags : []);
  const { dataUserContext, setDataUserContext } = useContext(LoginContext);
  const arraySelecao = ["Moda e Vestuário", "Joias e Acessórios", "Livros e Entretenimento", "Automotivo", "Alimentos e Bebidas", "Casa e Decoração", "Saúde e Beleza", "etrônicos e Tecnologia", "Bebês e Crianças", "Brinquedos e Jogos", "Viagens e Turismo", "Artes e Artesanato", "Produtos Ecológicos e Sustentáveis", "Produtos para Animais de Estimação"]


  const handleUploadImage = (data) => {
    // This function will receive the compressed data from the ImageUploader component
    setCompressedData(data);
    setRequestPerfil({
      ...requestPerfil,
      imagePerfil: data,
    });
    console.log("datacompress -> ", data)
  };

  // Função para lidar com o clique em uma tag
  const handleTagClick = (index) => {
    // Verifica se o índice da tag já está no array de itens selecionados
    if (arrayPosicaoItensSelecionados.includes(index)) {
      // Se estiver, remove o índice do array
      const updatedArray = arrayPosicaoItensSelecionados.filter((item) => item !== index);
      setArrayPosicaoItensSelecionados(updatedArray);
    } else {
      // Se não estiver, adiciona o índice ao array
      setArrayPosicaoItensSelecionados([...arrayPosicaoItensSelecionados, index]);
    }
    console.log("array de pos -> ", arrayPosicaoItensSelecionados)
  };

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    console.log(event.target)
    setRequestPerfil({
      ...requestPerfil,
      [name]: value,
    });


  };

  function handleClick() {


    setIsLoading(1)

    // Crie uma cópia profunda do requestPerfil
    const updatedRequestPerfil = JSON.parse(JSON.stringify(requestPerfil));

    // Atualize o arrayTags na cópia profunda
    updatedRequestPerfil.arrayTags = arrayPosicaoItensSelecionados;

    setRequestPerfil(updatedRequestPerfil);

    console.log("tentando fazer o upload com -> ", JSON.stringify(requestPerfil))
    console.log("array de pos dentro do handle click -> ", JSON.stringify(updatedRequestPerfil))
    uploadUser(updatedRequestPerfil).then(resp => {
      setIsLoading(0)
      console.log("upload foi sucesso os dados do request ->", JSON.stringify(requestPerfil))
      localStorage.setItem('dataUser', JSON.stringify(updatedRequestPerfil));
      setDataUserContext(updatedRequestPerfil)
      setRequestPerfil(updatedRequestPerfil)
    })

  }

  return (
    <div className="PagePerfil">
      <TitlePage
        title="Atualizar perfil"
        subTitle="Atualize seus dados de cadastro">
      </TitlePage>

      {isLoading ? (
        <div style={{ marginTop: "3vh" }}>
          <Oval stroke="#9E3FFD" />
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: "row", justifyContent: 'left', alignItems: 'center', gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>
          <div className='whitecard'>
            <div className='profileForms'>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '20px 20px' }}>
                <ImageUploader onUpload={handleUploadImage} base64Image={JSON.parse(localStorage.dataUser).imagePerfil} />
                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px 5%' }}>
                  <Input backgroundColor="rgb(230, 230, 230)" width='40%' cursor="not-allowed" content="Email" value={JSON.parse(localStorage.dataUser).email}></Input>

                  <Input content="Nome" width="40%" name="nome" paddingRight="1vh" onChange={handleInputChange} value={requestPerfil.nome}></Input>

                  <Input content="E-mail sac" width="25%" name="emailSac" paddingRight="1vh" onChange={handleInputChange} value={requestPerfil.emailSac}></Input>

                  <Input content="E-mail financeiro" width="25%" name="emailFinanceiro" paddingRight="1vh" onChange={handleInputChange} value={requestPerfil.emailFinanceiro}></Input>

                  <Input content="E-mail comercial" width="25%" name="emailComercial" paddingRight="1vh" onChange={handleInputChange} value={requestPerfil.emailComercial}></Input>

                  <InputMask mask="99.999.999/9999-99" maskChar="" value={requestPerfil.cnpj} onChange={handleInputChange}>
                    {(inputProps) => <Input content="CNPJ" width='25%' name="cnpj" {...inputProps} />}
                  </InputMask>

                  <InputMask mask="(99)99999-9999" maskChar="" value={requestPerfil.telefone} onChange={handleInputChange}>
                    {(inputProps) => <Input content="Telefone" width='25%' name="telefone" {...inputProps} />}
                  </InputMask>


                  <div className="divTags">
                    {arraySelecao.map((tag, index) => (
                      <div
                        className={arrayPosicaoItensSelecionados.includes(index) ? "itemTagActive" : "itemTag"}
                        key={index}
                        onClick={() => handleTagClick(index)}>
                        <p>{tag}</p>
                      </div>
                    ))}
                  </div>


                </div>
              </div>
            </div>
            <div style={{ marginTop: "50px", display: 'flex', justifyContent: 'end' }}>
              <Button title="atualizar dados" width="300px" onClick={handleClick}></Button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default PagePerfil;
