import './BoxSuporte.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Input from '../Input/Input'
import Button from '../Button/Button'

function BoxInputSuporte() {
    return (
        <div className="BoxInputSuporte">
            <Container>
                <Row style={{ textAlign: 'left' }}>
                    <a className='titleBox'>Informações do pacote</a>
                </Row>
                <Row style={{ textAlign: 'left' }}>
                    <a className='subTitleBox'>Insira as informações da remessa para verificar seu Suporte</a>
                </Row>
            </Container>
            <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 2vw'}}>
                <Input content="Nome" width="40vh"></Input>
                <Input content="Motivo de Suporte" width="40vh"></Input>
            </div>

            <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 2vw' , width: "100%"}}>
                <Input content="Mensagem" width="100vh" height="25vh" />
            </div>
            <div style={{ marginTop: "4vh", display: 'flex', justifyContent: 'end' }}>
                <Button title="Realizar cotação!" width="50%" height="5vh"></Button>
            </div>
        </div>
    );
}

export default BoxInputSuporte;
