import './App.css';
import PageCotacao from './pages/SistemaCliente/Cotacao/PageCotacao';
import ClienteRoutes from './Routes/ClienteRoutes';
import TransportadoraRoutes from './Routes/TransportadoraRoutes';
import React, { useEffect, useContext, useState } from 'react';
import MenuLateral from './Components/MenuLateral/MenuLateral';
import TabelaCliente from './Components/TabelaCliente/TabelaCliente';
import { BrowserRouter } from 'react-router-dom';
import { LoginContext } from './Context/LoginContext'; 

import LoginRoutes from './Routes/LoginRoutes'
import MenuMobile from './Components/MenuMobile/MenuMobile';
import PageRastreio from './pages/SistemaCliente/Rastreio/PageRastreio';
import AdmRoutes from './Routes/AdmRoutes';

function App() {
  const { dataUserContext, setDataUserContext } = useContext(LoginContext);
  const [loginCheck, setLoginCheck] = useState(0);
  const [showMenu, setShowMenu] = useState(window.innerWidth > 1200);

  useEffect(() => {
    // verifica se já existe um user em cache
    if (localStorage?.dataUser) {
      const userCache = JSON.parse(localStorage.dataUser);
      console.log("userCache no app", userCache.tipoUser)
      setLoginCheck(userCache.tipoUser ? userCache.tipoUser : userCache.tipouser);
    }

    // verifica se houve alguma alteração na página de login
    if (dataUserContext?.tipoUser) {
      setLoginCheck(dataUserContext.tipoUser);
    }

    // adiciona um listener para verificar a largura da tela quando ela é redimensionada
    const handleResize = () => {
      console.log("tamanho ->", window.innerHeight)
      setShowMenu(window.innerWidth > 1200);
    };

    window.addEventListener('resize', handleResize);

    // remove o listener quando o componente é desmontado
    return () => window.removeEventListener('resize', handleResize);
  }, [dataUserContext]);

  if (loginCheck === 0) {
    return (
      <div className='App'>
        <BrowserRouter>
          <LoginRoutes></LoginRoutes>
        </BrowserRouter>
      </div>
    );
  }

  if (loginCheck === 2) {
    return (
      <div className='App'>
        <BrowserRouter>
          {showMenu && <MenuLateral></MenuLateral>}
          {!showMenu &&<MenuMobile></MenuMobile>}
          <div style={{ marginLeft: showMenu? '15vw' : '3vw', marginTop: showMenu? '1vh' : '35px' , padding: '2vw' }}>
            <ClienteRoutes></ClienteRoutes>
          </div>
        </BrowserRouter>
      </div>
    );
  }

  if (loginCheck === 3) {
    return (
      <div className='App'>
        <BrowserRouter>
          {showMenu && <MenuLateral></MenuLateral>}
          {!showMenu &&<MenuMobile></MenuMobile>}
          <div style={{ marginLeft: showMenu? '15vw' : '3vw', marginTop: showMenu? '1vh' : '100px' , padding: '2vw' }}>
            <AdmRoutes></AdmRoutes>
          </div>
        </BrowserRouter>
      </div>
    );
  }

  if (loginCheck === 1) {
    return (
      <div className='App'>
        <BrowserRouter>
          {showMenu && <MenuLateral></MenuLateral>}
          {!showMenu &&<MenuMobile></MenuMobile>}
          <div style={{ marginLeft: showMenu? '15vw' : '3vw', marginTop: showMenu? '1vh' : '100px' , padding: '2vw' }}>
            <TransportadoraRoutes></TransportadoraRoutes>
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
