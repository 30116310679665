import React from 'react';
import styled, { css } from 'styled-components';
import { Responsividade } from '../../util/responsividade';

const DivInput = styled.div`
  width: ${(props) => props.width};


  ${Responsividade.lessThan('md')`
    width:  95%;
 `};


`;

const TitleInput = styled.p`
    display: flex;
    align-self: flex-start;
    color: grey;
    margin: 3px;
    font-size: 0.8rem;
`;

const InsertInput = styled.input`
    height: 35px;
    border-radius: 10px;
    border: 1px solid grey;
    transition: 1s;
    padding-left: 0.5rem;
    display: flex;
    align-self: flex-start;

   
    &:focus {
    outline: none;
    border-color: #9E3FFD; /* Apply your desired styles when the input is in focus */
    box-shadow: 0 0 5px black; /* Apply a box shadow, for example */
    transition: 1s;
    }
`;



export default function Input(props){
  return (
    <DivInput width={props.width} isMediaQueryActive={props.isMediaQueryActive}>
      <TitleInput>{props.content}</TitleInput>
      <InsertInput
        style={{ width: "100%", marginLeft: props.marginLeft, backgroundColor: props.backgroundColor, paddingRight: props.paddingRight, fontSize: props.fontSize, cursor: props.cursor }}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        type={props.type}
        placeholder={props.placeHolder}
      />
    </DivInput>
  );
};

