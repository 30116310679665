// BoxInputRecuperarSenha.js
import './BoxInputRecuperarSenha.css';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { useState, useContext } from 'react';
import { LoginContext } from '../../Context/LoginContext';
import { Oval } from 'react-loading-icons';
import Alert from '../Alert/Alert';
import {generateTokenForget, checkTokenForget, changePassWord} from '../../Service/LoginService'
import { useNavigate } from "react-router-dom";


function BoxInputRecuperarSenha() {
  const [usuario, setUsuario] = useState({
    email: '',
    senha: '',
    token: ''
  });

  const [isLoading, setIsLoading] = useState(false);
  const [respReq, setResReq] = useState("");
  const { dataUser, setDataUser } = useContext(LoginContext);
  const [returnMessage, setReturnMessage] = useState(false)
  const [returnMessageToken, setReturnMessageToken] = useState(false)
  const { dataUserContext, setDataUserContext } = useContext(LoginContext)
  const [messageToken, setMessageToken] = useState("")
  const [statusToken, setStatusToken] = useState(0)

  const [showEmailInput, setShowEmailInput] = useState(1);
  const [showTokenInput, setShowTokenInput] = useState(0);
  const [showPassWordInput, setShowPassWordInput] = useState(0);

  const navigate = useNavigate()

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUsuario({
      ...usuario,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true)
    let resp = await generateTokenForget(usuario.email)

    setIsLoading(false)
    setShowEmailInput(0)
    setShowTokenInput(1)
    setReturnMessage(true)
  };

  const handleSubmitToken = async () => {
    setIsLoading(true)
    let resp = await checkTokenForget(usuario.email, parseInt(usuario.token))
    
    setIsLoading(false)
    setReturnMessage(false)
   

    if(resp.erro) {
      setMessageToken(resp.erro)
      setStatusToken(0)
      return
    }

    setMessageToken("token autenticado com sucesso")
    setStatusToken(1)
    setShowTokenInput(0)
    setShowPassWordInput(1)
    
  };

  const handleSubmitChangePassWord = async () => {
    setIsLoading(true)
    setMessageToken(false)
    let resp = await changePassWord(usuario.email, usuario.senha, usuario.token)
    setIsLoading(false)

    if(resp.erro){
      console.log("houve erro -> ", resp)
      setMessageToken(resp.erro)
      setStatusToken(0)
      return 
    }
    
    alert("sua senha foi alterada com sucesso!")
    
    localStorage.setItem('dataUser', JSON.stringify(resp));
    setDataUserContext(resp)
    navigate("/home")

  };

  return (
    <div className="divCadastro">
      {returnMessage && (
        <Alert width="500px" color="green" message={"token enviado para o email: " + usuario.email} />
      )}

    {messageToken && (
        <Alert width="500px" color={statusToken == 0 ? "red" : "green"} message={messageToken} />
      )}
      <div className='BoxInputRecuperarSenha'>

        {/* Div do email */}
        {showEmailInput? (
          <>
            {isLoading ? (
              <div style={{ marginTop: "4vh" }} >
                <Oval stroke="#9E3FFD" />
              </div>
            ) : (
              <div style={{ opacity: showEmailInput, scale: showEmailInput, transition: '1s' }}>
                <div className="Titles" style={{ marginTop: "2vh", display: 'flex', alignItems: 'start', flexDirection: 'column', color: '#9E3FFD' }}>
                  <h1 className='titleBox'>Recuperar senha</h1>
                  <p className='subTitleBox'>Enviaremos um token de autênticação para seu email</p>
                </div>

                <div style={{ marginTop: "2vh", display: 'flex', flexDirection: "row", gap: "1vw 1vh", width: '100%', flexWrap: 'wrap' }}>
                  <Input content="Email cadastrado" width="99%" name="email" value={usuario.email} onChange={handleInputChange} />
                </div>


                <div style={{ marginTop: "4vh", display: 'flex' }}>
                  <Button title="Enviar token" width="100%" height="6vh" onClick={handleSubmit} />
                </div>
              </div>
            )}
          </>
        ) : (<></>)}


        {/* Div do token */}
        {showTokenInput? (
          <>
            {isLoading ? (
              <div style={{ marginTop: "4vh" }}>
                <Oval stroke="#9E3FFD" />
              </div>
            ) : (
              <div style={{ opacity: showTokenInput, scale: showTokenInput, transition: '1s' }}>
                <div className="Titles" style={{ marginTop: "2vh", display: 'flex', alignItems: 'start', flexDirection: 'column', color: '#9E3FFD' }}>
                  <h1 className='titleBox'>Informe o token</h1>
                  <p className='subTitleBox'>Enviamos um token para o email {usuario.email}, esse token expira em 5 minutos</p>
                </div>

                <div style={{ marginTop: "2vh", display: 'flex', flexDirection: "row", gap: "1vw 1vh", width: '100%', flexWrap: 'wrap' }}>
                  <Input content="Token" width="100px" name="token" value={usuario.token} onChange={handleInputChange} />
                </div>


                <div style={{ marginTop: "4vh", display: 'flex' }}>
                  <Button title="validar token" width="100%" height="6vh" onClick={handleSubmitToken} />
                </div>
              </div>
            )}
          </>
        ) : (<></>)}

        {/* Div do password */}
        {showPassWordInput? (
          <>
            {isLoading ? (
              <div style={{ marginTop: "4vh" }}>
                <Oval stroke="#9E3FFD" />
              </div>
            ) : (
              <div>
                <div className="Titles" style={{ marginTop: "2vh", display: 'flex', alignItems: 'start', flexDirection: 'column', color: '#9E3FFD' }}>
                  <h1 className='titleBox'>Troque a senha</h1>
                  <p className='subTitleBox'>Escolha sua nova senha</p>
                </div>

                <div style={{ marginTop: "2vh", display: 'flex', flexDirection: "row", gap: "1vw 1vh", width: '100%', flexWrap: 'wrap' }}>
                  <Input type="password" content="Nova senha" width="98%" name="senha" value={usuario.senha} onChange={handleInputChange} />
                </div>


                <div style={{ marginTop: "4vh", display: 'flex' }}>
                  <Button title="Trocar senha" width="100%" height="6vh" onClick={handleSubmitChangePassWord} />
                </div>
              </div>
            )}
          </>
        ) : (<></>)}

      </div>

    </div >
  );
}

export default BoxInputRecuperarSenha;
