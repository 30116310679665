import { TransparentCard } from '../../../Components/TransparentCard';
import { FaMoneyBill, FaTags, FaSearch, FaTruck,  FaRegNewspaper } from 'react-icons/fa';
import {MdSupportAgent} from 'react-icons/md';
import './Home.css';
import { ServiceCard } from '../../../Components/ServiceCard';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import Table from '../../../Components/Table/Table';


function Home() {

 

 return (
    <div className="Home">

        

        <TitlePage 
        title="Descubra nossos serviços especiais"
        subTitle="A Logflow conta com uma equipe altamente especializada em entregas. Veja como podemos ajudar você."
        ></TitlePage>

      <div style={{display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap'}}>

        
      <ServiceCard Icon={<FaTruck size={50} color="#9E3FFD" />} title={"Cotações de frete"}
          redirect="/cotacao/distribuicao"
          text={"Utilize nosso hub de transportadoras parceiras para obter cotações de frete que melhor atendam às suas demandas."}
          customButtonText="Fazer Cotação" />
      
        <ServiceCard Icon={<MdSupportAgent size={50} color="#9E3FFD" />} title={"Envio de analistas para a transportadora"} 
          text={"Um dos nossos analistas é enviado pessoalmente à transportadora, permitindo um contato direto e personalizado para discutir necessidades, resolver problemas e conhecer mais a fundo os serviços da transportadora."} 
          customButtonText="Conversar com um analista" 
          />
        

        <ServiceCard Icon={<FaRegNewspaper size={50} color="#9E3FFD" />} title={"Consultoria logística"}
          text={"Contrate nosso renomado time, com anos de experiência em transporte e logística, para a criação de estratégias e soluções personalizadas que otimizem sua cadeia de suprimentos."}
          customButtonText="Solicitar Consultoria" />

        <ServiceCard Icon={<FaTruck size={50} color="#9E3FFD" />} title={"Contratar transportadora"}
         redirect="/transportadoras"
          text={"A Logflow possui um extenso hub de transportadoras parceiras. Encontre a transportadora ideal para sua demanda, com o suporte e garantia de qualidade da Logflow."}
          customButtonText="Ver lista de transportadoras" />

        <ServiceCard Icon={<FaSearch size={50} color="#9E3FFD" />} title={"Auditoria de frete"}
          text={"Nossos analistas são especializados em auditar fretes, fornecendo uma análise detalhada dos custos, identificação de possíveis erros ou inconsistências na fatura, reduzindo as despesas relacionadas ao transporte."}
          customButtonText="Solicitar Auditoria" />

      </div>

    </div>
  );
}

export default Home;
