import { uploadUser } from '../../Service/LoginService';
import React, { useState, useRef } from 'react';
import Button from '../Button/Button';
import './UploadImage.css';
import Base64Image from '../Base64Image/Base64Image';

const ImageUploader = ({ onUpload, base64Image }) => {
  const [fileData, setFileData] = useState('');
  const fileInputRef = useRef(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileData(reader.result);
        handleUpload(reader.result)
      };
      
    }
    
  };

  const previewFile = (file) => {
    
  };

  const handleUpload = (fileData) => {
    // Verifica se a imagem foi selecionada antes de fazer o upload
    if (1) {

      console.log("chamou a função para gerar o base64")
      // Create a new Image object to load the base64 data
      const img = new Image();
      img.src = fileData;
  
      img.onload = () => {
        const MAX_SIZE = 100 * 1024; // 100KB in bytes
  
        // Create a canvas element
        const canvas = document.createElement("canvas");
  
        let width = img.width;
        let height = img.height;
  
        // Calculate new dimensions if needed to fit within 100KB
        if (fileData.length > MAX_SIZE) {
          const aspectRatio = width / height;
          width = Math.sqrt(MAX_SIZE * aspectRatio);
          height = width / aspectRatio;
        }
  
        // Set the canvas dimensions
        canvas.width = width;
        canvas.height = height;
  
        // Draw the image onto the canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
  
        // Convert the canvas content back to base64 with reduced quality
        const compressedData = canvas.toDataURL("image/jpeg", 0.7); // Adjust the quality as needed (0.7 is just an example)
    
        console.log("Enviando o base64 -> ", compressedData);

        if (compressedData) {
          // Pass the compressedData to the parent component through the onUpload prop
          onUpload(compressedData);
          
        }
      };
    } else {
      console.log("Selecione uma imagem antes de fazer o upload.");
    }
  };

  const handleClickMyDiv = () => {
    // Acionar o clique do input do tipo arquivo ao clicar na div "myDiv"
    fileInputRef.current.click();
  };

  return (
    <div className='uploadd'>
      <div className='myDiv'  onChange={handleUpload} onClick={handleClickMyDiv}>
        

        {fileData ? (
          <img
            src={fileData}
            alt="Preview"
            style={{ width: '150px', height: '150px', borderRadius: '2vh' }}
           
          />
        ) : (
          <>
           <div style={{cursor: "pointer", color:"#9E3FFD"}}>
           <Base64Image base64Data={base64Image} width="140" height="140" borderRadius="2vh"></Base64Image>
          </div>
         
          </>
         
          
        )}
         <p>Clique na imagem e altere a foto de perfil</p>
      </div>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileInputChange}
      />

      
      

      {/* <button className= "Uploadbutton" onClick={handleUpload} disabled={!fileData} >
        Upload
      </button> */}
    </div>
  );
};

export default ImageUploader;
