import './PageCotacaoFracionada.css';
import BoxInputCotacao from '../../../Components/BoxInputCotacao/BoxInputCotacao'
import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxResultCotacao from '../../../Components/BoxResultCotacao/BoxResultCotacao';
import logoTransportadora from "../../../images/image5.png"
import { useState } from 'react';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import Table from '../../../Components/Table/Table';

function PageCotacaoFracionada() {

  const [resultCotacao, setResultCotacao] = useState([]);
  const [requestCotacao, setRequestCotacao] = useState([]);
  const [loadingCotacao, setLoadingCotacao] = useState(0);
  const [dadosCotacao, setDadosCotacao] = useState([]);

  const handleRequestCotacao = (resp) => {
    console.log("fez a req!", resp)
    setLoadingCotacao(1);
    setRequestCotacao(resp);
    setDadosCotacao([])
  };

  const handleResultCotacao = (resp) => {
    setResultCotacao(resp);
    setLoadingCotacao(0);
    
    if (resp.length > 0) {
      const novoDadosCotacao = resp.map(cotacao => {
        //Se o peso da cubagem (vol * fator) for maior que o peso do pacote, considerar o peso da cubagem no valorKilo
        console.log('COTACAO ->', cotacao)
     
        if(cotacao.nomeTransportadora) {
          return {
            'Transportadora': cotacao.nomeTransportadora,
            'Prazo': cotacao.prazo + " dias",
            'Tipo de frete': cotacao.tipoFrete,
            'Preço': "R$" + cotacao.preco,
          };
        } else {
          return null; // Retorna nulo para os itens que não devem ser adicionados ao array
        }
      }).filter(item => item !== null); // Filtra os itens nulos (que não foram adicionados)

      setDadosCotacao(novoDadosCotacao);
    }
  };

  const colunasCotacao = ['Transportadora', 'Prazo', 'Tipo de frete','Preço'];

  return (
    <div className="PageTesteCotacao">

      <TitlePage
        title="Faça uma cotação do tipo fracionada"
        subTitle="Simule um frete baseado em sua tabela de preços">
      </TitlePage>

      <BoxInputCotacao onResultCotacao={handleResultCotacao} tipoInput={1} onRequestCotacao={handleRequestCotacao}></BoxInputCotacao>

      <div style={{marginTop: '10vh'}}>
        <TitlePage
          title="Confira o resultado da sua cotação"
          subTitle="Informe os dados da remessa para visualizar todas as transportadoras disponíveis para a entrega">
        </TitlePage>
        <div style={{display: 'flex', flexDirection: 'col', gap: "5vw 5vw", width: '100%', flexWrap: 'wrap', justifyContent: "center" }}>

          {dadosCotacao.length > 0 ? (
            <Table dados={dadosCotacao} colunas={colunasCotacao} botao={'Contratar'}></Table>
          ) : <p></p>}

          {loadingCotacao === 1 ? (
            <p><Oval stroke="#9E3FFD" /></p>
          ) : (<p></p>)}

        </div>
      </div>
    </div>
  );
}

export default PageCotacaoFracionada;
