import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react'; // Importe também o hook useEffect
import './PageConfirmarEmail.css';
import { confirmEmail } from '../../../Service/LoginService';

function PageConfirmarEmail() {
  const { emailUrl } = useParams();
  const [usuario, setUsuario] = useState(null); // Inicie o estado com null
  const [loading, setLoading] = useState(true); // Estado para representar o carregamento

  useEffect(() => {
    // Função assíncrona para fazer a confirmação do email
    async function confirmarEmail() {
      try {
        const resp = await confirmEmail(emailUrl);
        setUsuario(resp); // Defina o estado do usuário com a resposta do serviço
      } catch (error) {
        console.error('Erro ao confirmar email:', error);
      } finally {
        setLoading(false); // Indique que o carregamento está completo
      }
    }

    confirmarEmail();
  }, [emailUrl]); // Certifique-se de chamar novamente a função se o emailUrl mudar

  return (
    <div className='PageConfirmarEmail'>
      <div className='DivResulConfirmarEmail'>
        <p>Email <a style={{border: "2px solid blue", padding: '10px', borderRadius: '30px'}}>{emailUrl}</a> </p>
        <p>confirmado com sucesso</p>
      </div>
      {loading ? (
          <p>Carregando...</p>
        ) : (
          usuario && (
            <p>
              <p>Nome: {usuario.dadosuser.nome}</p>
              <p>Email: {usuario.email}</p>
              {/* Mostrar outros dados do usuário, se disponíveis */}
            </p>
          )
        )}
    </div>
  );
}

export default PageConfirmarEmail;