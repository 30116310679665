import './MenuLateral.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import { LoginContext } from '../../Context/LoginContext';
import Base64Image from '../Base64Image/Base64Image';
import { FaUserCircle, FaCircleHalfStroke } from 'react-icons/fa';
import { AiOutlineHome } from 'react-icons/ai';
import { BiUserCircle, BiSolidDownArrow, BiSolidUpArrow, BiSolidNetworkChart } from 'react-icons/bi';
import { BsTruck } from 'react-icons/bs';
import { PiPackage } from 'react-icons/pi';
import { LiaSearchDollarSolid } from 'react-icons/lia';
import { SlLogout } from 'react-icons/sl';
import { CgPathDivide } from 'react-icons/cg'
import { MdJoinInner, MdOutlineLocalPolice, MdCreateNewFolder, MdTrackChanges, MdOutlineJoinLeft, MdOutlineJoinFull } from 'react-icons/md';
import { IconContext } from "react-icons";

//Determina qual tipo de usuario está usando
const flagUser = 2;

function MenuLateral(props) {

  //Opcs que existem nos dois menus
  const [classHome, setClassHome] = useState("itemMenuDisable");
  const [classPerfil, setClassPerfil] = useState("itemMenuDisable");

  //Opcs do cliente
  const [classCotacao, setClassCotacao] = useState("itemMenuDisable");
  const [classTransportadoras, setClassTransportadoras] = useState("itemMenuDisable");
  const [classRastreio, setClassRastreio] = useState("itemMenuDisable");
  const [classEtiquetas, setClassEtiquetas] = useState("itemMenuDisable");
  const [classArmazenagem, setClassArmazenagem] = useState("itemMenuDisable");

  //Opcs da transportadora
  const [classIntegracao, setClassIntegracao] = useState("itemMenuDisable");
  const [classSeguro, setClassSeguro] = useState("itemMenuDisable");
  const [classTabelaPreco, setClassTabelaPreco] = useState("itemMenuDisable");
  const [classIntegracaoM, setClassIntegracaoM] = useState("itemMenuDisable");
  const [classIntegracaoE, setClassIntegracaoE] = useState("itemMenuDisable");
  const [classTestarCotacao, setClassTestarCotacao] = useState("itemMenuDisable");



  //Verifica no load em qual rota está para habilitar o nome do menu
  useEffect(() => {
    window.location.href.indexOf("home") > -1 ? setClassHome("itemMenuEnable") : setClassHome("itemMenuDisable")
    window.location.href.indexOf("cotacao") > -1 ? setClassCotacao("itemMenuEnable") : setClassCotacao("itemMenuDisable")
    window.location.href.indexOf("cotacao") > -1 ? setClassTestarCotacao("itemMenuEnable") : setClassTestarCotacao("itemMenuDisable")
    window.location.href.indexOf("transportadoras") > -1 ? setClassTransportadoras("itemMenuEnable") : setClassTransportadoras("itemMenuDisable")
    window.location.href.indexOf("rastreio") > -1 ? setClassRastreio("itemMenuEnable") : setClassRastreio("itemMenuDisable")
    window.location.href.indexOf("etiquetas") > -1 ? setClassEtiquetas("itemMenuEnable") : setClassEtiquetas("itemMenuDisable")
    window.location.href.indexOf("armazenagem") > -1 ? setClassArmazenagem("itemMenuEnable") : setClassArmazenagem("itemMenuDisable")
    window.location.href.indexOf("perfil") > -1 ? setClassPerfil("itemMenuEnable") : setClassPerfil("itemMenuDisable")
    window.location.href.indexOf("integracao") > -1 ? setClassIntegracao("itemMenuEnable") : setClassIntegracao("itemMenuDisable")
    window.location.href.indexOf("integracaoERP") > -1 ? setClassIntegracaoE("itemMenuEnable") : setClassIntegracaoE("itemMenuDisable")
    window.location.href.indexOf("seguro") > -1 ? setClassSeguro("itemMenuEnable") : setClassSeguro("itemMenuDisable")
    window.location.href.indexOf("tabela-preco") > -1 ? setClassTabelaPreco("itemMenuEnable") : setClassTabelaPreco("itemMenuDisable")
  }, []);

  function desabilitarMenu() {
    setClassCotacao("itemMenuDisable")
    setClassHome("itemMenuDisable")
    setClassTransportadoras("itemMenuDisable")
    setClassRastreio("itemMenuDisable")
    setClassEtiquetas("itemMenuDisable")
    setClassArmazenagem("itemMenuDisable")
    setClassPerfil("itemMenuDisable")
    setClassIntegracao("itemMenuDisable")
    setClassSeguro("itemMenuDisable")
    setClassTestarCotacao("itemMenuDisable")
    setClassIntegracaoE("itemMenuDisable")
    setClassTabelaPreco("itemMenuDisable")
  }

  //Verificando se houver alteração de user

  const { dataUserContext, setDataUserContext } = useContext(LoginContext);
  const [userInfo, setUserInfo] = useState({});
  const [loginCheck, setLoginCheck] = useState(0)

  const logOut = () => {
    console.log('URL', window.location.href.split('/'))
    const urlRedirect = window.location.href.split('/')[0] + '//' + window.location.href.split('/')[2]
    localStorage.removeItem("dataUser")
    setLoginCheck(0)
    window.location.href = urlRedirect

  }

  useEffect(() => {
    //verifica se já existe um user em cache
    if (localStorage?.dataUser) {
      const userCache = JSON.parse(localStorage.dataUser)
      setUserInfo(userCache)
      setLoginCheck(userCache.tipoUser ? userCache.tipoUser : userCache.tipouser)
    }

  }, [dataUserContext]);



  //Função para expandir dropDown 
  const [itensVisiveis, setItensVisiveis] = useState(false); // Estado para controlar a visibilidade dos itens
  const [itensVisiveis1, setItensVisiveis1] = useState(false); // Estado para controlar a visibilidade dos itens

  const showItensDropDown = () => {
    setItensVisiveis(!itensVisiveis); // Alternar a visibilidade dos itens
  };

  const showItensDropDown1 = () => {
    setItensVisiveis1(!itensVisiveis1); // Alternar a visibilidade dos itens
  };

  //Caso seja um cliente
  if (loginCheck == 2) {

    return (
      <div className="MenuLateral" style={{ width: props.width, marginLeft: props.marginLeft }}>
        <div className='contentMenuLateral'>
          <Link to="/perfil" style={{ textDecoration: "none" }}>
            <div className='boxUser'>
              <div className='photoUser'>
                {JSON.parse(localStorage.dataUser).imagePerfil ? (
                  <Base64Image base64Data={JSON.parse(localStorage.dataUser).imagePerfil} width="35" height="35" borderRadius="5px"></Base64Image>
                ) : (
                  <IconContext.Provider value={{ color: "rgb(205, 205, 205)", size: "35px" }}>
                    <div>
                      <FaUserCircle />
                    </div>
                  </IconContext.Provider>
                )}


              </div>
              <Container>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a>{userInfo.nome}</a>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a className="infoUser">Configuração de conta</a>
                </Row>
              </Container>
            </div>
          </Link>
          
          <div style={{marginTop: '20px'}}></div>
          <Link to=""
            className={classHome}
            onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
            <IconContext.Provider value={{ color: classHome == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineHome />
              </div>
            </IconContext.Provider>
            Home
          </Link>

          <div className='dropDownCotacao'>

            <div className='divHeadDropDownCotacao' onClick={() => { showItensDropDown(); }}>
              <div className='boxDropDownCotacao'>
                <IconContext.Provider value={{ color: "#9E3FFD", size: "25px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LiaSearchDollarSolid />
                  </div>
                </IconContext.Provider>
              </div>
              <p className='textDropDownCotacao'>Contratações</p>

              <div style={{ marginLeft: '0px', width: '40%', display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
                {!itensVisiveis ? (

                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidDownArrow />
                    </div>
                  </IconContext.Provider>

                ) : (
                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidUpArrow />
                    </div>
                  </IconContext.Provider>
                )}
              </div>


            </div>



            <div className='divItensDropDownCotacao' style={{ display: itensVisiveis ? 'block' : 'none' }}>
              <Link to="/cotacao/distribuicao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BiSolidNetworkChart />
                  </div>
                </IconContext.Provider>
                Contratar Distribuição
              </Link>

              <Link to="/cotacao/lotacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinFull />
                  </div>
                </IconContext.Provider>
                Consultar lotação
              </Link>

              <Link to="/cotacao/subcontratacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinLeft />
                  </div>
                </IconContext.Provider>
                Contratar Subcontratação
              </Link>

              <Link to="/cotacao/fracionada"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CgPathDivide />
                  </div>
                </IconContext.Provider>
                Contratar Fracionada
              </Link>

            </div>

          </div>
          <Link to="/lotacao/criarLotacao" style={{ textDecoration: "none" }}>
            <div className='dropDownCotacao' style={{ flexDirection: 'row', display: 'flex' }}>
              <div className='boxDropDownCotacao'>
                <IconContext.Provider value={{ color: "#9E3FFD", size: "25px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCreateNewFolder />
                  </div>
                </IconContext.Provider>
              </div>

              <a className='textDropDownCotacao' style={{ textDecoration: "none" }}>Criar lotação</a>

              <div style={{ marginLeft: '0px', width: '40%', display: 'flex', justifyContent: 'end', paddingRight: '5px' }}></div>
            </div>
          </Link>
          <div className='itensMenu'>

            {/* <Link to="/cotacao" 
                    className={classCotacao}
                    onClick={() => {desabilitarMenu(); setClassCotacao("itemMenuEnable")}}>
                       <IconContext.Provider value={{ color: classCotacao == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <MdCreateNewFolder />
                        </div>
                      </IconContext.Provider>
                      Cotações</Link> */}





            <Link to="/transportadoras"
              className={classTransportadoras}
              onClick={() => { desabilitarMenu(); setClassTransportadoras("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classTransportadoras == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <BsTruck />
                </div>
              </IconContext.Provider>
              Transportadoras</Link>

            <Link to="/armazenagem"
              className={classArmazenagem}
              onClick={() => { desabilitarMenu(); setClassArmazenagem("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classArmazenagem == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PiPackage />
                </div>
              </IconContext.Provider>
              Armazenagem</Link>

            <Link to="/rastreio"
              className={classRastreio}
              onClick={() => { desabilitarMenu(); setClassRastreio("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classRastreio == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <MdTrackChanges />
                </div>
              </IconContext.Provider>
              Rastrear pedido</Link>

            <Link to="/integracao"
              className={classIntegracao}
              onClick={() => { desabilitarMenu(); setClassIntegracao("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classIntegracao == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdJoinInner />
                </div>
              </IconContext.Provider>
              Integração Market Place</Link>

            <Link to="/integracaoERP"
              className={classIntegracaoE}
              onClick={() => { desabilitarMenu(); setClassIntegracaoE("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classIntegracaoE == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdJoinInner />
                </div>
              </IconContext.Provider>
              Integração ERP</Link>

            <Link to="/seguro"
              className={classSeguro}
              onClick={() => { desabilitarMenu(); setClassSeguro("itemMenuEnable") }}
              style={{ textDecoration: 'none' }}>
              <IconContext.Provider value={{ color: classSeguro == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdOutlineLocalPolice />
                </div>
              </IconContext.Provider>
              Seguro de cargas</Link>

            <Link to="/perfil"
              className={classPerfil}
              onClick={() => { desabilitarMenu(); setClassPerfil("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classPerfil == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <BiUserCircle />
                </div>
              </IconContext.Provider>
              Perfil</Link>

            <Link className={classEtiquetas} to='/' onClick={logOut}>
              <IconContext.Provider value={{ color: classEtiquetas == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <SlLogout />
                </div>
              </IconContext.Provider>
              Logout</Link>
          </div>
        </div>
      </div>
    );
  }

  //Caso seja transportadora
  if (loginCheck == 1) {

    return (
      <div className="MenuLateral">
        <div className='contentMenuLateral'>
          <Link to="/perfil" style={{ textDecoration: "none" }}>
            <div className='boxUser'>
              <div className='photoUser'>
                {JSON.parse(localStorage.dataUser).imagePerfil ? (
                  <Base64Image base64Data={JSON.parse(localStorage.dataUser).imagePerfil} width="35" height="35" borderRadius="5px"></Base64Image>
                ) : (
                  <IconContext.Provider value={{ color: "rgb(205, 205, 205)", size: "35px" }}>
                    <div>
                      <FaUserCircle />
                    </div>
                  </IconContext.Provider>
                )}


              </div>
              <Container>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a>{userInfo.nome}</a>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a className="infoUser">Configuração de conta</a>
                </Row>
              </Container>
            </div>
          </Link>

          <div style={{marginTop: '20px'}}></div>
          <Link to=""
            className={classHome}
            onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
            <IconContext.Provider value={{ color: classHome == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <AiOutlineHome />
              </div>
            </IconContext.Provider>
            Home
          </Link>

          <div className='dropDownCotacao'>

            <div className='divHeadDropDownCotacao' onClick={() => { showItensDropDown(); }}>
              <div className='boxDropDownCotacao'>
                <IconContext.Provider value={{ color: "#9E3FFD", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCreateNewFolder />
                  </div>
                </IconContext.Provider>
              </div>
              <p className='textDropDownCotacao'>Configurações</p>

              <div style={{ marginLeft: '0px', width: '40%', display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
                {!itensVisiveis ? (

                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidDownArrow />
                    </div>
                  </IconContext.Provider>

                ) : (
                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidUpArrow />
                    </div>
                  </IconContext.Provider>
                )}
              </div>


            </div>


            <div className='divItensDropDownCotacao' style={{ display: itensVisiveis ? 'block' : 'none' }}>
              <Link to="/cotacao/distribuicao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BiSolidNetworkChart />
                  </div>
                </IconContext.Provider>
                Tabela Distribuição
              </Link>

              <Link to="/cotacao/lotacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinFull />
                  </div>
                </IconContext.Provider>
                Anunciar Lotação
              </Link>

              <Link to="/cotacao/subcontratacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinLeft />
                  </div>
                </IconContext.Provider>
                Tabela Subcontratação
              </Link>

              <Link to="/cotacao/fracionada"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CgPathDivide />
                  </div>
                </IconContext.Provider>
                Tabela Fracionada
              </Link>

            </div>

          </div>

          <div className='dropDownCotacao'>

            <div className='divHeadDropDownCotacao' onClick={() => { showItensDropDown1(); }}>
              <div className='boxDropDownCotacao'>
                <IconContext.Provider value={{ color: "#9E3FFD", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BsTruck />
                  </div>
                </IconContext.Provider>
              </div>
              <p className='textDropDownCotacao'>Contratações</p>

              <div style={{ marginLeft: '0px', width: '40%', display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
                {!itensVisiveis1 ? (

                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidDownArrow />
                    </div>
                  </IconContext.Provider>

                ) : (
                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidUpArrow />
                    </div>
                  </IconContext.Provider>
                )}
              </div>


            </div>


            <div className='divItensDropDownCotacao' style={{ display: itensVisiveis1 ? 'block' : 'none' }}>
              <Link to="/teste-cotacao/distribuicao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BiSolidNetworkChart />
                  </div>
                </IconContext.Provider>
                Cotação Distribuição
              </Link>

              <Link to="/teste-cotacao/lotacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinFull />
                  </div>
                </IconContext.Provider>
                Consultar lotação
              </Link>

              <Link to="/teste-cotacao/subcontratacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinLeft />
                  </div>
                </IconContext.Provider>
                Contratar Subcontratação
              </Link>

              <Link to="/teste-cotacao/fracionada"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CgPathDivide />
                  </div>
                </IconContext.Provider>
                Cotação de Fracionada
              </Link>

            </div>

          </div>

          <div className='itensMenu'>

            <Link to="/armazenagem"
              className={classArmazenagem}
              onClick={() => { desabilitarMenu(); setClassArmazenagem("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classArmazenagem == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <PiPackage />
                </div>
              </IconContext.Provider>
              Armazenagem</Link>

            <Link to="/integracao"
              className={classIntegracao}
              onClick={() => { desabilitarMenu(); setClassIntegracao("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classIntegracao == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdJoinInner />
                </div>
              </IconContext.Provider>
              Integração Market Place</Link>

            <Link to="/integracaoERP"
              className={classIntegracaoE}
              onClick={() => { desabilitarMenu(); setClassIntegracaoE("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classIntegracaoE == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdJoinInner />
                </div>
              </IconContext.Provider>
              Integração ERP</Link>

            <Link to="/seguro"
              className={classTransportadoras}
              onClick={() => { desabilitarMenu(); setClassTransportadoras("itemMenuEnable") }}
              style={{ textDecoration: 'none' }}>
              <IconContext.Provider value={{ color: classTransportadoras == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <MdOutlineLocalPolice />
                </div>
              </IconContext.Provider>
              Seguro de cargas</Link>

            <Link to="/perfil"
              className={classPerfil}
              onClick={() => { desabilitarMenu(); setClassPerfil("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classPerfil == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <BiUserCircle />
                </div>
              </IconContext.Provider>
              Perfil</Link>

            <Link className={classEtiquetas} to='/' onClick={logOut}>
              <IconContext.Provider value={{ color: classEtiquetas == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <SlLogout />
                </div>
              </IconContext.Provider>
              Logout</Link>
          </div>
        </div>
      </div>
    );
  }

  //Caso seja adm
  if (loginCheck == 3) {

    return (
      <div className="MenuLateral">
        <div className='contentMenuLateral'>
          <Link to="/perfil" style={{ textDecoration: "none" }}>
            <div className='boxUser'>
              <div className='photoUser'>
                {JSON.parse(localStorage.dataUser).imagePerfil ? (
                  <Base64Image base64Data={JSON.parse(localStorage.dataUser).imagePerfil} width="35" height="35" borderRadius="5px"></Base64Image>
                ) : (
                  <IconContext.Provider value={{ color: "rgb(205, 205, 205)", size: "35px" }}>
                    <div>
                      <FaUserCircle />
                    </div>
                  </IconContext.Provider>
                )}


              </div>
              <Container>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a>{userInfo.nome}</a>
                </Row>
                <Row style={{ display: 'flex', justifyContent: 'left', textAlign: 'left' }}>
                  <a className="infoUser">Configuração de conta</a>
                </Row>
              </Container>
            </div>
          </Link>


          <div className='dropDownCotacao'>
            <div className='divHeadDropDownCotacao' onClick={() => { showItensDropDown(); }}>
              <div className='boxDropDownCotacao'>
                <IconContext.Provider value={{ color: "#9E3FFD", size: "25px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdCreateNewFolder />
                  </div>
                </IconContext.Provider>
              </div>
              <p className='textDropDownCotacao'>Cotações</p>

              <div style={{ marginLeft: '0px', width: '40%', display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
                {!itensVisiveis ? (

                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidDownArrow />
                    </div>
                  </IconContext.Provider>

                ) : (
                  <IconContext.Provider value={{ color: "rgb(230, 230, 230)", size: "12px" }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <BiSolidUpArrow />
                    </div>
                  </IconContext.Provider>
                )}
              </div>


            </div>


            <div className='divItensDropDownCotacao' style={{ display: itensVisiveis ? 'block' : 'none' }}>
              <Link to="/cotacao/distribuicao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <BiSolidNetworkChart />
                  </div>
                </IconContext.Provider>
                Contratar Distribuição
              </Link>

              <Link to="/cotacao/lotacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinFull />
                  </div>
                </IconContext.Provider>
                Consultar lotação
              </Link>

              <Link to="/cotacao/subcontratacao"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <MdOutlineJoinLeft />
                  </div>
                </IconContext.Provider>
                Contratar Subcontratação
              </Link>

              <Link to="/cotacao/fracionada"
                className='itemDropDownCotacao'
                onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
                <IconContext.Provider value={{ color: "white", size: "20px" }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <CgPathDivide />
                  </div>
                </IconContext.Provider>
                Contratar Fracionada
              </Link>

            </div>

          </div>

          <div className='itensMenu'>

            <Link to="/home"
              className={classHome}
              onClick={() => { desabilitarMenu(); setClassHome("itemMenuEnable") }}>
              <IconContext.Provider value={{ color: classHome == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <AiOutlineHome />
                </div>
              </IconContext.Provider>
              Home
            </Link>

            <Link className={classEtiquetas} to='/' onClick={logOut}>
              <IconContext.Provider value={{ color: classEtiquetas == 'itemMenuEnable' ? "#9E3FFD" : "rgb(173, 173, 173)", size: "25px" }}>
                <div>
                  <SlLogout />
                </div>
              </IconContext.Provider>
              Logout</Link>
          </div>
        </div>
      </div>
    );
  }

}


function checkCurrentPageCliente() {

}

export default MenuLateral;
