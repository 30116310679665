import './BoxInputRastreio.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Input from '../Input/Input'
import Button from '../Button/Button'

function BoxInputRastreio() {
  return (
    <div className="BoxInputRastreio">
      <Container>
        <Row style={{textAlign: 'left'}}>
          <a className='titleBox'>Informações do pacote</a>
        </Row>
        <Row style={{textAlign: 'left'}}>
          <a className='subTitleBox'>Insira as informações da remessa para verificar seu rastreio</a>
        </Row>
      </Container>
      <div style={{marginTop: "2vh", display: 'flex', gap: '1vh 2vw'}}>
        <Input content="Numero de rastreio"></Input>
      </div>
      <div style={{marginTop: "3vh", display: 'flex'}}>
        <Button title="Realizar cotação!" width="100%" height="4vh"></Button>
      </div>
    </div>
  );
}

export default BoxInputRastreio;
