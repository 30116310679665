import React, { useState, useEffect } from 'react';
import './MenuMobile.css';
import { HiMenu } from 'react-icons/hi';
import { MdClose } from 'react-icons/md'
import { FaUser, FaUserCircle } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom'; 
import Base64Image from '../Base64Image/Base64Image';
import { IconContext } from 'react-icons';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import MenuLateral from './../MenuLateral/MenuLateral';

function MenuMobile() {
  const [menuActive, setMenuActive] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [loginCheck, setLoginCheck] = useState(0)
  const location = useLocation();
  const handleClickMenu = () => {
    setMenuActive((prevState) => !prevState);
  };

  const logOut = () => {
    setLoginCheck(0)
  }

  useEffect(() => {
    // Verifica se já existe um user em cache
    if (localStorage?.dataUser) {
      const userCache = JSON.parse(localStorage.dataUser)
      setUserInfo(userCache)
      console.log("userInfo -> ", userInfo)
      setLoginCheck(userCache.tipoUser ? userCache.tipoUser : userCache.tipouser)
    }

  // Desativa o menu sempre que a rota mudar
  setMenuActive(false);
  }, [loginCheck, location]);

  return (
    <div style={{zIndex: '4', position: 'fixed'}}>
      <div className='divColapseMenu'>
        <div style={{width: '100%'}} onClick={handleClickMenu}>
          <HiMenu style={{ transition: '1s', opacity: menuActive ? '0' : '1', width: '100%', marginBottom: '-20px'}} size={30} color='white' />
          <MdClose style={{ transition: '1s', opacity: menuActive ? '1' : '0', width: '100%', marginTop: '-30px' }} size={30} color='white' /> 
        </div>
      </div>
      <div style={{backgroundColor: 'rgba(19, 19, 19)', width: "100%", height: "100%", position: "fixed", top: "0", transition: '1s', opacity: menuActive ? "0.37" : "0"}}></div>
      <MenuLateral width="300px" marginLeft={menuActive ? "0px" : "-300px"}></MenuLateral>
    </div>




  );
}

export default MenuMobile;
