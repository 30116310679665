import './PageLogin.css';
import BoxInputLogin from '../../../Components/BoxInputLogin/BoxInputLogin';
import { useEffect, useRef, useState } from 'react';
import NET from 'vanta/dist/vanta.net.min';
import BoxInputCadastro from '../../../Components/BoxInputCadastro/BoxInputCadastro';
import logoLogFlow from '../../../images/LogotipoCortado.png'

function PageLogin() {
  const vantaRef = useRef(null);
  const [showCadastro, setShowCadastro] = useState(false);

  useEffect(() => {
    let vantaEffect;

    const initializeVanta = () => {
      vantaEffect = NET({
        el: vantaRef.current,
        mouseControls: true,
        touchControls: true,
        gyroControls: false,
        minHeight: 300.00,
        minWidth: 300.00,
        scale: 1.00,
        scaleMobile: 1.00,
        color: 0x9E3FFD,
        backgroundColor: 0x360666
      });
    };

    const destroyVanta = () => {
      if (vantaEffect) {
        vantaEffect.destroy();
      }
    };

    initializeVanta();

    return () => {
      destroyVanta();
    };
  }, [showCadastro]); // Adicionando showCadastro como dependência do useEffect

  const handleShowCadastro = () => {
    setShowCadastro(true); // Set the flag to true to show the registration component
    console.log(showCadastro);
  };

  return (
    <div className='PageLogin' style={{border: '1px solid green'}} ref={vantaRef}>
      <img src={logoLogFlow} className='LogoFlow'></img>
      {showCadastro && <BoxInputCadastro />}
      {!showCadastro && <BoxInputLogin handleShowCadastro={handleShowCadastro} />}
    </div>
  );
}

export default PageLogin;
