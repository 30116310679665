import { ServiceCard } from '../../../Components/ServiceCard';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import { CardTransportadora } from '../../../Components/CardTransportadora/CardTransportadora';

import './pageIntegracao.css';
import Table from '../../../Components/Table/Table';


function Integracao() {
    const dadosMarketPlace = [{
        "Nome do market place": "Mercado Livre",
      },
      {
        "Nome do market place": "Americanas",
      },
      {
        "Nome do market place": "Submarino",
      },
      {
        "Nome do market place": "Shoptime",
      },
      {
        "Nome do market place": "Magazine Luiza",
      },
      {
        "Nome do market place": "Amazon",
      },
      {
        "Nome do market place": "Casas Bahia",
      },
      {
        "Nome do market place": "Ponto Frio",
      },
      {
        "Nome do market place": "Carrefour",
      },
      {
        "Nome do market place": "Netshoes",
      },
      {
        "Nome do market place": "Dafiti",
      },
      {
        "Nome do market place": "Extra",
      },
      {
        "Nome do market place": "Elo7",
      },
      {
        "Nome do market place": "MadeiraMadeira",
      },
      {
        "Nome do market place": "Kabum",
      },
      {
        "Nome do market place": "Zattini",
      },
      {
        "Nome do market place": "Farfetch",
      },
      {
        "Nome do market place": "C&A",
      },
      {
        "Nome do market place": "Leroy Merlin",
      },
      {
        "Nome do market place": "Tricae",
      },
      {
        "Nome do market place": "Zara",
      },
      {
        "Nome do market place": "Kanui",
      },
      {
        "Nome do market place": "Marisa",
      },
      {
        "Nome do market place": "Renner",
      },
      {
        "Nome do market place": "Centauro",
      },
      {
        "Nome do market place": "Pernambucanas",
      },
      {
        "Nome do market place": "Riachuelo",
      },
      {
        "Nome do market place": "Sestini",
      },
      {
        "Nome do market place": "Nike",
      },
      {
        "Nome do market place": "PontoFrio.com",
      },
      {
        "Nome do market place": "Ricardo Eletro",
      },
      {
        "Nome do market place": "Kalunga",
      },
      {
        "Nome do market place": "Insinuante",
      },
      {
        "Nome do market place": "Havan",
      },
      {
        "Nome do market place": "Camicado",
      },
      {
        "Nome do market place": "Ri Happy",
      },
      {
        "Nome do market place": "Casas Jardim",
      },
      {
        "Nome do market place": "Cia. dos Livros",
      },
      {
        "Nome do market place": "Hering",
      },
      {
        "Nome do market place": "Shafa",
      },
      {
        "Nome do market place": "Posthaus",
      },
      {
        "Nome do market place": "SouQ",
      },
      {
        "Nome do market place": "Sou Barato",
      },
      {
        "Nome do market place": "Zoom",
      },
      {
        "Nome do market place": "Eletrum",
      },
      {
        "Nome do market place": "Dufry Shop",
      },
      {
        "Nome do market place": "Shop2gether",
      },
      {
        "Nome do market place": "Bombril",
      },
      {
        "Nome do market place": "Lojas Lebes",
      },
      {
        "Nome do market place": "Esposende",
      },
      {
        "Nome do market place": "HP Store",
      },
      {
        "Nome do market place": "Estrela10",
      },
      {
        "Nome do market place": "Saraiva",
      },
      {
        "Nome do market place": "Sony Store",
      },
      {
        "Nome do market place": "L'Occitane",
      },
      {
        "Nome do market place": "Super Muffato",
      },
      {
        "Nome do market place": "Riocard Mais",
      },
      {
        "Nome do market place": "Outlet da Informática",
      },
      {
        "Nome do market place": "Cissa Magazine",
      },
      {
        "Nome do market place": "Timex",
      },
      {
        "Nome do market place": "Animale",
      },
      {
        "Nome do market place": "TNG",
      },
      {
        "Nome do market place": "Colombo",
      },
      {
        "Nome do market place": "Le Postiche",
      },
      {
        "Nome do market place": "Magalu Marketplace",
      },
      {
        "Nome do market place": "Leroy Merlin Pro",
      },
      {
        "Nome do market place": "Compra Certa",
      },
      {
        "Nome do market place": "Farmácia Pague Menos",
      },
      {
        "Nome do market place": "Claro Store",
      },
      {
        "Nome do market place": "iPlace",
      },
      {
        "Nome do market place": "Onofre Agora",
      },
      {
        "Nome do market place": "Adcos",
      },
      {
        "Nome do market place": "Netfarma",
      },
      {
        "Nome do market place": "Casa das Cuecas",
      },
      {
        "Nome do market place": "Souple Lingerie",
      },
      {
        "Nome do market place": "Granado",
      },
      {
        "Nome do market place": "Comix",
      },
      {
        "Nome do market place": "Brastemp",
      },
      {
        "Nome do market place": "Consul",
      },
      {
        "Nome do market place": "Carros na Web",
      },
      {
        "Nome do market place": "Kipling",
      },
    ]
    const colubnasMarketPlace = ['Nome do market place'];
    
    const dadosERP = [
        {
            "Nome do ERP": "Bling",
          },
          {
            "Nome do ERP": "Tiny ERP",
          },
          {
            "Nome do ERP": "Tray",
          },
          {
            "Nome do ERP": "Nuvemshop",
          },
          {
            "Nome do ERP": "VTEX",
          },
          {
            "Nome do ERP": "Linx",
          },
          {
            "Nome do ERP": "SkyHub",
          },
          {
            "Nome do ERP": "Becommerce",
          },
          {
            "Nome do ERP": "Octadesk",
          },
          {
            "Nome do ERP": "Omie",
          },
          {
            "Nome do ERP": "Alterdata",
          },
          {
            "Nome do ERP": "Sankhya",
          },
          {
            "Nome do ERP": "ContaAzul",
          },
          {
            "Nome do ERP": "TOTVS",
          },
          {
            "Nome do ERP": "Mastermaq",
          },
          {
            "Nome do ERP": "Consistent",
          },
          {
            "Nome do ERP": "B2W",
          },
          {
            "Nome do ERP": "Magalu Marketplace",
          },
          {
            "Nome do ERP": "Vnda",
          },
          {
            "Nome do ERP": "BizCommerce",
          },
          {
            "Nome do ERP": "Olist",
          },
          {
            "Nome do ERP": "IntegraCommerce",
          },
          {
            "Nome do ERP": "WebMais",
          },
          {
            "Nome do ERP": "B2B Stack",
          },
          {
            "Nome do ERP": "Loga",
          },
          {
            "Nome do ERP": "InfoVarejo",
          },
          {
            "Nome do ERP": "StormCommerce",
          },
          {
            "Nome do ERP": "Commerce Plus",
          },
          {
            "Nome do ERP": "PowerHub",
          },
          {
            "Nome do ERP": "Mega Sistemas",
          },
          {
            "Nome do ERP": "UOL DIVEO",
          },
          {
            "Nome do ERP": "SellerCenter",
          },
          {
            "Nome do ERP": "Omnize",
          },
          {
            "Nome do ERP": "Tstore",
          },
          {
            "Nome do ERP": "Exact Sales",
          },
          {
            "Nome do ERP": "Eclético",
          },
          {
            "Nome do ERP": "Compose",
          },
          {
            "Nome do ERP": "SellerX",
          },
          {
            "Nome do ERP": "KPL",
          },
          {
            "Nome do ERP": "StarSoft",
          },
          {
            "Nome do ERP": "ShopDelivery",
          },
          {
            "Nome do ERP": "Arquivei",
          },
          {
            "Nome do ERP": "MarketUP",
          },
          {
            "Nome do ERP": "eNotas",
          },
          {
            "Nome do ERP": "NearPOS",
          },
          {
            "Nome do ERP": "Netsuite",
          },
          {
            "Nome do ERP": "JetNeo",
          },
          {
            "Nome do ERP": "ShopW",
          },
          {
            "Nome do ERP": "Gencommerce",
          },
          {
            "Nome do ERP": "Seller4All",
          },
          {
            "Nome do ERP": "PlataformaFacil",
          },
          {
            "Nome do ERP": "Ekos ERP",
          },
          {
            "Nome do ERP": "Oxy ERP",
          },
          {
            "Nome do ERP": "Fatto",
          },
          {
            "Nome do ERP": "Shopfy",
          },
          {
            "Nome do ERP": "Brudam",
          },
          {
            "Nome do ERP": "Moip",
          },
          {
            "Nome do ERP": "PayPal",
          },
          {
            "Nome do ERP": "SuperSoft",
          },
          {
            "Nome do ERP": "Sharemart",
          },
          {
            "Nome do ERP": "SQL Commerce",
          },
          {
            "Nome do ERP": "EcomTrello",
          },
          {
            "Nome do ERP": "Codigus",
          },
          {
            "Nome do ERP": "SigEra",
          },
          {
            "Nome do ERP": "X-Commerce",
          },
          {
            "Nome do ERP": "BlingPOS",
          },
          {
            "Nome do ERP": "Atak",
          },
          {
            "Nome do ERP": "Alog",
          },
          {
            "Nome do ERP": "ErpFox",
          },
          {
            "Nome do ERP": "N2PAG",
          },
          {
            "Nome do ERP": "Softvar",
          },
          {
            "Nome do ERP": "Web Escada",
          },
          {
            "Nome do ERP": "Fact",
          },
          {
            "Nome do ERP": "ClickSoft",
          },
          {
            "Nome do ERP": "Autocom",
          },
          {
            "Nome do ERP": "Reginsys",
          },
          {
            "Nome do ERP": "NewCon",
          },
          {
            "Nome do ERP": "Microsiga",
          },
          {
            "Nome do ERP": "Genesys",
          },
          {
            "Nome do ERP": "Myrp",
          },
    ]
    const colubnasERP = ['Nome do ERP'];

    const handleLinhaClicada = (dados) => {
      const mensagem = encodeURIComponent('Aqui é o usuário ' + JSON.parse(localStorage.dataUser).nome + ', e gostaria de integrar nossa loja com o ' + dados["Nome do market place"])
      window.open('https://wa.me//5511945122317?text='+mensagem, '_blank').focus();
      console.log('dados da linha -> ', dados);
    };


    return (
        <div className="Integracao">

            <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                    <TitlePage title="Integração com marketplaces" subTitle="Marketplace são sites de compra e venda de produtos, como um shopping center virtual" />
                    <Table dados={dadosMarketPlace} colunas={colubnasMarketPlace} botao={'Integrar minha loja'}  onLinhaClicada={handleLinhaClicada}></Table>
            </div>
        </div>
    );
}

export default Integracao;
