// const apiUrl = 'http://localhost:3000/';
const apiUrl = 'https://us-central1-logiflow-backend.cloudfunctions.net/api/';

async function getTransportadoras(params){
    console.log('tenadno o upload com: ', params)
    try {
      const response = await fetch(apiUrl + "transportadoras/getTransportadoras", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function getFreightLotacao(){

    try {
      const response = await fetch(apiUrl + "transportadoras/freight/getFreightLotacao", {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function editFreightLotacao(body){

    try {
      const response = await fetch(apiUrl + "transportadoras/freight/editFreightLotacao", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          '_id': body._id,
          'idTransportadora': body.idTransportadora,
          "nome": body.nome,
          "imagePerfil": body.imagePerfil,
          "telefoneTransportadora": body.telefoneTransportadora,
          "cidadeOrigem": body.cidadeOrigem,
          "cidadeDestino": body.cidadeDestino,
          "pesoPacote": body.pesoPacote,
          "valor": body.valor,
          "horarioEnvio": body.horarioEnvio,
          "materiais": body.materiais,
          "veiculos": body.veiculos,
          "observacao": body.observacao,
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function deleteFreightLotacao(body){

    try {
      const response = await fetch(apiUrl + "transportadoras/freight/deleteFreightLotacao", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body:JSON.stringify({
          '_id': body._id
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function insertFreightLotacao(idTransportadora, nome, imagePerfil, telefoneTransportadora, cidadeOrigem, cidadeDestino, pesoPacote, valor, materiais, veiculos, horarioEnvio, observacao){

    try {
      const response = await fetch(apiUrl + "transportadoras/freight/insertFreightLotacao", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'idTransportadora': idTransportadora,
          "nome": nome,
          "imagePerfil": imagePerfil,
          "telefoneTransportadora": telefoneTransportadora,
          "cidadeOrigem": cidadeOrigem,
          "cidadeDestino": cidadeDestino,
          "pesoPacote": pesoPacote,
          "valor": valor,
          "horarioEnvio": horarioEnvio,
          "materiais": materiais,
          "veiculos": veiculos,
          "observacao": observacao,
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function getTabelaFrete(idTransportadora, tipoFrete){
    try {
      const response = await fetch(apiUrl + "transportadoras/freight/getList", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'idTransportadora': idTransportadora,
          "tipoFrete": tipoFrete
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function deleteTabelaFrete(idTransportadora, ufOrigem, ufDestino, tipoFrete){
    try {
      const response = await fetch(apiUrl + "transportadoras/freight/deleteFreightListOgDes", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'idTransportadora': idTransportadora,
          'ufOrigem': ufOrigem,
          "ufDestino": ufDestino,
          "tipoFrete": tipoFrete
        })
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }

  async function getTabelaFreteOgDes(idTransportadora, ufOrigem, ufDestino, tipoFrete){

    try {
      // const response = await fetch(apiUrl + "cep/freight/getFreightListOgDes", {
      const response = await fetch(apiUrl + "transportadoras/freight/getFreightListOgDes", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'idTransportadora': idTransportadora,
          'ufOrigem': ufOrigem,
          "ufDestino": ufDestino,
          'tipoFrete': tipoFrete
        })
      });
  
      const data = await response.json();
      console.log('Resposta do service -> ' + JSON.stringify(data));
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  }


  async function uploadPrecoTransportadoras(params, tipoFrete){
    try {
      // const response = await fetch(apiUrl + "cep/freight/list", {
      const response = await fetch(apiUrl + "transportadoras/freight/insertFreightListOgDes", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          'tipoFrete': tipoFrete,
          'data': params})
      });
      const data = await response.json();
      console.log('chamou transportadorass -> ' + JSON.stringify(data));
      return data;
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
      // Aqui você pode realizar outros tratamentos de erro, se necessário
      throw error;
    }
  
  
  }

  
export { getTransportadoras, uploadPrecoTransportadoras, getTabelaFrete, getTabelaFreteOgDes, deleteTabelaFrete, deleteFreightLotacao, getFreightLotacao, insertFreightLotacao, editFreightLotacao };
