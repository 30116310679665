import { getUser } from "./LoginService";

const apiUrl = 'https://us-central1-logiflow-backend.cloudfunctions.net/api/';
// const apiUrl = 'http://localhost:3000/';


async function calculaFrete(cepDestino, cepOrigem, pesoFinal) {
  console.log("ta fazendo o check")
  try {
    const response = await fetch(apiUrl + "cep/freight/calculate", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cepOrigem: cepOrigem,
        cepDestino: cepDestino,
        pesoFinal: pesoFinal
      }),
    });

    if (!response.ok) {
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    // Faz o parse do JSON e obtém os dados da resposta
    const data = await response.json();
    console.log('Calculo frete -> ', data)

    // Retorna os dados caso precise usá-los em outro lugar
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}


async function calculaFrete2(cepDestino, cepOrigem, pesoFinal, valorNotaFiscal, tipoFrete, volCubico) {
  try {
    const response = await fetch(apiUrl + "quotation/getPrice", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cepOrigem: cepOrigem,
        cepDestino: cepDestino,
        pesoFinal: pesoFinal,
        valorNotaFiscal: valorNotaFiscal, 
        tipoFrete: tipoFrete,
        volCubico: volCubico
      }),
    });

    if (!response.ok) {
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    // Faz o parse do JSON e obtém os dados da resposta
    const data = await response.json();
    console.log('Calculo frete -> ', data)

    async function updateData(data) {
      for (const element of data) {
        const respGetUser = await getUser(element.idTransportadora);
        element.nome = respGetUser.nome;
        element.imageTransportadora = respGetUser.imagePerfil;
        element.telefone = respGetUser.telefone;
      }
    
      console.log("SERVICE DATA -> ", data);
      console.log("SERVICE STRING -> ", JSON.stringify(data));
    }
    
    await updateData(data);

    // Retorna os dados caso precise usá-los em outro lugar
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

export { calculaFrete, calculaFrete2 }
