import './PagePerfil.css';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import Input from '../../../Components/Input/Input';
import { useState, useContext } from 'react';
import ImageUploader from '../../../Components/UploadImage/UploadImage';
import InputMask from 'react-input-mask';
import Button from '../../../Components/Button/Button';
import { loginCheck, uploadUser } from '../../../Service/LoginService';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { LoginContext } from '../../../Context/LoginContext';

function PagePerfil() {

  const [requestPerfil, setRequestPerfil] = useState(
    JSON.parse(localStorage.dataUser)
  )

  const [isLoading, setIsLoading] = useState(0)
  const { dataUserContext, setDataUserContext } = useContext(LoginContext);

  const [compressedData, setCompressedData] = useState(null);

  const handleUploadImage = (data) => {
    // This function will receive the compressed data from the ImageUploader component
    setCompressedData(data);
    setRequestPerfil({
      ...requestPerfil,
      imagePerfil: data,
    });
    console.log("datacompress -> ", data)
  };

  const handleInputChange = (event) => {

    const { name, value } = event.target;
    console.log(event.target)
    setRequestPerfil({
      ...requestPerfil,
      [name]: value,
    });


  };

  function handleClick() {


    setIsLoading(1)
    console.log("clicou no botao  ----> ", requestPerfil)


    uploadUser(requestPerfil).then(resp => {
      setIsLoading(0)
      console.log("upload foi sucesso os dados do request ->", JSON.stringify(requestPerfil))
      localStorage.setItem('dataUser', JSON.stringify(requestPerfil));
      setDataUserContext(requestPerfil)
      setRequestPerfil(requestPerfil)
    })

  }

  return (
    <div className="PagePerfil">
      <TitlePage
        title="Atualizar perfil"
        subTitle="Atualize seus dados de cadastro">
      </TitlePage>

      <div style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>
        {isLoading ? (
          <div style={{ marginTop: "3vh" }}>
            <Oval stroke="#9E3FFD" />
          </div>
        ) : (
          <div className='whitecard'>
            <div className='profileForms'>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '20px 20px'}}>
                <ImageUploader onUpload={handleUploadImage} base64Image={JSON.parse(localStorage.dataUser).imagePerfil} />
                <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '15px 5%'}}>
                  <Input backgroundColor="rgb(230, 230, 230)" width='40%' cursor="not-allowed" content="Email" value={JSON.parse(localStorage.dataUser).email}></Input>

                  <Input content="Nome" width="40%" name="nome" paddingRight="1vh" onChange={handleInputChange} value={requestPerfil.nome}></Input>

                  <InputMask mask="99.999.999/9999-99" maskChar="" value={requestPerfil.cnpj} onChange={handleInputChange}>
                    {(inputProps) => <Input content="CNPJ" width='25%' name="cnpj" {...inputProps} />}
                  </InputMask>

                  <InputMask mask="(99)99999-9999" maskChar="" value={requestPerfil.telefone} onChange={handleInputChange}>
                    {(inputProps) => <Input content="Telefone" width='25%' name="telefone" {...inputProps} />}
                  </InputMask>

                </div>
              </div>
            </div>
            <div style={{ marginTop: "50px", display: 'flex', justifyContent: 'end' }}>
              <Button title="atualizar dados" width="300px" onClick={handleClick}></Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PagePerfil;
