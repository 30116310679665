import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import { Responsividade } from '../../util/responsividade';

const DivInput = styled.div`
  width: ${(props) => props.width};
  position: relative;

  ${Responsividade.lessThan('md')`
    width: 95%;
  `};
`;

const TitleInput = styled.p`
  display: flex;
  align-self: flex-start;
  color: grey;
  margin: 3px;
  font-size: 0.8rem;
`;

const ListCidades = styled.ul`
  border: 1px solid grey;
  border-radius: 10px;
  margin-top: -20px;
  text-align: left;
  padding-right: 15px;
  padding-left: 15px;
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
  z-index: 2;
`;

const ItemCidade = styled.li`
  cursor: pointer;
  list-style-type: none;
`;

const InsertInput = styled.input`
  height: 35px;
  border-radius: 10px;
  border: 1px solid grey;
  transition: 1s;
  padding-left: 0.5rem;
  display: flex;
  align-self: flex-start;
  width: 100%;

  &:focus {
    outline: none;
    border-color: #9e3ffd;
    box-shadow: 0 0 5px black;
    transition: 1s;
  }
`;

const removeAccents = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
};

const InputCity = (props) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [cities, setCities] = useState([]);
  const [arrayCities, setArrayCities] = useState([]);
  const [isInputFocused, setInputFocused] = useState(false);
  const [showItemList, setShowItemList] = useState(false);

  let debouncedFetchCities = debounce(fetchCities, 300);

  const inputCityRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (inputCityRef.current && !inputCityRef.current.contains(event.target)) {
        setShowItemList(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  async function fetchCities(term) {
    try {
      const normalizedTerm = removeAccents(term);

      if (arrayCities.length === 0) {
        const response = await axios.get(
          `https://servicodados.ibge.gov.br/api/v1/localidades/municipios`
        );

        setArrayCities(response.data);
      }

      const filteredCities = arrayCities.filter((city) =>
        removeAccents(city.nome.toLowerCase()).includes(normalizedTerm)
      );

      setCities(filteredCities);
    } catch (error) {
      console.error('Erro ao buscar cidades:', error);
    }
  }

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedFetchCities(term);
    props.onSearchTermChange(term);
  };

  const handleInputFocus = () => {
    setInputFocused(true);
  };

  const handleItemClick = (city) => {
    setSearchTerm(`${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`);
    props.onSearchTermChange(`${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`);
    setShowItemList(false);
  };

  return (
    <DivInput width={props.width} ref={inputCityRef}>
      <TitleInput>{props.content}</TitleInput>
      <InsertInput
        type="text"
        value={searchTerm}
        onChange={handleInputChange}
        list="city-suggestions"
        onFocus={() => setShowItemList(true)}
      />
      {showItemList && (
        <ListCidades>
          {cities.map((city) => (
            <div key={city.id}>
              <ItemCidade onClick={() => handleItemClick(city)}>
                {`${city.nome} - ${city.microrregiao.mesorregiao.UF.sigla}`}
              </ItemCidade>
            </div>
          ))}
        </ListCidades>
      )}
    </DivInput>
  );
};

export default InputCity;
