import './BoxInputArmazenagem.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useState, useEffect } from 'react';
import { calculaFrete, calculaFrete2 } from '../../Service/CotacaoService'
  import InputMask from 'react-input-mask'; 
import { uploadArmazenagem } from '../../Service/LoginService';
import Oval from 'react-loading-icons/dist/esm/components/oval';

function BoxInputArmazenagem() {
  
  const [precoMetros2, setPrecoMetros2] = useState("");
  const [isLoading, setIsLoading] = useState(0);
  const [maskPrecoMetros2, setMaskPrecoMetros2] = useState((JSON.parse(localStorage.dataUser).precoArmazenagem*100).toString());
  
  useEffect(() => {
    if(JSON.parse(localStorage.dataUser).precoArmazenagem == undefined){
      setMaskPrecoMetros2("0")
    }
  }, []);

  console.log('Preco -> ', JSON.parse(localStorage.dataUser).precoArmazenagem)
 

  function maskM(string){
    
    if(string == ""){
      string = " m"
    }

    var onlyNumber

    //Caso esteja apagando
    if(string.slice(-1) == " ")
    {
       onlyNumber = string.substring(0, string.length -2)
    }
    
    //Caso esteja escrevendo
    if(string.slice(-1) != " ")
    {
      onlyNumber = string.replace(" m", "")
    }

    onlyNumber = onlyNumber.replace(".", "")
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)

    if (intNumber == ""){
      return "0." + decimalNumber + " m"
    }

    if (intNumber[0] == 0 && intNumber[1] == 0 & intNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }

    if (intNumber[0] == 0 && intNumber.length>1){
      intNumber = intNumber.substring(1, intNumber.length)
    }

    
    return intNumber + "." + decimalNumber + " m"
  }

  function maskRs(string){
    
    if(string == ""){
      string = "R$ 00.00"
    }

    var onlyNumber = string.replace('R$ ', '')
    onlyNumber = onlyNumber.replace('.', '')
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)

    if (intNumber[0] == 0 && intNumber[1] == 0 & onlyNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }
    return "R$ " + intNumber + "." + decimalNumber
  }

  const handleInputMaskChange = (event) => {
    const { name, value } = event.target;
    setMaskPrecoMetros2(value);
    var valorNum = parseFloat(maskRs(value).replace("R$ ", ""))
    setPrecoMetros2(valorNum)
  }

  const handleClick = (event) => {
    setIsLoading(1);
    uploadArmazenagem(JSON.parse(localStorage.dataUser).email, precoMetros2).then(resp => {
      console.log("resp do upload -> ", resp);
      setIsLoading(0);
    })
  }




  return (
    <div className="BoxInputArmazenagem">

          
          <>
           <Container>
            <Row style={{ textAlign: 'left' }}>
              <a className='titleBox'>Informe o preço para armazenar o metro quadrado</a>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <a className='subTitleBox'>Informe o valor para a armazenagem, dessa forma calcularemos proporcionalmente a armazenagem do cliente</a>
            </Row>
          </Container>

          <h1>{precoMetros2}</h1>

            <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 5%', flexWrap: "wrap" }}>
              {isLoading ? (
                  <div style={{ marginTop: "3vh" }}>
                    <Oval stroke="#9E3FFD" />
                  </div>
                ) : (
                  <Input content="Valor do M²" width="25%" value={maskRs(maskPrecoMetros2)} onChange={handleInputMaskChange}></Input>
                )} 
            </div>
          </>
        
     
      

   
      
      <div style={{ marginTop: "10vh", display: 'flex', justifyContent: 'end' }}>
        <Button title="Atualizar valor!" width="100%" onClick={handleClick} height="6vh"></Button>
      </div>
    </div>
  );
}

export default BoxInputArmazenagem;
