import { createContext, useState } from 'react';

const LoginContext = createContext();

const LoginProvider = ({ children }) => {
  const [dataUserContext, setDataUserContext] = useState(null);

  return (
    <LoginContext.Provider value={{ dataUserContext, setDataUserContext }}>
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContext, LoginProvider };