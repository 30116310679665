import './PageUploadTransportadora.css';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import {uploadPrecoTransportadoras, getTabelaFreteOgDes, deleteTabelaFrete} from '../../../Service/TransportadoraService'
import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx'
import Button from '../../../Components/Button/Button';
import tabelaRef from './tabelaRef.xlsx'
import Oval from 'react-loading-icons/dist/esm/components/oval';
import { ImWarning } from 'react-icons/im';
import { AiFillLike } from 'react-icons/ai';
import Alert from '../../../Components/Alert/Alert';

function PageUploadTransportadora({ onUploadCompleted }) {
  
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [statusResponse, setstatusResponse] = useState(null);
  const [mesageResponse, setMesageResponse] = useState("");
  const [tipoFrete, setTipoFrete] = useState(1);

  const handleDownloadXlsx = async () => {
    try {
      const response = await fetch(tabelaRef);
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.download = 'tabelaReferenciaPreco.xlsx';
      link.click();

      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Erro ao fazer o download:', error);
    }
  };

const handleClick = () => {
  const arrayPrecos = []
  setIsLoading(1)
  
  document.body.scrollTop = document.documentElement.scrollTop = 0;

  //Criando a tabela de preços
  for(var i = 1; i<data.length; i++){
    if(data[i].length > 3)
    {
      arrayPrecos.push(      
        {
        "idTransportadora": JSON.parse(localStorage.dataUser)._id,
        "nomeTransportadora": JSON.parse(localStorage.dataUser).nome,
        "idDestino": data[i][2],
        "pesoFinal": data[i][6],
        "pesoInicial": data[i][5],
        "ufDestino": data[i][1],
        "ufOrigem": data[i][0],
        "prazo": data[i][4],
        'tipoFrete': data[i][3],
        "valorFrete": data[i][7],
        "valorFreteKgAdicional": data[i][8],
        "adValorem": data[i][9],
        "gris": data[i][10],
        "grisRisco": data[i][11],
        "grisExtremoRisco": data[i][12],
        "valorAdValoremMinimo": data[i][13],
        "valorGrisMinimo": data[i][14],
        "taxaDespacho": data[i][15],
        "valorPedagio": data[i][16],
        "taxaGris": data[i][17],
        "taxaGrisRisco": data[i][18],
        "taxaGrisExtremoRisco": data[i][19],
        "taxaFluvial": data[i][20],
        "taxaDificuldadeAcesso": data[i][21],
        "valorMinimo": data[i][22],
        "valorNF": data[i][23],
        "valorDescargaPorPeso": data[i][24],
        "valorPorKilo": data[i][25],
        "taxaCTRC": data[i][26],
        "valorTaxaTRT": data[i][27],
        "valorTaxaEMEX": data[i][28],
        "valorTaxaTDE": data[i][29],
        "valorMinimoTDE": data[i][30],
        "valorMinimoTRT": data[i][31],
        "valorMinimoEMEX": data[i][32],
        "fatorCubagem": data[i][33]
      })
    }
   
  }

  getTabelaFreteOgDes(arrayPrecos[0].idTransportadora, arrayPrecos[0].ufOrigem, arrayPrecos[0].ufDestino, tipoFrete).then(respGet => {
    
    console.log('RESP DO GET ->', respGet[0])
   

    if(respGet[0] != undefined){
      uploadPrecoTransportadoras(arrayPrecos, tipoFrete).then(respUploadTransportadoras => {
          console.log("TIPO FRETE ->", tipoFrete)
        if(respUploadTransportadoras.errors){
          var stringErros = ""
          for (const key in respUploadTransportadoras.errors) {
            if (respUploadTransportadoras.errors.hasOwnProperty(key)) {
              console.log("string -> ", stringErros, 'key ->', key)
              stringErros = stringErros+ key + ", "
            }
          }
          setMesageResponse("A tabela que você enviou continha erro nas colunas: " + stringErros )
          setstatusResponse(0)
          setIsLoading(null)
          setData(null)
          return 0;
        }
      
        //Realiza o delete de todos, e depois um upload da ultima solicitação
        deleteTabelaFrete(arrayPrecos[0].idTransportadora, arrayPrecos[0].ufOrigem, arrayPrecos[0].ufDestino , tipoFrete).then(respDelete => {
          uploadPrecoTransportadoras(arrayPrecos, tipoFrete).then(respUploadTransportadoras => {
            
            console.log("entrou no delete")
  
            setMesageResponse("Substituimos a tabela " + respUploadTransportadoras[0].ufOrigem + "/" +  respUploadTransportadoras[0].ufDestino)
            setstatusResponse(2)
            setIsLoading(null)
            setData(null)
            onUploadCompleted(true)
            return 0;
          })
        })

      })
    }

    if(respGet[0] === undefined){
      uploadPrecoTransportadoras(arrayPrecos, tipoFrete).then(respUploadTransportadoras => {
          
        if(respUploadTransportadoras.errors){
          var stringErros = ""
          for (const key in respUploadTransportadoras.errors) {
            if (respUploadTransportadoras.errors.hasOwnProperty(key)) {
              console.log("string -> ", stringErros, 'key ->', key)
              stringErros = stringErros+ key + ", "
            }
          }
          setMesageResponse("A tabela que você enviou continha erro nas colunas: " + stringErros )
          setstatusResponse(0)
          setIsLoading(null)
          setData(null)
          return 0;
        }

        //Realiza o delete de todos, e depois um upload da ultima solicitação
        deleteTabelaFrete(arrayPrecos[0].idTransportadora, arrayPrecos[0].ufOrigem, arrayPrecos[0].ufDestino, tipoFrete).then(respDelete => {
          uploadPrecoTransportadoras(arrayPrecos, tipoFrete).then(respUploadTransportadoras => {
            
            console.log("entrou no delete")
  
            setMesageResponse("A tabela " + respUploadTransportadoras[0].ufOrigem + "/" +  respUploadTransportadoras[0].ufDestino + " foi criada com sucesso!")
            setstatusResponse(1)
            setIsLoading(null)
            setData(null)
            onUploadCompleted(true);
            return 0;
          })
        })

      })
    }

       

  })



}

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = async (event) => {
      const buffer = event.target.result;
      const workbook = XLSX.read(buffer, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assume a única planilha
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      setData(jsonData);
      console.log(jsonData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div className="PageUploadTransportadora">


        
      {/* Espaço da tabela */}
       {isLoading ? (
          <div style={{ marginTop: "3vh" }}>
            <Oval stroke="#9E3FFD" />
          </div>
        ) : (

        <>
          {data && (
            <table className='tableUpload'>
              <tbody>
                {data.map((row, index) => (
                  <tr className='rowUpload' key={index}>
                    {row.map((cell, cellIndex) => (
                      <td className='itemTableUpload' key={cellIndex}>{cell}</td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
            )}

          {mesageResponse && (
            <div style= {{display: 'flex', justifyContent: 'flex-start', gap: '2vw 2vh', marginBottom: '40px'}}>
              {statusResponse == '2' && (
                  <Alert color="yellow" message={mesageResponse}></Alert>
              )}
              {statusResponse == '1' && (
                  <Alert color="green" message={mesageResponse}></Alert>
              )}
              {statusResponse == '0' && (
                  <Alert color="red" message={mesageResponse}></Alert>
     
              )}  
            </div> 
          )}
          
        {/* Espaço dos botões */}

            <div style= {{display: 'flex', justifyContent: 'flex-start', gap: '2vw 2vh', width: '100%'}}>
              {data && (
                <>
                  <label class="custom-file-upload">
                    <input type="file" onChange={handleFileChange}/>
                    Selecionar outra tabela
                  </label>
                  <Button onClick={handleClick} title="enviar tabela" width="250px"></Button>
                </>
              )}

              {!data && (
                <>
                  <label class="button-file-upload">
                    <input type="file" onChange={handleFileChange}/>
                    Selecionar tabela de preços
                  </label>  
                  
                  <label class="custom-file-upload" onClick={handleDownloadXlsx}>
                    Baixar tabela referência
                  </label>
                
                </>
              )}
            </div>
        </>
        )}
       
      </div>  
  );
}




export default PageUploadTransportadora;
