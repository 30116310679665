import './PageCotacao.css';
import BoxInputCotacao from '../../../Components/BoxInputCotacao/BoxInputCotacao'
import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxResultCotacao from '../../../Components/BoxResultCotacao/BoxResultCotacao';
import logoTransportadora from "../../../images/image5.png"
import { useState, useEffect } from 'react';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import Table from '../../../Components/Table/Table';
import { createMensagem } from '../../../Service/MensagemService';
import { getUser } from '../../../Service/LoginService';


function PageCotacao() {

  const [resultCotacao, setResultCotacao] = useState([]);
  const [requestCotacao, setRequestCotacao] = useState([]);
  const [loadingCotacao, setLoadingCotacao] = useState(0);
  const [dadosCotacao, setDadosCotacao] = useState([]);

  const handleRequestCotacao = (resp) => {
    console.log("fez a req!", resp)
    setLoadingCotacao(1);
    setRequestCotacao(resp);
    setDadosCotacao([])
  };

  const handleResultCotacao = (resp) => {
    setResultCotacao(resp);
    setLoadingCotacao(0);
    

    if (resp.length > 0) {
      const dadosRequestCotacao = resp[resp.length-1].request

      var novoDadosCotacao = resp.map(cotacao => {
        //Se o peso da cubagem (vol * fator) for maior que o peso do pacote, considerar o peso da cubagem no valorKilo
        console.log('COTACAO ->', cotacao)
        
        if(cotacao.nomeTransportadora) {
          console.log("Tentativa de trazer nome atualizado -> ", cotacao.prazo, ' ||| ', JSON.stringify(cotacao))
          return {
            'imagePerfil': cotacao.imageTransportadora,
            'idTransportadora': cotacao.idTransportadora,
            'Transportadora': cotacao.nomeTransportadora,
            'Prazo': cotacao.prazo + " dias",
            'Tipo de frete': cotacao.tipoFrete,
            'Preço': "R$" + cotacao.preco,
            'Telefone': cotacao.telefone
          };
        } else {
          return null; // Retorna nulo para os itens que não devem ser adicionados ao array
        }
      }).filter(item => item !== null); // Filtra os itens nulos (que não foram adicionados)
      
      //Verifica se é página de transportadora para filtrar somente resultado dela
      if(JSON.parse(localStorage.dataUser).tipouser == 1){
        novoDadosCotacao = novoDadosCotacao.filter((element) =>  element.idTransportadora == JSON.parse(localStorage.dataUser)._id)
      }

      setDadosCotacao(novoDadosCotacao);
      
      //Guarda todos os usuarios que fizeram a req de frete
      const objectsMensagemCotacao = resp.map(cotacao => {
        
        if(cotacao.nomeTransportadora) {
          return {
          "idTransportadora": cotacao.idTransportadora,
          "idCliente": JSON.parse(localStorage.dataUser)._id,
          "mensagem": "cotacao",
          "cotacao" : {
            "cepOrigem" : dadosRequestCotacao.cepOrigem,
            "cepDestino": dadosRequestCotacao.cepDestino,
            "tipoFrete": cotacao.tipoFrete,
            "valor": "R$ " + cotacao.preco,
            "cliente": JSON.parse(localStorage.dataUser).nome,
            "data": new Date().toLocaleDateString('pt-BR')
          }
          };
        } else {
          return null; // Retorna nulo para os itens que não devem ser adicionados ao array
        }
      }).filter(item => item !== null); // Filtra os itens nulos (que não foram adicionados)

      createMensagem(objectsMensagemCotacao).then(
        console.log("MENSAGEM CRIADA COM SUCESSO!")
      )
      
  

    }
  };

  function handleLinhaClicada(dados){
    var telefoneFormatado = "55" + dados.Telefone.replace("(", "").replace(")", "").replace("-", "")
    console.log("REQUEST -> ", requestCotacao)
    var texto = "Realizei uma cotação através da Plataforma *Logiflow* segue detalhes : \n \n"  + " * cep de destino: " + requestCotacao.cepDestino + "\n * cep de origem: " + requestCotacao.cepOrigem + "\n * peso do pacote: " + requestCotacao.pesoPacote + "kg \n * valor da nota fiscal: R$ " + requestCotacao.valorNotaFiscal + "\n * VALOR DO FRETE: " + dados.Preço + "\n \n Gostaria de confirmar o prazo de retirada do material";
    const mensagem = encodeURIComponent(texto)
    window.open('https://wa.me//'+telefoneFormatado+'?text='+mensagem, '_blank').focus();
    console.log('dados da linha -> ', dados);
  }


  const colunasCotacao = ['Transportadora', 'Prazo', 'Tipo de frete','Preço'];

  return (
    <div className="PageTesteCotacao">

      <TitlePage
        title="Realize uma cotação de frete"
        subTitle="Veja quanto irá custar o seu frete">
      </TitlePage>

      <BoxInputCotacao onResultCotacao={handleResultCotacao} tipoInput={1} onRequestCotacao={handleRequestCotacao}></BoxInputCotacao>

      <div style={{marginTop: '10vh'}}>
        <TitlePage
          title="Confira o resultado da sua cotação"
          subTitle="Informe os dados da remessa para visualizar todas as transportadoras disponíveis para a entrega">
        </TitlePage>
        <div style={{display: 'flex', flexDirection: 'col', gap: "5vw 5vw", width: '100%', flexWrap: 'wrap', justifyContent: "center" }}>

          {dadosCotacao.length > 0 ? (
            <Table dados={dadosCotacao} colunas={colunasCotacao} botao={'Contratar'} onLinhaClicada={handleLinhaClicada} base64Data={true}></Table>
          ) : <p></p>}

          {loadingCotacao === 1 ? (
            <p><Oval stroke="#9E3FFD" /></p>
          ) : (<p></p>)}

        </div>
      </div>
    </div>
  );
}

export default PageCotacao;
