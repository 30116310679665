import './PageTransportadoras.css';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxResultTransportadoras from '../../../Components/BoxResultTransportadoras/BoxResultTransportadoras';    
import logoTransportadora from "../../../images/image5.png"
import { getTransportadoras } from '../../../Service/TransportadoraService';
import React, { useState, useEffect } from 'react';
import Base64Image from '../../../Components/Base64Image/Base64Image';
import '../../../Components/BoxResultTransportadoras/BoxResultTransportadoras.css';
import Button from '../../../Components/Button/Button';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import {FaTruck } from 'react-icons/fa';
import { IconContext } from "react-icons";
import Table from '../../../Components/Table/Table';

function PageTransportadoras() {
  

  const [transportadorasData, setTransportadorasData] = useState([]);
  const [isLoading, setIsLoading] = useState(1);
  const [dadosTransportadoras, setDadosTransportadoras] = useState([{}]);
  const [base64Array, setBase64Array] = useState([]);

  useEffect(() => {
    getTransportadoras().then(resp => {
      
      setTransportadorasData(resp);
      setDadosTransportadoras(resp);
      const arrayResp = []
      
      resp.forEach(item => {
        arrayResp.push(item.imagePerfil)
      })

      setBase64Array(arrayResp)
      setIsLoading(0);
      console.log("Array de fotos ->", arrayResp )
      console.log("array de dados ->", resp)
    });
    
  }, []);



  const colunasCotacao = ['nome', 'email', 'telefone'];

  return (
    <div className="PageTransportadoras">
        <TitlePage 
          title="Explore nossas transportadoras parceiras" 
          subTitle="Descubra um extenso hub das melhores transportadoras do país, trazendo a solução ideal para o seu transporte">
        </TitlePage>
        
        

        {isLoading ? (
          <div style={{ marginTop: "4vh" }}>
            <Oval stroke="#9E3FFD" />
          </div>
        ) : (
        <div style={{display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap', justifyContent: 'center'}}>
          <Table dados={dadosTransportadoras} colunas={colunasCotacao} botao={'Contratar'} base64Data={true}></Table>
        </div>)}
        


    </div>
  );
}

export default PageTransportadoras;
