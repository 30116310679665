import './Button.css'
import styled from 'styled-components';
import { Responsividade } from '../../util/responsividade';

const StyledButton = styled.button`
    border-radius: 8px;
    border-width: 0;
    color: white;
    background-color: #9E3FFD;
    border: solid #9E3FFD 1px ;
    transition: 1s;
    cursor: pointer;
    height:  40px;

    ${Responsividade.lessThan('md')`
        height:  5vh;
    
    `};
    
    &:hover{
    background: white;
    color: #9E3FFD;
    border: solid #9E3FFD 1px ;
    font-weight: bold;
    }

`;



export default function Button(props){
    return(
        <StyledButton className="button"
            onClick={props.onClick}
            style={{  
                     width: props.width
                    }}
        >{props.title}</StyledButton>
    )
}