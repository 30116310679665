import React from "react";
import { Route, BrowserRouter, Routes, Router, Navigate } from "react-router-dom";

import TabelaCliente from "../Components/TabelaCliente/TabelaCliente";
import PageCotacao from "../pages/SistemaCliente/Cotacao/PageCotacao";
import PageCotacaoFracionada from "../pages/SistemaCliente/CotacaoFracionada/PageCotacaoFracionada";
import PageCotacaoLotacao from "../pages/SistemaCliente/CotacaoLotacao/PageCotacaoLotacao";
import PageCotacaoSubContratacao from "../pages/SistemaCliente/CotacaoSubContratacao/PageCotacaoSubContratacao";

const AdmRoutes = () => {
   return(
        <Routes>
            <Route path="" element={ <Navigate to="/home" /> } />
            <Route element={<TabelaCliente />} path="/home" />
            <Route element={<PageCotacao />} path="/cotacao/distribuicao" />
            <Route element={<PageCotacaoLotacao/>} path="/cotacao/lotacao" />
            <Route element={<PageCotacaoSubContratacao />} path="/cotacao/subcontratacao" />
            <Route element={<PageCotacaoFracionada />} path="/cotacao/fracionada" />
        </Routes> 
   )
}

export default AdmRoutes;