import './PageRecuperarSenha.css';
import { useEffect } from 'react';
import logoLogFlow from '../../../images/LogotipoCortado.png'
import BoxInputRecuperarSenha from '../../../Components/BoxInputRecuperarSenha/BoxInputRecuperarSenha';
import { Link } from 'react-router-dom';
function PageRecuperarSenha() {

  return (
    <div className='PageRecuperarSenha'>
      {/* <BoxInputRecuperarSenha></BoxInputRecuperarSenha> */}.

      <Link to="/">
        <img src={logoLogFlow} className='LogoFlow'></img>
      </Link>


      <BoxInputRecuperarSenha></BoxInputRecuperarSenha>

    </div>
  );
}

export default PageRecuperarSenha;
