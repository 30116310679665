import './BoxResultCotacao.css';
import logoTransportadora from "../../images/image5.png"
import Button from '../Button/Button';

function BoxResultCotacao(props) {
  return (
        <div className='BoxResultCotacao'>
            <div className='fotoResultCotacao'>
                <img src={props.imageTransportadora}></img>
            </div>
            <div style={{marginTop: '1vh', display: 'flex', flexDirection: 'column'}}>
                <a className='titleResultCotacao'>{props.nomeTransportadora}</a>
                <a className='SubTitleResultCotacao'>{props.cidadeTransportadora}</a>
            </div>
            <div style={{marginTop: '2vh'}}>
                <a className='valorResultCotacao'>{props.precoTransportadora}</a>
            </div>
            <div style={{marginTop: '2vh', marginBottom:"1vh", display: 'flex', flexDirection: 'column'}}>
                <Button title="utilizar transportadora" width="100%" height="6vh"></Button>
            </div>
        </div>

  );
}

export default BoxResultCotacao;
