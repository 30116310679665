const apiUrl = 'https://us-central1-logiflow-backend.cloudfunctions.net/api/';
// const apiUrl = 'http://localhost:3000/'

async function loginCheck(user, password) {
  console.log("ta fazendo o check")
  try {
    const response = await fetch(apiUrl + "user/check", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "email": user.toLowerCase(),
        "senha": password
      }),
    });

    if (!response.ok) {
      if (response.status === 403 || response.status === 500 || response.status === 404) {
        // Tratamento para erro 403 (Acesso proibido)
        console.log('Acesso proibido! Verifique suas credenciais.');
        return 403; // Ou você pode lançar uma exceção personalizada, se preferir
      }
      // Tratamento para outros erros (opcional)
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    const data = await response.json();
    console.log('checou o login -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    throw error;
  }
}

async function registerUser(obj) {

  try {
    const response = await fetch(apiUrl + "user/register", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(obj),
    });

    if (!response.ok) {
      if (response.status === 401) {

        return "401";
      }
      // Tratamento para outros erros (opcional)
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    const data = await response.json();
    console.log('checou o login -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

async function generateTokenForget(email) {

  try {
    const response = await fetch(apiUrl + "user/password/createTokenForget", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ "email": email }),
    });



    const data = await response.json();
    console.log('criou o token -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

async function changePassWord(email, senha, token) {

  try {
    const response = await fetch(apiUrl + "user/password/changePassword", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          "email": email,
          "senha": senha,
          "token": token
        }
      ),
    });



    const data = await response.json();
    console.log('criou o token -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

async function checkTokenForget(email, token) {

  try {
    const response = await fetch(apiUrl + "user/password/checkTokenForget", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(
        {
          "email": email,
          "token": parseInt(token)
        }
      ),
    });



    const data = await response.json();
    console.log('check do token -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }
}

async function uploadUser(params) {
  console.log('tenadno o upload com: ', params)
  var bodyAdaptado = ""

  if (params.senha != " ") {
    bodyAdaptado = JSON.stringify({
      email: params.email,
      nome: params.nome,
      imagePerfil: params.imagePerfil,
      cnpj: params.cnpj,
      telefone: params.telefone,
      senha: params.senha,
      emailSac: params.emailSac,
      emailComercial: params.emailComercial,
      emailFinanceiro: params.emailFinanceiro,
      arrayTags: params.arrayTags
    })
  }

  if (params.senha == " ") {
    bodyAdaptado = JSON.stringify({
      email: params.email,
      nome: params.nome,
      imagePerfil: params.imagePerfil,
      cnpj: params.cnpj,
      telefone: params.telefone,
      arrayTags: params.arrayTags
    })
  }
  try {
    const response = await fetch(apiUrl + "user/uploadUser", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: bodyAdaptado
    });

    const data = await response.json();
    console.log('fez o upload -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}



async function uploadArmazenagem(email, preco) {

  try {
    const response = await fetch(apiUrl + "user/uploadUser", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        precoArmazenagem: preco
      })
    });

    const data = await response.json();
    console.log('fez o upload -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}

async function confirmEmail(userEmail) {

  try {
    const response = await fetch(apiUrl + "user/verifyEmail/" + userEmail, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: ""
    });

    if (!response.ok) {
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    const data = await response.json();
    console.log('checou o login -> ' + JSON.stringify(data));
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}


async function getUser(param) {

  try {
    const response = await fetch(apiUrl + "user/getUser/" + param, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}

async function delUser(param) {

  try {
    const response = await fetch(apiUrl + "user/cnaodsajoi/" + param, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (!response.ok) {
      console.log('Erro de requisição:', response.status, response.statusText);
      throw new Error('Erro de requisição');
    }

    const data = await response.json();
    console.log('resposta do getUser -> ', await response);
    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}

async function getAllUser() {

  try {
    const response = await fetch(apiUrl + "user/allUser", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = await response.json();

    return data;
  } catch (error) {
    console.error('Erro ao fazer a requisição:', error);
    // Aqui você pode realizar outros tratamentos de erro, se necessário
    throw error;
  }


}

export { loginCheck, registerUser, confirmEmail, uploadUser, uploadArmazenagem, getUser, getAllUser, delUser, generateTokenForget, checkTokenForget, changePassWord };
