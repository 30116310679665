import BoxInputPacote from '../../../Components/BoxInputPacote/BoxInputPacote';
import BoxResultCotacao from '../../../Components/BoxResultCotacao/BoxResultCotacao';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxInputCotacao from '../../../Components/BoxInputCotacao/BoxInputCotacao'
import './PageSeguro.css';
import Table from '../../../Components/Table/Table';

function PageCobertura() {
    const dadosSeguradora = [
        {
            'Nome seguradora': 'Porto seguro'
        },
        {
            'Nome seguradora': 'Bradesco Seguros'
        },
        {
            'Nome seguradora': 'SulAmérica'
        },
        {
            'Nome seguradora': 'Itaú Seguros'
        },
        {
            'Nome seguradora': 'Mapfre'
        },
        {
            'Nome seguradora': 'Tokio Marine'
        },
        {
            'Nome seguradora': 'HDI Seguros'
        },
        {
            'Nome seguradora': 'Liberty Seguros'
        },
        {
            'Nome seguradora': 'Zuric Seguros'
        },
        {
            'Nome seguradora': 'Allianz Seguros'
        },

    ]
    const colubnasSeguradora = ['Nome seguradora'];

    const handleLinhaClicada = (dados) => {
        const mensagem = encodeURIComponent('Aqui é o usuário ' + JSON.parse(localStorage.dataUser).nome + ', e gostaria de fazer uma cotação com a seguradora ' + dados["Nome seguradora"])
        window.open('https://wa.me//5511945122317?text='+mensagem, '_blank').focus();
        console.log('dados da linha -> ', dados);
      };

    return (
        <div className="Cobertura">

    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <TitlePage title="Seguro de cargas para transportadoras" subTitle="Realize a cotação de um seguro para sua carga, frota ou pacote" />
            <Table dados={dadosSeguradora} colunas={colubnasSeguradora} botao={'Realizar cotação'} onLinhaClicada={handleLinhaClicada}></Table>
    </div>



        </div>
    );
}

export default PageCobertura;
