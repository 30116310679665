// BoxInputCadastro.js
import './BoxInputCadastro.css';
import Input from '../Input/Input';
import Button from '../Button/Button';
import { useState, useContext } from 'react';
import { registerUser } from '../../Service/LoginService';
import { LoginContext } from '../../Context/LoginContext';
import { Oval } from 'react-loading-icons';
import Alert from '../Alert/Alert';

function BoxInputCadastro() {
  const [usuario, setUsuario] = useState({
    email: '',
    senha: '',
    nome: '',
    tipouser: '',
  });

  const [isLoading, setIsLoading] = useState(false);
  const [respReq, setResReq] = useState("");
  const [showInputsCliente, setShowInputsCliente] = useState(false);
  const [showInputsTransportadora, setShowInputsTransportadora] = useState(false);
  const { dataUserContext, setDataUserContext } = useContext(LoginContext);
  const [LoginError , setLoginError] = useState(false)
  const [errorMessage , setErrorMessage] = useState('')

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUsuario({
      ...usuario,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    setIsLoading(true);
    registerUser(usuario).then((resp) => {
      console.log("TENTANDO REGISTRAR USUARIO")
      setIsLoading(false);
      setDataUserContext(resp)
      if(resp == "Erro de requisição: 403 Forbidden"){
        setLoginError(true)
        setErrorMessage('email já cadastrado em nossa base de clientes')
        return 0;
      }
      localStorage.setItem('dataUser', JSON.stringify(resp));
      setResReq(JSON.stringify(resp));
    }, (erro) => {
      setIsLoading(false);
      setLoginError(true)
      setErrorMessage('email já cadastrado em nossa base de clientes')
      return 0;
    });
  };

  const handleBoxClickCliente = () => {
    setUsuario({
      ...usuario,
      tipouser: 2,
    });
    setShowInputsTransportadora(false);
    setShowInputsCliente(true);
    setLoginError(false); // Reset the error state when switching to cliente
  };

  const handleBoxClickTransportadora = () => {
    setUsuario({
      ...usuario,
      tipouser: 1,
    });
    setShowInputsCliente(false);
    setShowInputsTransportadora(true);
    setLoginError(false); // Reset the error state when switching to transportadora
  };

  return (
    <div className="divCadastro">

      <div className={`BoxInputCadastro ${showInputsCliente ? "showInputs" : ""}`} onClick={handleBoxClickCliente}>
        <div className="Titles" style={{ marginTop: "2vh", display: 'flex', alignItems: 'start', flexDirection: 'column', color: '#9E3FFD' }}>
          <h1 className='titleBox'>Sou Cliente</h1>
          <p className='subTitleBox'>Está buscando a transportadora ideal para suas remessas?</p>
        </div>

        {LoginError && showInputsCliente && (
          <Alert message={errorMessage} />
        )}
        
        {showInputsCliente && <div style={{ marginTop: "2vh", display: 'flex', flexDirection: "row", gap: "1vw 1vh", width: '100%', flexWrap: 'wrap' }}>
          <Input content="Email" width="100%" name="email" value={usuario.email} onChange={handleInputChange} />
          <Input type="password" content="Senha" width="100%" name="senha" value={usuario.senha} onChange={handleInputChange} />
          <Input content="Nome" width="100%" name="nome" value={usuario.nome} onChange={handleInputChange} />
        </div>}

        {isLoading && showInputsCliente ? (
          <div style={{ marginTop: "4vh" }}>
            <Oval stroke="#9E3FFD" />
          </div>
        ) : (
          showInputsCliente && <div style={{ marginTop: "4vh", display: 'flex' }}>
            <Button title="Cadastrar como cliente" width="100%" height="6vh" onClick={handleSubmit} />
          </div>
        )}
      </div>

      <div className={`BoxInputCadastro ${showInputsTransportadora ? "showInputs" : ""}`} onClick={handleBoxClickTransportadora}>
        <div className="Titles" style={{ marginTop: "2vh", display: 'flex', alignItems: 'start', flexDirection: 'column', color: '#9E3FFD' }}>
          <h1 className='titleBox'>Sou Transportadora</h1>
          <p className='subTitleBox'>Cadastre-se como uma transportadora e faça novos clientes</p>
        </div>

        
        {LoginError && showInputsTransportadora && (
          <Alert message={errorMessage} />
        )}

        {showInputsTransportadora && <div style={{ marginTop: "2vh", display: 'flex', flexDirection: "row", gap: "1vw 1vh", width: '100%', flexWrap: 'wrap' }}>
          <Input content="Email" width="100%" name="email" value={usuario.email} onChange={handleInputChange} />
          <Input type="password" content="Senha" width="100%" name="senha" value={usuario.senha} onChange={handleInputChange} />
          <Input content="Nome" width="100%" name="nome" value={usuario.nome} onChange={handleInputChange} />
        </div>}

        {isLoading && showInputsTransportadora ? (
          <div style={{ marginTop: "4vh" }}>
            <Oval stroke="#9E3FFD" />
          </div>
        ) : (
          showInputsTransportadora && <div style={{ marginTop: "4vh", display: 'flex' }}>
            <Button title="Cadastrar como transportadora" width="100%" height="6vh" onClick={handleSubmit} />
          </div>
        )}
      </div>

    </div>
  );
}

export default BoxInputCadastro;
