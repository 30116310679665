import React from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";

import PageUploadTransportadora from "../pages/SistemaTransportadoras/UploadTransportadora/PageUploadTransportadora";
import HomeTransportadora from "../pages/SistemaTransportadoras/HomeTransportadora/HomeTransportadora";
import PageSeguro from "../pages/SistemaTransportadoras/Seguro/PageSeguro"
import PageIntegracao from "../pages/SistemaTransportadoras/Integracao/pageIntegracao"
import PageIntegracaoERP from "../pages/SistemaTransportadoras/Integracao/pageIntegracaoERP"
import PageSuporte from "../pages/SistemaTransportadoras/Suporte/pageSuporte"
import PagePerfil from "../pages/SistemaTransportadoras/Perfil/PagePerfil";
import PageTabelaPreco from "../pages/SistemaTransportadoras/TabelaPreco/PageTabelaPreco";
import PageTesteCotacao from "../pages/SistemaTransportadoras/TesteCotacao/PageTesteCotacao";
import PageTabelaFracionada from "../pages/SistemaTransportadoras/TabelaFracionada/PageTabelaFracionada";
import PageTabelaLotacao from "../pages/SistemaTransportadoras/TabelaLotacao/PageTabelaLotacao";
import PageTabelaSuSubContratacao from "../pages/SistemaTransportadoras/TabelaSubContratacao/PageTabelaSubContratacao";
import PageCotacao from "../pages/SistemaCliente/Cotacao/PageCotacao";
import PageCotacaoLotacao from "../pages/SistemaCliente/CotacaoLotacao/PageCotacaoLotacao";
import PageCotacaoFracionada from "../pages/SistemaCliente/CotacaoFracionada/PageCotacaoFracionada";
import PageCotacaoSubContratacao from "../pages/SistemaCliente/CotacaoSubContratacao/PageCotacaoSubContratacao";
import PageValorArmazenagem from "../pages/SistemaTransportadoras/ValorArmazenagem/PageValorArmazenagem";

const TransportadoraRoutes = () => {
   return(
        <Routes>
            <Route element={<PageUploadTransportadora />} path="transportadora/uploadTransportadora" />
            <Route element={<HomeTransportadora />} />
            <Route element={<HomeTransportadora />} path="/home" />
            <Route path="" element={ <Navigate to="/home" /> } />

            <Route element={<PageTabelaPreco tipoFrete={1}/>} path="/cotacao/distribuicao" />
            <Route element={<PageTabelaLotacao/>} path="/cotacao/lotacao" />
            <Route element={<PageTabelaPreco tipoFrete={3}/>} path="/cotacao/subcontratacao" />
            <Route element={<PageTabelaPreco tipoFrete={4} />} path="/cotacao/fracionada" />

            <Route element={<PageTabelaPreco />} path="/tabela-preco" />
            
            <Route element={<PageCotacao />} path="/teste-cotacao/distribuicao" />
            <Route element={<PageCotacaoLotacao />} path="/teste-cotacao/lotacao" />
            <Route element={<PageCotacaoSubContratacao />} path="/teste-cotacao/subcontratacao" />
            <Route element={<PageCotacaoFracionada />} path="/teste-cotacao/fracionada" />

            <Route element={<PageTesteCotacao />} path="/cotacao" />

            <Route element={<PageValorArmazenagem />} path="/armazenagem" />


            <Route element={<PageIntegracao />} path="/integracao" />
            <Route element={<PageIntegracaoERP />} path="/integracaoERP" />
            <Route element={<PageSeguro />} path="/seguro" />
            <Route element={<PageSuporte />} path="/suporte" />
            <Route element={<PagePerfil />} path="/perfil" />
        </Routes> 
   )
}

export default TransportadoraRoutes;