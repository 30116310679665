import "./TabelaCliente.css";
import BoxInputRastreio from "../BoxInputRastreio/BoxInputRastreio";
import TitlePage from "../TitlePage/TitlePage";
import Table from "../Table/Table";
import BoxResultCotacao from "../BoxResultCotacao/BoxResultCotacao";
import BoxResultEtiquetas from "../BoxResultEtiquetas/BoxResultEtiquetas";
import { useState, useEffect } from "react";
import { getTransportadoras } from "../../Service/TransportadoraService";
import Oval from "react-loading-icons/dist/esm/components/oval";
import { delUser, getAllUser } from "../../Service/LoginService";
import Input from "../Input/Input";
import ImageUploader from "../UploadImage/UploadImage";
import InputMask from "react-input-mask";
import Button from "../Button/Button";
import { uploadUser } from "../../Service/LoginService";
import Alert from "../Alert/Alert";

function TabelaCliente() {
  const [transportadorasData, setTransportadorasData] = useState([]);
  const [isLoading, setIsLoading] = useState(1);
  const [dadosTransportadoras, setDadosTransportadoras] = useState([{}]);
  const [base64Array, setBase64Array] = useState([]);
  const [compressedData, setCompressedData] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [dadosModal, setDadosModal] = useState({});
  const [showModalChangePassword, setShowModalChangePassword] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);

  const [showModalSuccesAlert, setShowModalSuccesAlert] = useState(false);
  const [showModalDeleteAlert, setShowModalDeleteAlert] = useState(false);

  async function getAllUsers() {
    getAllUser().then(async (resp) => {
      async function updateData(data) {
        for (const element of data) {
          element.tipo = element.tipouser == 2 ? "cliente" : "transportadora";
        }
      }

      await updateData(resp);

      await updateData(resp);

      const arrayResp = [];

      resp.forEach((item) => {
        if (!item.imagePerfil) {
          arrayResp.push({
            ...item,
            imagePerfil: "",
          });
        } else {
          arrayResp.push(item);
        }
      });
      setTransportadorasData(arrayResp);
      setDadosTransportadoras(arrayResp);

      setBase64Array(arrayResp);
      setIsLoading(0);
    });
  }

  useEffect(() => {
    getAllUser().then(async (resp) => {
      async function updateData(data) {
        for (const element of data) {
          element.tipo = element.tipouser == 2 ? "cliente" : "transportadora";
        }
      }

      await updateData(resp);

      const arrayResp = [];

      resp.forEach((item) => {
        if (!item.imagePerfil) {
          arrayResp.push({
            ...item,
            imagePerfil: "",
          });
        } else {
          arrayResp.push(item);
        }
      });
      setTransportadorasData(arrayResp);
      setDadosTransportadoras(arrayResp);

      setBase64Array(arrayResp);
      setIsLoading(0);
    });
  }, []);

  function handleLinhaClicada(dados) {
    const dadosCliente = transportadorasData.find((element) => {
      return dados._id === element._id;
    });
    dadosCliente.senha = " ";
    setDadosModal(dadosCliente);
    console.log("dados cliente -> ", dadosCliente);
    setShowModal(!showModal);
  }

  function changeModalState() {
    setShowModal(!showModal);
  }

  function changeModalChangePasswordState() {
    setShowModalChangePassword(!showModalChangePassword);
  }

  function changeModalDelete() {
    setShowModalDelete(!showModalDelete);
  }

  const countTransportadoras = () => {
    const transportadorasComTipoUserUm = transportadorasData.filter(
      (item) => item.tipouser === 1
    );
    return transportadorasComTipoUserUm.length;
  };

  const countCliente = () => {
    const transportadorasComTipoUserUm = transportadorasData.filter(
      (item) => item.tipouser === 2
    );
    return transportadorasComTipoUserUm.length;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(event.target);
    setDadosModal({
      ...dadosModal,
      [name]: value,
    });
  };

  const handleUploadImage = (data) => {
    // This function will receive the compressed data from the ImageUploader component
    setCompressedData(data);
    setDadosModal({
      ...dadosModal,
      imagePerfil: data,
    });
    console.log("datacompress -> ", data);
  };

  function updateClient() {
    setIsLoading(1);

    uploadUser(dadosModal).then((resp) => {
      setShowModalSuccesAlert(true);
      getAllUsers().then((resp) => {
        setIsLoading(0);
      });
    });
  }

  function deleteClient() {
    setShowModalDelete(false);
    setShowModal(false);
    setIsLoading(1);
    delUser(dadosModal._id).then((resp) => {
      uploadUser(dadosModal).then((resp) => {
        getAllUsers().then((resp) => {
          setShowModalDeleteAlert(true);

          setIsLoading(0);
        });
      });
    });
  }

  const colunasTabela = ["nome", "email", "telefone", "tipo"];

  return (
    <div className="TabelaCliente">
      <TitlePage
        title="Lista de clientes"
        subTitle="Veja todos os clientes"
      ></TitlePage>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "30px 30px",
          marginBottom: "80px",
        }}
      >
        <BoxResultEtiquetas
          nomeEtiqueta="Nº de clientes"
          fornecedorEtiqueta={countCliente() + " clientes"}
        />
        <BoxResultEtiquetas
          nomeEtiqueta="Nº de transportadoras"
          fornecedorEtiqueta={countTransportadoras() + " transportadoras"}
        />
      </div>

      {showModalDeleteAlert && (
        <div
          style={{
            width: "max-content",
            marginTop: "-20px",
            marginBottom: "20px",
          }}
        >
          <Alert
            color="red"
            message="A conta foi deletada com sucesso!"
          ></Alert>
        </div>
      )}

      {isLoading ? (
        <div style={{ marginTop: "4vh" }}>
          <Oval stroke="#9E3FFD" />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "2vw 3vh",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Table
            dados={dadosTransportadoras}
            colunas={colunasTabela}
            onLinhaClicada={handleLinhaClicada}
            botao={"Editar perfil"}
            base64Data={true}
          ></Table>
        </div>
      )}

      {showModalChangePassword && (
        <div
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            backgroundColor: "rgb(105, 105, 105, 0.5)",
            width: "85vw",
            height: "100vh",
            display: "flex",
            paddingTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "3",
          }}
        >
          <div className="whitecard">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-20px",
                marginLeft: "40px"
              }}
            >
              <p
                style={{ cursor: "pointer", fontWeight: "1000" }}
                onClick={changeModalChangePasswordState}
              >
                X
              </p>
            </div>

            <Input
              type="password"
              content="Senha"
              width="100%"
              name="senha"
              value={dadosModal.senha}
              onChange={handleInputChange}
            />
            <div style={{ marginTop: "40px" }}>
              <Button
                title="atualizar senha"
                width="410px"
                onClick={updateClient}
              ></Button>
            </div>
          </div>
        </div>
      )}

      {showModalDelete && (
        <div
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            backgroundColor: "rgb(105, 105, 105, 0.5)",
            width: "85vw",
            height: "100vh",
            display: "flex",
            paddingTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "6",
          }}
        >
          <div className="whitecard">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-20px",
                
              }}
            >
              <p
                style={{ cursor: "pointer", fontWeight: "1000" }}
                onClick={changeModalDelete}
              >
                X
              </p>
            </div>
            <TitlePage
              title="Tem certeza que deseja apagar o usuário?"
              subTitle="Depois de excluido não poderá reverter"
            ></TitlePage>
            <div style={{ marginTop: "40px", }}>
              <button
                style={{
                  width: "45%",
                  borderRadius: "5px",
                  border: "none",
                  cursor: "pointer",
                  backgroundColor: "#ff3a3a",
                  color: "white",
                  height: "40px",
                  marginTop: "20px",
                  fontWeight: "400",
                  zIndex: '6'
                }}
                onClick={deleteClient}
              >
                APAGAR PERMANENTEMENTE!
              </button>
            </div>
          </div>
        </div>
      )}

      {showModal ? (
        <div
          style={{
            position: "fixed",
            top: "0",
            right: "0",
            backgroundColor: "rgb(105, 105, 105, 0.5)",
            width: "85vw",
            height: "100vh",
            display: "flex",
            paddingTop: "50px",
            justifyContent: "center",
            alignItems: "center",
            zIndex: '5'
          }}
        >
          <div className="whitecard">
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-20px",
              }}
            >
              <p
                style={{ cursor: "pointer", fontWeight: "1000" }}
                onClick={changeModalState}
              >
                X
              </p>
            </div>

            <div className="profileForms">
              {isLoading ? (
                <div style={{ marginTop: "3vh" }}>
                  <Oval stroke="#9E3FFD" />
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px 20px",
                  }}
                >
                  <ImageUploader
                    onUpload={handleUploadImage}
                    base64Image={dadosModal.imagePerfil}
                  />
                  <div>
                    <TitlePage title="Alterar Dados de informação" />

                    {showModalSuccesAlert && (
                      <div>
                        <Alert
                          color="green"
                          message="Alterações realizadas com sucesso!"
                        ></Alert>
                      </div>
                    )}

                    <Input
                      backgroundColor="rgb(230, 230, 230)"
                      cursor="not-allowed"
                      content="Email"
                      value={dadosModal.email}
                    ></Input>

                    <Input
                      content="Nome"
                      width="60vh"
                      name="nome"
                      paddingRight="1vh"
                      onChange={handleInputChange}
                      value={dadosModal.nome}
                    ></Input>

                    <InputMask
                      mask="99.999.999/9999-99"
                      maskChar=""
                      value={dadosModal.cnpj}
                      onChange={handleInputChange}
                    >
                      {(inputProps) => (
                        <Input content="CNPJ" name="cnpj" {...inputProps} />
                      )}
                    </InputMask>

                    <InputMask
                      mask="(99)99999-9999"
                      maskChar=""
                      value={dadosModal.telefone}
                      onChange={handleInputChange}
                    >
                      {(inputProps) => (
                        <Input
                          content="Telefone"
                          name="telefone"
                          {...inputProps}
                        />
                      )}
                    </InputMask>

                    <div
                      style={{
                        marginTop: "2vh",
                        marginBottom: "40px",
                        gap: "5% 5%",
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <Button
                        title="atualizar dados"
                        width="100%"
                        onClick={updateClient}
                      ></Button>
                    </div>

                    <TitlePage title="Mais opções de alteração" />
                    <div
                      style={{
                        marginTop: "2vh",
                        gap: "5% 5%",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "-40px",
                      }}
                    >
                      <button
                        style={{
                          width: "45%",
                          borderRadius: "5px",
                          cursor: "pointer",
                          backgroundColor: "#ffeb3b",
                          color: "black",
                          height: "40px",
                          marginTop: "20px",
                          border: 'none',
                          fontWeight: "700",
                        }}
                        onClick={changeModalChangePasswordState}
                      >
                        Alterar senha da conta
                      </button>
                      <button
                        style={{
                          width: "45%",
                          borderRadius: "5px",
                          cursor: "pointer",
                          backgroundColor: "#ff3a3a",
                          color: "white",
                          height: "40px",
                          marginTop: "20px",
                          border: 'none',
                          fontWeight: "700",
                        }}
                        onClick={changeModalDelete}
                      >
                        Apagar conta!
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
}

export default TabelaCliente;
