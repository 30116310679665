import React from "react";
import { Route, BrowserRouter, Routes, Router, Navigate } from "react-router-dom";

import PageCotacao from "../pages/SistemaCliente/Cotacao/PageCotacao";
import PageCotacaoSubContratacao from '../pages/SistemaCliente/CotacaoSubContratacao/PageCotacaoSubContratacao'
import PageCotacaoLotacao from "../pages/SistemaCliente/CotacaoLotacao/PageCotacaoLotacao";
import PageCotacaoFracionada from "../pages/SistemaCliente/CotacaoFracionada/PageCotacaoFracionada";

import PageTransportadoras from "../pages/SistemaCliente/Transportadoras/PageTransportadoras";
import PageRastreio from "../pages/SistemaCliente/Rastreio/PageRastreio";
import PageArmazenagem from "../pages/SistemaCliente/Armazenagem/Armazenagem"
import Home from "../pages/SistemaCliente/Home/Home";
import PageIntegracao from '../pages/SistemaTransportadoras/Integracao/pageIntegracao';
import PageIntegracaoERP from '../pages/SistemaTransportadoras/Integracao/pageIntegracaoERP'
import PageSeguro from '../pages/SistemaTransportadoras/Seguro/PageSeguro'
import PagePerfil from "../pages/SistemaCliente/PerfilCliente/PagePerfil";
import PageTabelaLotacao from "../pages/SistemaTransportadoras/TabelaLotacao/PageTabelaLotacao";



// Páginas das rotas de fornecedor

const ClienteRoutes = () => {
   return(
        <Routes>

            <Route path="" element={ <Navigate to="/home" /> } />
            <Route element={<Home />} path="/home" />

            <Route element={<PageCotacao />} path="/cotacao" />
            <Route element={<PageCotacao />} path="/cotacao/distribuicao" />
            <Route element={<PageCotacaoLotacao/>} path="/cotacao/lotacao" />
            <Route element={<PageTabelaLotacao/>} path="/lotacao/criarLotacao" />
            <Route element={<PageCotacaoSubContratacao />} path="/cotacao/subcontratacao" />
            <Route element={<PageCotacaoFracionada />} path="/cotacao/fracionada" />


            <Route element={<PageArmazenagem />} path="/armazenagem" />
            <Route element={<PageTransportadoras />} path="/transportadoras" />
            <Route element={<PageRastreio />} path="/rastreio" />
            <Route element={<PageIntegracao />} path="/integracao" />
            <Route element={<PageIntegracaoERP />} path="/integracaoERP" />
            <Route element={<PageSeguro />} path="/seguro" />
            {/* <Route path="/etiquetas" element={<PageEtiquetas />} /> */}
            <Route path="/perfil" element={<PagePerfil />} />
        </Routes> 
   )
}

export default ClienteRoutes;