import './BoxInputPacote.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useState, useEffect } from 'react';
import { calculaFrete, calculaFrete2 } from '../../Service/CotacaoService'
import InputMask from 'react-input-mask'; 
import { getTransportadoras } from '../../Service/TransportadoraService';
import Oval from 'react-loading-icons/dist/esm/components/oval';

function BoxInputPacote({onButtonClick}) {

  const [precoMetros2, setPrecoMetros2] = useState("");
  const [isLoading, setIsLoading] = useState(0);
  const [maskPrecoMetros2, setMaskPrecoMetros2] = useState((JSON.parse(localStorage.dataUser).precoArmazenagem*100).toString());
  
  useEffect(() => {
    if(JSON.parse(localStorage.dataUser).precoArmazenagem == undefined){
      setMaskPrecoMetros2("0")
    }
    
  }, []);

  console.log('Preco -> ', JSON.parse(localStorage.dataUser).precoArmazenagem)
 

  function maskM(string){
    
    if(string == ""){
      string = " m"
    }

    var onlyNumber

    //Caso esteja apagando
    if(string.slice(-1) == " ")
    {
       onlyNumber = string.substring(0, string.length -2)
    }
    
    //Caso esteja escrevendo
    if(string.slice(-1) != " ")
    {
      onlyNumber = string.replace(" m", "")
    }

    onlyNumber = onlyNumber.replace(".", "")
    var decimalNumber = onlyNumber.substring(onlyNumber.length-2, onlyNumber.length)
    var intNumber = onlyNumber.substring(0, onlyNumber.length-2)

    if (intNumber == ""){
      return "0." + decimalNumber + " m"
    }

    if (intNumber[0] == 0 && intNumber[1] == 0 & intNumber.length>2){
      intNumber = intNumber.substring(2, intNumber.length)
    }

    if (intNumber[0] == 0 && intNumber.length>1){
      intNumber = intNumber.substring(1, intNumber.length)
    }

    
    return intNumber + "." + decimalNumber + " m"
  }

  const handleInputMaskChange = (event) => {
    const { name, value } = event.target;
    setMaskPrecoMetros2(value);
    var valorNum = parseFloat(maskM(value).replace(" m²", ""))
    setPrecoMetros2(valorNum)
  }

  const handleClick = (event) => {
    onButtonClick(precoMetros2);
  }
  
  return (
    <div className="BoxInputPacote">

          <>
           <Container>
            <Row style={{ textAlign: 'left' }}>
              <a className='titleBox'>Informe o tamanho do pacote em M²</a>
            </Row>
            <Row style={{ textAlign: 'left' }}>
              <a className='subTitleBox'>dessa forma calcularemos proporcionalmente a Pacote do cliente</a>
            </Row>
          </Container>

          
            <div style={{ marginTop: "4vh", display: 'flex', gap: '1vh 5%', flexWrap: "wrap" }}>
              <Input content="Tamanho em M²" width="25%" value={maskM(maskPrecoMetros2)} onChange={handleInputMaskChange}></Input>  
            </div>
          </>
        
      <div style={{ marginTop: "6vh", display: 'flex', justifyContent: 'end' }}>
        <Button title="Realizar cotação!" width="100%" onClick={handleClick} height="6vh"></Button>
      </div>

    </div>
  );
}

export default BoxInputPacote;
