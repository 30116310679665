import './BoxResultEtiquetas.css';


function BoxResultEtiquetas(props) {
  return (
        <div className='BoxResultEtiquetas'>
            <div style={{marginTop: '1vh', display: 'flex', flexDirection: 'column'}}>
                <a className='idResultEtiquetas'>{props.idEtiqueta}</a>
                <a className='nomeResultEtiquetas'>{props.nomeEtiqueta}</a>
                <a className='fornecedorResultEtiquetas'>{props.fornecedorEtiqueta}</a>
            </div>
        </div>

  );
}

export default BoxResultEtiquetas;