import BoxInputPacote from '../../../Components/BoxInputPacote/BoxInputPacote';
import BoxResultCotacao from '../../../Components/BoxResultCotacao/BoxResultCotacao';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import BoxInputCotacao from '../../../Components/BoxInputCotacao/BoxInputCotacao'
import './PageValorArmazenagem.css';
import Table from '../../../Components/Table/Table';
import BoxInputArmazenagem from '../../../Components/BoxInputArmazenagem/BoxInputArmazenagem';

function PageValorArmazenagem() {
    const dadosSeguradora = [
        {
            'Nome seguradora': 'Porto seguro'
        },
        {
            'Nome seguradora': 'Bradesco Seguros'
        },
        {
            'Nome seguradora': 'SulAmérica'
        },
        {
            'Nome seguradora': 'Itaú Seguros'
        },
        {
            'Nome seguradora': 'Mapfre'
        },
        {
            'Nome seguradora': 'Tokio Marine'
        },
        {
            'Nome seguradora': 'HDI Seguros'
        },
        {
            'Nome seguradora': 'Liberty Seguros'
        },
        {
            'Nome seguradora': 'Zuric Seguros'
        },
        {
            'Nome seguradora': 'Allianz Seguros'
        },

    ]
    const colubnasSeguradora = ['Nome seguradora'];
    return (
        <div className="Cobertura">

    <div style={{display: 'flex', flexDirection: 'column', width: '100%'}}>
            <TitlePage title="Deseja criar uma precificação para armazenagem?" subTitle="Na logiflow nós disponibilizamos a opção de armazenagem para nossos clientes, deseja oferecer esse serviço também?" />
            <BoxInputArmazenagem></BoxInputArmazenagem>
    </div>



        </div>
    );
}

export default PageValorArmazenagem;
