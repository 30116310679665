import './PageTabelaPreco.css';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import Input from '../../../Components/Input/Input';
import { useState, useContext, useEffect } from 'react';
import ImageUploader from '../../../Components/UploadImage/UploadImage';
import InputMask  from 'react-input-mask';
import PageUploadTransportadora from '../UploadTransportadora/PageUploadTransportadora';
import  Button  from  '../../../Components/Button/Button';
import { loginCheck, uploadUser } from '../../../Service/LoginService';
import Oval from 'react-loading-icons/dist/esm/components/oval';
import Table from '../../../Components/Table/Table';
import { getTabelaFrete } from '../../../Service/TransportadoraService';

function PageTabelaPreco(props) {
    
    const colunasCotacao = ['ufDestino', 'ufOrigem', 'idDestino', 'pesoInicial',  'pesoFinal', 'tipoFrete', 'valorFrete'];
    const [isLoading, setIsLoading] = useState(1);
    const [tipoFrete, setTipoFrete] = useState(1);
    const [nomeTipoFrete, setNomeTipoFrete] = useState("Distribuição");
    const [subTitleTipoFrete, setSubTitleTipoFrete] = useState("Frete comum, com distribuição porta a porta");
    const [uploadCompleted, setUploadCompleted] = useState(false);

    const [requestPerfil, setRequestPerfil] = useState(
        JSON.parse(localStorage.dataUser)
      )

    const [dadosCotacao, setDadosCotacao] = useState([{
        'ufDestino': '',
        'ufOrigem': '', 
        'idDestino': '', 
        'pesoInicial': '',
        'pesoFinal': '', 
        'tipoFrete': '', 
        'valorFrete': ''
    }]);
    
    function handleClick(){
        setIsLoading(1)
        getTabelaFrete(JSON.parse(localStorage.dataUser)._id, props.tipoFrete).then(resp => {
            setDadosCotacao(resp.sort((a,b) => (a.ufDestino > b.ufDestino) ? 1 : ((b.ufDestino > a.ufDestino) ? -1 : 0)))
            setIsLoading(0)
          })
    }
    
    const handleTipoFreteChange = (event) => {
        const selectedTipoFrete = parseInt(event.target.value);
        setTipoFrete(selectedTipoFrete);
      };

      const handleUploadCompleted = () => {
        handleClick()
      }

         
    //Monitora o tipoFrete
    useEffect(() => {
        console.log("MUDOU TIPO FRETE")
        
        switch(props.tipoFrete){
            case 1:
                setNomeTipoFrete("Distribuição")
                setSubTitleTipoFrete("Utilizado para frete comum, com distribuição porta a porta")
                break

            case 2:
                setNomeTipoFrete("Lotação")
                setSubTitleTipoFrete("Utilizado para grandes volumes ou cargas pesadas")
                break

            case 3:
                setNomeTipoFrete("Sub-contratação")
                setSubTitleTipoFrete("Utilizado para frete pré-acordado com contratação")
                break
            
            case 4:
                setNomeTipoFrete("Fracionada")
                setSubTitleTipoFrete("Utilizado para cargas menores que não requerem um veículo inteiro.")
                break
        }

        setIsLoading(1)
        getTabelaFrete(JSON.parse(localStorage.dataUser)._id, props.tipoFrete).then(resp => {
            setDadosCotacao(resp.sort((a,b) => (a.ufDestino > b.ufDestino) ? 1 : ((b.ufDestino > a.ufDestino) ? -1 : 0)))
            setIsLoading(0)
          })
    }, [props.tipoFrete]);
   
        
    return (
        <div className="PageTabelaPreco">
            <TitlePage
                title={"Tabela de " + nomeTipoFrete} 
                subTitle={subTitleTipoFrete} >
            </TitlePage>

            <div style={{ display: 'flex', flexDirection: "column",  justifyContent: 'center', alignItems: 'center' , gap: "20px 20px", width: '60%', flexWrap: 'wrap', marginBottom: '100px' }}>
                <div className='whitecard'>
                    <div className='tableForm'>
                        <TitlePage title={"Faça o upload da sua tabela de " + nomeTipoFrete} subTitle='utilize o padrão de tabelas da logflow para ter um orçamento correto' />
            
                        <PageUploadTransportadora onUploadCompleted={handleUploadCompleted}></PageUploadTransportadora>
                    </div>
                </div>
            </div>

            <TitlePage title={"Tabela de " +nomeTipoFrete} subTitle={"Veja sua tabela matriz de " + nomeTipoFrete + " que está cadastrada em nosso sistema"} />
            
    
            {isLoading == 1 ? (
                <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Oval stroke="#9E3FFD" />
                </div>
                
            ) : dadosCotacao.length > 2 ? (
                <Table dados={dadosCotacao} colunas={colunasCotacao}></Table>
            ) : (<></>)}
            
        </div>
    );
}

export default PageTabelaPreco;
