import React from 'react';
import { PiUserSquareFill } from 'react-icons/pi';
import { IconContext } from "react-icons";

const Base64Image = ({ base64Data, width, height, borderRadius }) => {
  const imageSrc = `${base64Data}`;
  
  const imgStyle = {
    borderRadius: borderRadius || 0, // Se a prop borderRadius não for fornecida, o valor padrão será 0 (sem borda arredondada).
  };
  return (
    <div>
      {
        base64Data ? (
          
          <img src={imageSrc} alt="Imagem em base64" width={width} height={height} style={imgStyle} />
        ) : (
          <IconContext.Provider value={{ color: "rgb(205, 205, 205)", size: {width} }}>
            <div>
              <PiUserSquareFill />
            </div>
          </IconContext.Provider>
        )
      }
      
    </div>
  );
};

export default Base64Image;