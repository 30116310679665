import './BoxInputLogin.css';
import Input from '../Input/Input'
import Button from '../Button/Button'
import { useState, useContext } from 'react';
import { loginCheck } from '../../Service/LoginService';
import { LoginContext } from '../../Context/LoginContext';
import { Link } from 'react-router-dom';
import { Route, Routes, useNavigate } from 'react-router-dom';

// Login animation
import { Oval } from 'react-loading-icons'
import Alert from '../Alert/Alert'

const flagLogin = 1;


function BoxInputLogin({handleShowCadastro}) {
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [LoginError , setLoginError] = useState(false)
  const [errorMessage , setErrorMessage] = useState('')

  const { dataUserContext, setDataUserContext } = useContext(LoginContext);
  
  const navigate = useNavigate();

  const handleUsuarioChange = (event) => {
    setUsuario(event.target.value);
  };

  const handleSenhaChange = (event) => {
    setSenha(event.target.value);
  };

  const handleSubmit = () => {
    setIsLoading(true); // Inicia o estado de carregamento
    setLoginError(false)
    loginCheck(usuario, senha)
      .then((data) => {
       
        
        if(data != 403) {
          localStorage.setItem('dataUser', JSON.stringify(data));
          setDataUserContext(data);
          navigate('/home', { replace: true })
          console.log("DATA:" , data)
        }

        if(data == 403){
          setLoginError(true)
          setErrorMessage('Usuário ou senha inválidos')
        }

        if(data == 401){
          setLoginError(true)
          setErrorMessage('Usuário já cadastrado')
        }
       
      })
      .finally(() => {
        setIsLoading(false); // Finaliza o estado de carregamento, independentemente do resultado
      });
  };
  


  return (
    <div className="BoxInputLogin">
      {/* Div Inputs */}
      <div style={{ marginTop: "2vh", display: 'flex', gap: '1vh 2vw', color: '#9E3FFD' , justifyContent: 'center',}} >
        <h1>Bem Vindo!</h1>
      </div>

      {/* Setando a mensagem de erro caso seja recebido as mesnagens 401 ou 403  */}

      {LoginError &&
        <Alert message={errorMessage} />
      }
    
      <div style={{marginTop: "2vh", display: 'flex', flexDirection: 'column' , width: '100%'}}>
        <Input content="Usuario" value={usuario} onChange={handleUsuarioChange} ></Input>
        <Input type="password" content="Senha" value={senha} onChange={handleSenhaChange}></Input>
      </div>
      {/* Div botão acesso */}
      
      {isLoading ? (
        <div style={{ marginTop: "3vh" }}>
          <Oval stroke="#9E3FFD" />
        </div>
      ) : (
          <div style={{ marginTop: "4vh", display: 'flex', width: '100%', justifyContent: 'center' }}>
            <Button title="Login" width="100%" height="5vh" onClick={handleSubmit}></Button>
          </div>
      )} 

      <div style={{ marginTop: "3vh", display: 'flex', flexDirection: 'column',  width: '100%', justifyContent: 'center' }}>
        <p className='regulatText'>Não possui uma conta ainda?</p>
        <a className='loginLink' ></a>
        <a className='loginLink' onClick={handleShowCadastro}>Clique aqui e cadastre-se</a>
        <Link to="/recuperarSenha" style={{textDecoration: "none"}}>
          <a className='loginLink'>Esqueci minha senha</a>
        </Link>
        
      </div>
    </div>
  );
}

export default BoxInputLogin;
