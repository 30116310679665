import './BoxProduct.css';
import logoTransportadora from "../../images/image5.png"
import Button from '../Button/Button';

function BoxResultProduto(props) {
    return (
        <div className='BoxResultProduto'>
            <div style={{ marginTop: '1vh', display: 'flex', flexDirection: 'column' }}>
                <a className='titleResultProduto'>{props.nomeProduto}</a>
            </div>

            <div style={{ marginTop: '1vh', display: 'flex', flexDirection: 'column' , padding: '1vh' }} >
                <p>{props.content}</p>
            </div>

            <div style={{ marginTop: '3vh', display: 'flex', flexDirection: 'column' , margin: '2vh'}}>
                <Button title="Converse com um analista" width="100%" height="4vh"></Button>
            </div>
        </div>

    );
}

export default BoxResultProduto;
