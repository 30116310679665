import './PageCadastro.css';
import BoxInputCadastro from '../../../Components/BoxInputCadastro/BoxInputCadastro';
import { useEffect } from 'react';

function PageCadastro() {

  return (
    <div className='PageCadastro' style={{border: '1px solid green', backgroundColor: 'red'}}>
        <BoxInputCadastro></BoxInputCadastro>
    </div>
  );
}

export default PageCadastro;
