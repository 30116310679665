import TitlePage from "../../../Components/TitlePage/TitlePage";
import InputCity from "../../../Components/InputCity/InputCity";
import Input from "../../../Components/Input/Input";
import { useState, useEffect } from "react";
import Button from "../../../Components/Button/Button";
import { deleteFreightLotacao, insertFreightLotacao } from "../../../Service/TransportadoraService";
import Oval from "react-loading-icons/dist/esm/components/oval";
import Alert from '../../../Components/Alert/Alert';
import { getFreightLotacao, editFreightLotacao } from "../../../Service/TransportadoraService";
import Base64Image from "../../../Components/Base64Image/Base64Image";
import { FaRegClock } from "react-icons/fa";

function converterParaUnixTimestamp(dataEnvio, horarioEnvio) {
  // Combine a data e o horário em uma string no formato "AAAA-MM-DDTHH:MMZ"
  const dataHoraString = `${dataEnvio}T${horarioEnvio}Z`;

  console.log("data hora string -> ", dataHoraString)
  const dataHora = new Date(dataHoraString);
  const dataHoraUTC = new Date(
    dataHora.getUTCFullYear(),
    dataHora.getUTCMonth(),
    dataHora.getUTCDate(),
    dataHora.getUTCHours(),
    dataHora.getUTCMinutes(),
    dataHora.getUTCSeconds()
  );

  // Obtenha o Unix timestamp em segundos
  const unixTimestamp = Math.floor(dataHoraUTC.getTime() / 1000);

  return unixTimestamp
}

function formatUnixTime(unixTime) {
  // Crie um objeto Date com base no Unix time (em milissegundos)
  const date = new Date(unixTime * 1000);

  // Obtenha os componentes da data
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Adicione 1 ao mês, pois os meses em JavaScript são zero-based
  const year = date.getFullYear();

  // Construa a string no formato desejado
  const formattedDate = `${hours}:${minutes} ${day}/${month}/${year}`;

  return formattedDate;
}


function PageTabelaLotacao() {

  const [requestCriaFrete, setCriaFrete] = useState({
    idTransportadora: JSON.parse(localStorage.dataUser)._id,
    cidadeOrigem: "",
    cidadeDestino: "",
    pesoPacote: "",
    valor: "",
    materiais: [],
    veiculos: [],
    dataEnvio: "",
    horarioEnvio: "",
    unixTime: 0,
    observacao: "",
  });

  const [maskCotacao, setMaskCotacao] = useState({
    cidadeOrigem: "",
    cidadeDestino: "",
    pesoPacote: "",
    valor: "",
    dataEnvio: "",
    horarioEnvio: "",
    observacao: "",
  });

  const [isNegotiable, setIsNegotiable] = useState(false);
  const [checkboxStyle, setCheckboxStyle] = useState({
    backgroundColor: "",
    value: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState({
    color: "",
    message: ""
  });
  const [arrayPoiscaoVeiculos, setArrayPoiscaoVeiculos] = useState([]);
  const [arrayPoiscaoMateriais, setArrayPoiscaoMateriais] = useState([]);
  const [arrayLotacao, setArrayLotacao] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false);

  const [objModal, setObjModal] = useState({
    idTransportadora: JSON.parse(localStorage.dataUser)._id,
    cidadeOrigem: "",
    cidadeDestino: "",
    pesoPacote: "",
    valor: "",
    materiais: [],
    veiculos: [],
    dataEnvio: "",
    horarioEnvio: "",
    unixTime: 0,
  })

  const arrayVeiculos = [
    "Carreta",
    "Carreta LS",
    "Trucado",
    "Van",
    "Moto",
    "Picape",
    "Baú",
    "Cegonha",
    "Gaiola",
    "Baú frigorífico",
    "Baú refrigerado",
    "Caçamba",
    "Graneleiro",
  ];

  const arrayMateriais = [
    "Aço",
    "Aluminio",
    "Madeira",
    "Veiculo",
    "Tecido",
    "Animal vivo",
    "Animal abatido",
    "Joia",
    "Móveis",
    "Eletrodomésticos",
    "órgãos humanos",
  ];

  function maskKg(string) {
    if (string == "") {
      string = " kg";
    }
    var onlyNumber;
    // Caso esteja apagando
    if (string.slice(-1) == "k") {
      onlyNumber = string.substring(0, string.length - 3);
    }
    // Caso esteja escrevendo
    if (string.slice(-1) != "k") {
      onlyNumber = string.replace(" kg", "");
    }
    onlyNumber = onlyNumber.replace(".", "");
    var decimalNumber = onlyNumber.substring(
      onlyNumber.length - 2,
      onlyNumber.length
    );
    var intNumber = onlyNumber.substring(0, onlyNumber.length - 2);
    if (intNumber == "") {
      return "0." + decimalNumber + " kg";
    }
    if (intNumber[0] == 0 && (intNumber[1] == 0) & (intNumber.length > 2)) {
      intNumber = intNumber.substring(2, intNumber.length);
    }
    if (intNumber[0] == 0 && intNumber.length > 1) {
      intNumber = intNumber.substring(1, intNumber.length);
    }
    return intNumber + "." + decimalNumber + " kg";
  }

  function maskRs(string) {
    if (string == "") {
      string = "R$ 00.00";
    }

    var onlyNumber = string.replace("R$ ", "");
    onlyNumber = onlyNumber.replace(".", "");
    var intNumber = onlyNumber.substring(0, onlyNumber.length - 2);
    var decimalNumber = onlyNumber.substring(
      onlyNumber.length - 2,
      onlyNumber.length
    );

    if (intNumber[0] == 0 && (intNumber[1] == 0) & (onlyNumber.length > 2)) {
      intNumber = intNumber.substring(2, intNumber.length);
    }
    return "R$ " + intNumber + "." + decimalNumber;
  }

  async function handleClick() {
    setIsLoading(true)
    let dataUnixTime = converterParaUnixTimestamp(
      requestCriaFrete.dataEnvio,
      requestCriaFrete.horarioEnvio
    );
    let req = requestCriaFrete;

    // verifica se o valor é negociavel
    const valor = typeof (req.valor) == "string" ? 0 : req.valor
    const respInsert = await insertFreightLotacao(
      req.idTransportadora,
      JSON.parse(localStorage.dataUser).nome,
      JSON.parse(localStorage.dataUser).imagePerfil,
      JSON.parse(localStorage.dataUser).telefone,
      req.cidadeOrigem,
      req.cidadeDestino,
      req.pesoPacote,
      valor,
      req.materiais,
      req.veiculos,
      dataUnixTime,
      req.observacao,
    );
    console.log(respInsert)

    if (respInsert.errors) {
      setStatusMessage({
        color: "yellow",
        message: "Todos os campos marcados com '*' são obrigatórios"
      })
      setIsLoading(false);
      return 0;
    }

    setStatusMessage({
      color: "green",
      message: "O anuncio de frete foi criado com sucesso!"
    })

    setCriaFrete({
      idTransportadora: JSON.parse(localStorage.dataUser)._id,
      cidadeOrigem: "",
      cidadeDestino: "",
      pesoPacote: "",
      valor: "",
      materiais: [],
      veiculos: [],
      dataEnvio: "",
      horarioEnvio: "",
      unixTime: 0,
    })

    setMaskCotacao({
      cidadeOrigem: "",
      cidadeDestino: "",
      pesoPacote: "",
      valor: "",
      dataEnvio: "",
      horarioEnvio: "",
    })

    setIsLoading(false);
  }

  const handleClickDelete = async function(obj) {
    let respDelete = await deleteFreightLotacao(obj)
    setShowEditModal(false)
    setStatusMessage({
      color: "red",
      message: "Frete apagado com sucesso"
    })
    setIsLoading(true)
    getFreightLotacao().then(resp => {
      // Função para verificar se dois objetos são iguais
      function isObjectEqual(obj1, obj2) {
        // Você precisa implementar uma lógica para comparar os objetos aqui.
        // Suponhamos que os objetos têm uma propriedade 'id' que deve ser única.
        return obj1._id === obj2._id;
      }

      const filteredArray = resp.filter(item => item.idTransportadora == JSON.parse(localStorage.dataUser)._id);

      // Filtrar o array para remover objetos duplicados
      const uniqueArray = filteredArray.filter((item, index, self) => {
        return self.findIndex(obj => isObjectEqual(obj, item)) === index;
      });

      setArrayLotacao(uniqueArray);
      setIsLoading(false)

    })
  }


  async function handleClickAtualizar(obj) {
    setIsLoading(true)
    let dataUnixTime = converterParaUnixTimestamp(
      obj.dataEnvio,
      obj.horarioEnvio
    );
    let req = requestCriaFrete;

    let objFormatado = {
      "_id": objModal._id,
      'idTransportadora': obj.idTransportadora,
      "nome": JSON.parse(localStorage.dataUser).nome,
      "imagePerfil": JSON.parse(localStorage.dataUser).imagePerfil,
      "telefone": JSON.parse(localStorage.dataUser).telefone,
      "cidadeOrigem": obj.cidadeOrigem,
      "cidadeDestino": obj.cidadeDestino,
      "pesoPacote": obj.pesoPacote,
      "valor": obj.valor,
      "materiais": obj.materiais,
      "veiculos": obj.veiculos,
      "horarioEnvio": dataUnixTime,
      "observacao": obj.observacao,

    }

    console.log("objFormatado -> ", objFormatado)

    // verifica se o valor é negociavel
    const valor = typeof (req.valor) == "string" ? 0 : req.valor
    const respInsert = await editFreightLotacao(objFormatado);
    console.log("resp edit -> ", respInsert)
    getFreightLotacao().then(resp => {
      // Função para verificar se dois objetos são iguais
      function isObjectEqual(obj1, obj2) {
        // Você precisa implementar uma lógica para comparar os objetos aqui.
        // Suponhamos que os objetos têm uma propriedade 'id' que deve ser única.
        return obj1._id === obj2._id;
      }

      const filteredArray = resp.filter(item => item.idTransportadora == JSON.parse(localStorage.dataUser)._id);

      // Filtrar o array para remover objetos duplicados
      const uniqueArray = filteredArray.filter((item, index, self) => {
        return self.findIndex(obj => isObjectEqual(obj, item)) === index;
      });

      setArrayLotacao(uniqueArray);
      setIsLoading(false)

    })

    if (respInsert.errors) {
      setStatusMessage({
        color: "yellow",
        message: "Todos os campos marcados com '*' são obrigatórios"
      })
      setIsLoading(false);
      return 0;
    }

    setStatusMessage({
      color: "green",
      message: "O anuncio de frete foi atualizado com sucesso!"
    })

    setCriaFrete({
      idTransportadora: JSON.parse(localStorage.dataUser)._id,
      cidadeOrigem: "",
      cidadeDestino: "",
      pesoPacote: "",
      valor: "",
      materiais: [],
      veiculos: [],
      dataEnvio: "",
      horarioEnvio: "",
      unixTime: 0,
    })

    setMaskCotacao({
      cidadeOrigem: "",
      cidadeDestino: "",
      pesoPacote: "",
      valor: "",
      dataEnvio: "",
      horarioEnvio: "",
    })
    setShowEditModal(false)
    setIsLoading(false);
  }

  const changeModalState = (itemLotacao) => {
    console.log("entrou no change!", itemLotacao)
    setObjModal(itemLotacao)
    setShowEditModal(!showEditModal)
  }


  const handleCheckboxChange = () => {
    setCriaFrete({
      ...requestCriaFrete,
      valor: "negociavel",
    });

    setIsNegotiable(!isNegotiable);
    // Inverte o estado do checkbox
    // Atualiza o estado do backgroundColor e do value com base no estado atual do checkbox
    setCheckboxStyle({
      backgroundColor: isNegotiable ? "" : "#D0D0D0",
      value: isNegotiable ? "" : "-",
    });
  };

  const handleInputMaskChange = (event) => {
    const { name, value } = event.target;
    console.log("event no inputmask -> ", event.target);
    setMaskCotacao({
      ...maskCotacao,
      [name]: value,
    });
    if (name == "pesoPacote") {
      const valorNum = parseFloat(maskKg(value).replace(" kg", ""));
      return setCriaFrete({
        ...requestCriaFrete,
        [name]: valorNum,
      });
    }
    if (name == "valor") {
      const valorNum = parseFloat(maskKg(value).replace("R$ ", ""));
      return setCriaFrete({
        ...requestCriaFrete,
        [name]: valorNum,
      });
    }
    return setCriaFrete({
      ...requestCriaFrete,
      [name]: value,
    });
  };

  const handleTagClick = (index) => {
    // Verifica se o índice da tag já está no array de itens selecionados
    if (arrayPoiscaoVeiculos.includes(index)) {
      // Se estiver, remove o índice do array
      const updatedArray = arrayPoiscaoVeiculos.filter(
        (item) => item !== index
      );
      setArrayPoiscaoVeiculos(updatedArray);
      return setCriaFrete({
        ...requestCriaFrete,
        veiculos: updatedArray,
      });
    } else {
      // Se não estiver, adiciona o índice ao array
      setArrayPoiscaoVeiculos([...arrayPoiscaoVeiculos, index]);
      let arrayRef = arrayPoiscaoVeiculos;
      arrayRef.push(index);
      return setCriaFrete({
        ...requestCriaFrete,
        veiculos: arrayRef,
      });
    }
  };

  const handleTagClickMateriais = (index) => {
    // Verifica se o índice da tag já está no array de itens selecionados
    if (arrayPoiscaoMateriais.includes(index)) {
      // Se estiver, remove o índice do array
      const updatedArray = arrayPoiscaoMateriais.filter(
        (item) => item !== index
      );
      setArrayPoiscaoMateriais(updatedArray);
      setCriaFrete({
        ...requestCriaFrete,
        materiais: updatedArray,
      });
    } else {
      // Se não estiver, adiciona o índice ao array
      setArrayPoiscaoMateriais([...arrayPoiscaoMateriais, index]);
      let arrayRef = arrayPoiscaoMateriais;
      arrayRef.push(index);

      setCriaFrete({
        ...requestCriaFrete,
        materiais: arrayRef,
      });
    }
  };

  const handleSearchTermChange = (newSearchTerm) => {
    setCriaFrete({
      ...requestCriaFrete,
      cidadeOrigem: newSearchTerm,
    });
  };

  const handleSearchTermChangeDestino = (newSearchTerm) => {
    setCriaFrete({
      ...requestCriaFrete,
      cidadeDestino: newSearchTerm,
    });
  };

  useEffect(() => {
    setIsLoading(true)
    getFreightLotacao().then(resp => {
      // Função para verificar se dois objetos são iguais
      function isObjectEqual(obj1, obj2) {
        // Você precisa implementar uma lógica para comparar os objetos aqui.
        // Suponhamos que os objetos têm uma propriedade 'id' que deve ser única.
        return obj1._id === obj2._id;
      }

      const filteredArray = resp.filter(item => item.idTransportadora == JSON.parse(localStorage.dataUser)._id);

      // Filtrar o array para remover objetos duplicados
      const uniqueArray = filteredArray.filter((item, index, self) => {
        return self.findIndex(obj => isObjectEqual(obj, item)) === index;
      });

      setArrayLotacao(uniqueArray);
      setIsLoading(false)

    })

  }, []);


  return (
    <div className="PageTesteCotacao">
      <TitlePage
        title="Crie um frete do tipo lotação"
        subTitle="Defina todos os detalhes do transporte, para que assim o cliente encontre o frete ideal"
      ></TitlePage>
      {isLoading ? (
        <Oval stroke="#9E3FFD" />
      ) : (
        <div className="whitecard" >

          {
            statusMessage.message.length > 0 ? (
              <Alert color={statusMessage.color} message={statusMessage.message}></Alert>
            ) : (<></>)
          }

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "0px 40px",
            }}
          >
            <InputCity
              width="200px"
              content="cidade de origem *"
              name="cidadeOrigem"
              onSearchTermChange={handleSearchTermChange}
            ></InputCity>
            <InputCity
              width="200px"
              content="cidade de destino *"
              name="cidadeDestino"
              onSearchTermChange={handleSearchTermChangeDestino}
            ></InputCity>

            <Input
              content="Peso máximo *"
              width="111px"
              name="pesoPacote"
              value={maskKg(maskCotacao.pesoPacote)}
              onChange={handleInputMaskChange}
            ></Input>

            <div
              style={{
                display: "flex",
                width: "120px",
                flexDirection: "column",
              }}
            >
              <Input
                content="Valor do frete"
                width="100%"
                name="valor"
                value={isNegotiable ? "Negociavel" : maskRs(maskCotacao.valor)}
                onChange={handleInputMaskChange}
                backgroundColor={checkboxStyle.backgroundColor}
              // Define o backgroundColor com base no estado
              ></Input>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <input
                  type="checkbox"
                  id="scales"
                  name="scales"
                  checked={isNegotiable}
                  // Define o estado do checkbox
                  onChange={handleCheckboxChange}
                // Manipulador de eventos para o checkbox
                />
                <label
                  for="scales"
                  style={{
                    color: "grey",
                    fontSize: "0.7rem",
                  }}
                >
                  Preço a negociar
                </label>
              </div>
            </div>

            <div style={{ width: `100%` }}></div>

            <Input
              content="Data do envio *"
              width="120px"
              name="dataEnvio"
              value={maskCotacao.dataEnvio}
              onChange={handleInputMaskChange}
              type="date"
            ></Input>


            <Input
              content="Horario *"
              width="80px"
              name="horarioEnvio"
              value={maskCotacao.horarioEnvio}
              onChange={handleInputMaskChange}
              type="time"
            ></Input>


            <Input
              content="Observacao"
              width="300px"
              name="observacao"
              value={maskCotacao.observacao}
              onChange={handleInputMaskChange}
              type="text"
            ></Input>

            <div style={{ width: `100%` }}></div>
            <div
              style={{
                width: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                textAlign: "left",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  color: "grey",
                  fontSize: "0.8rem",
                  marginBottom: "2px",
                }}
              >
                Tipos de carroceria
              </p>
              <div className="divTags">
                {arrayVeiculos.map((tag, index) => (
                  <div
                    className={
                      arrayPoiscaoVeiculos.includes(index)
                        ? "itemTagActive"
                        : "itemTag"
                    }
                    key={index}
                    onClick={() => handleTagClick(index)}
                  >
                    <p>{tag}</p>
                  </div>
                ))}{" "}
              </div>
            </div>
            <div
              style={{
                width: "45%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "left",
                textAlign: "left",
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  color: "grey",
                  fontSize: "0.8rem",
                  marginBottom: "2px",
                }}
              >
                Tipo de material
              </p>
              <div className="divTags">
                {arrayMateriais.map((tag, index) => (
                  <div
                    className={
                      arrayPoiscaoMateriais.includes(index)
                        ? "itemTagActive"
                        : "itemTag"
                    }
                    key={index}
                    onClick={() => handleTagClickMateriais(index)}
                  >
                    <p>{tag}</p>
                  </div>
                ))}{" "}
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "20px",
              }}
            >
              <Button
                title="Criar frete!"
                width="300px"
                onClick={handleClick}
              ></Button>
            </div>
          </div>
        </div>
      )}

      <div style={{ marginTop: '50px' }}></div>
      <TitlePage
        title="Confira seus fretes de lotação"
        subTitle="Veja aqui todos os fretes do tipo lotação , caso queira editar algum basta clicar"
      ></TitlePage>
      {showEditModal ? (
        <div style={{ position: 'fixed', top: '0', width: '100%', height: '100%', backgroundColor: 'rgb(0, 0, 0, 0.5)', right: '0', zIndex: '10', display: 'flex', justifyContent: 'center', paddingTop: '30px' }}>
          {isLoading ? (
            <Oval stroke="#9E3FFD" />
          ) : (
            <div className="whitecard" style={{ width: "90%"}}>
              <h1 style={{ margin: '0', lineHeight: '0', textAlign: 'end', marginTop: '10px', cursor: 'pointer', fontSize: '20px' }} onClick={() => changeModalState()}>X</h1>
              {
                statusMessage.message.length > 0 ? (
                  <Alert color={statusMessage.color} message={statusMessage.message}></Alert>
                ) : (<></>)
              }

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "0px 40px",
                }}
              >


                <InputCity
                  width="200px"
                  content="cidade de origem *"
                  name="cidadeOrigem"

                  onSearchTermChange={handleSearchTermChange}
                ></InputCity>
                <InputCity
                  width="200px"
                  content="cidade de destino *"
                  name="cidadeDestino"
                  onSearchTermChange={handleSearchTermChangeDestino}
                ></InputCity>

                <Input
                  content="Peso máximo *"
                  width="111px"
                  name="pesoPacote"
                  value={maskKg(maskCotacao.pesoPacote)}
                  onChange={handleInputMaskChange}
                ></Input>

                <div
                  style={{
                    display: "flex",
                    width: "120px",
                    flexDirection: "column",
                  }}
                >
                  <Input
                    content="Valor do frete"
                    width="100%"
                    name="valor"
                    value={isNegotiable ? "Negociavel" : maskRs(maskCotacao.valor)}
                    onChange={handleInputMaskChange}
                    backgroundColor={checkboxStyle.backgroundColor}
                  // Define o backgroundColor com base no estado
                  ></Input>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <input
                      type="checkbox"
                      id="scales"
                      name="scales"
                      checked={isNegotiable}
                      // Define o estado do checkbox
                      onChange={handleCheckboxChange}
                    // Manipulador de eventos para o checkbox
                    />
                    <label
                      for="scales"
                      style={{
                        color: "grey",
                        fontSize: "0.7rem",
                      }}
                    >
                      Preço a negociar
                    </label>
                  </div>
                </div>

                <div style={{ width: `100%` }}></div>

                <Input
                  content="Data do envio *"
                  width="120px"
                  name="dataEnvio"
                  value={maskCotacao.dataEnvio}
                  onChange={handleInputMaskChange}
                  type="date"
                ></Input>


                <Input
                  content="Horario *"
                  width="80px"
                  name="horarioEnvio"
                  value={maskCotacao.horarioEnvio}
                  onChange={handleInputMaskChange}
                  type="time"
                ></Input>


                <Input
                  content="Observacao"
                  width="300px"
                  name="observacao"
                  value={maskCotacao.observacao}
                  onChange={handleInputMaskChange}
                  type="text"
                ></Input>

                <div style={{ width: `100%` }}></div>
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "grey",
                      fontSize: "0.8rem",
                      marginBottom: "2px",
                    }}
                  >
                    Tipos de carroceria
                  </p>
                  <div className="divTags">
                    {arrayVeiculos.map((tag, index) => (
                      <div
                        className={
                          arrayPoiscaoVeiculos.includes(index)
                            ? "itemTagActive"
                            : "itemTag"
                        }
                        key={index}
                        onClick={() => handleTagClick(index)}
                      >
                        <p>{tag}</p>
                      </div>
                    ))}{" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "45%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "left",
                    textAlign: "left",
                    marginTop: "20px",
                  }}
                >
                  <p
                    style={{
                      color: "grey",
                      fontSize: "0.8rem",
                      marginBottom: "2px",
                    }}
                  >
                    Tipo de material
                  </p>
                  <div className="divTags">
                    {arrayMateriais.map((tag, index) => (
                      <div
                        className={
                          arrayPoiscaoMateriais.includes(index)
                            ? "itemTagActive"
                            : "itemTag"
                        }
                        key={index}
                        onClick={() => handleTagClickMateriais(index)}
                      >
                        <p>{tag}</p>
                      </div>
                    ))}{" "}
                  </div>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    marginTop: "40px",
                  }}
                >
                  <Button
                    title="Atualizar anuncio de frete!"
                    width="300px"
                    onClick={() => handleClickAtualizar(requestCriaFrete)}
                  ></Button>

                  <button
                    style={{ cursor: 'pointer', backgroundColor: 'red', color: 'white', width: '300px', borderRadius: '10px', height: '40px', border: 'none', fontWeight: '600', marginLeft: '20px' }}
                    onClick={() => handleClickDelete(objModal)}
                  >Apagar anuncio de frete</button>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (<></>)}

      {isLoading ? (
        <Oval stroke="#9E3FFD" />
      ) : (
        <div style={{ width: `100%` }}>
          {
            arrayLotacao.map(itemLotacao =>
              <div className='divResultLotacao' onClick={() => changeModalState(itemLotacao)}>
                <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
                  <div className="divInfoUser">
                    <Base64Image base64Data={itemLotacao.imagePerfil} width="70" height="70" borderRadius="5px"></Base64Image>
                    <p style={{ margin: `0px`, fontSize: `10px` }}>{itemLotacao.nome}</p>
                  </div>

                  <div className="divCidadesLotacao">
                    <p style={{ margin: `0` }}>{itemLotacao.cidadeOrigem}</p>
                    <i className="arrowDown"></i>
                    <p style={{ margin: `0` }}>{itemLotacao.cidadeDestino}</p>
                  </div>

                  <div className="divTagsLotacao">
                    <p className="titleDiv">Materiais permitidos</p>
                    {
                      itemLotacao.materiais.map(material => (
                        <div className="itemTagLotacao">
                          <p>{arrayMateriais[material]}</p>
                        </div>
                      ))
                    }
                    <p className="titleDiv" style={{ marginTop: "20px" }}>Veiculos disponiveis</p>
                    {
                      itemLotacao.veiculos.map(material => (
                        <div className="itemTagLotacao">
                          <p>{arrayVeiculos[material]}</p>
                        </div>
                      ))
                    }
                  </div>

                  <div className="divObservacao">
                    <p className="titleDiv">Observacão</p>
                    <h6>{itemLotacao.observacao}</h6>
                  </div>

                  <div className="divHoraRestante" style={{alignSelf: 'center'}}>
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                      <FaRegClock />
                    </div>
                    <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center', flexDirection: 'column', width: '100%' }}>
                      {formatUnixTime(itemLotacao.horarioEnvio)}
                    </div>


                  </div>
                  <div className="divPreco">
                    <h4>{(itemLotacao.valor == 0 || itemLotacao.valor == null)? "Negociável" : ("R$ " + itemLotacao.valor.toFixed(2))}</h4>
                  </div>
                </div>

                <div style={{ lineHeight: '0', margin: '0', fontSize: '10px'}}>
                  <p>clique para editar o anuncio de frete</p>
                </div>

              </div>
            )
          }
        </div>
      )}

    </div>
  );
}
export default PageTabelaLotacao;
