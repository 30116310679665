import React from "react";
import { Route, BrowserRouter, Routes, Router } from "react-router-dom";

import PageLogin from "../pages/Login/Login/PageLogin";
import PageCadastro from "../pages/Login/Cadastro/PageCadastro";
import PageConfirmarEmail from "../pages/Login/ConfirmarEmail/PageConfirmarEmail";
import PageRecuperarSenha from "../pages/Login/RecuperarSenha/PageRecuperarSenha";
import PagePassword from '../pages/Login/Password/PagePassword'

const TransportadoraRoutes = () => {
   return(
        <Routes>
            <Route element={<PageLogin />} path="" />
            <Route element={<PageCadastro />} path="/register" />
            <Route element={<PageRecuperarSenha />} path="/recuperarSenha" />
            <Route element={<PageConfirmarEmail />} path={"/confirmEmail/:emailUrl"} exact />
        </Routes> 
   )
}

export default TransportadoraRoutes;