import { FaMoneyBill, FaTags, FaSearch, FaTruck, FaRegNewspaper } from 'react-icons/fa';
import { MdSupportAgent } from 'react-icons/md';
import './HomeTransportadora.css';
import { ServiceCard } from '../../../Components/ServiceCard';
import TitlePage from '../../../Components/TitlePage/TitlePage';
import { getMensagem } from '../../../Service/MensagemService';
import Table from '../../../Components/Table/Table';
import { useState, useEffect } from 'react';

function HomeTransportadora() {

   
    const [dadosCotacao, setDadosCotacao] = useState([])
    const colunasCotacao = ['cep origem', 'cep destino', 'tipo de frete', 'valor', 'cliente', 'data'];
    
    
    useEffect(() => { 
        getMensagem(JSON.parse(localStorage.dataUser)._id).then(resp => {
            console.log("resposta do getMensagnes ", resp);
    
            // Crie um conjunto temporário para rastrear elementos únicos
            const elementosUnicos = new Set(dadosCotacao.map(item => JSON.stringify(item)));
    
            resp.forEach(element => {
                // Verifica se o elemento já existe no conjunto temporário
                const elementoExistente = elementosUnicos.has(JSON.stringify({
                    'cep origem': element.cotacao.cepOrigem,
                    'cep destino': element.cotacao.cepDestino,
                    'tipo de frete': element.cotacao.tipoFrete,
                    'valor': element.cotacao.valor,
                    'cliente': element.cotacao.cliente,
                    'data': element.cotacao.data
                }));
    
                // Se o elemento não existir no conjunto temporário, adicione-o
                if (!elementoExistente && element.cotacao.cliente != JSON.parse(localStorage.dataUser).nome) {
                    elementosUnicos.add(JSON.stringify({
                        'cep origem': element.cotacao.cepOrigem,
                        'cep destino': element.cotacao.cepDestino,
                        'tipo de frete': element.cotacao.tipoFrete,
                        'valor': element.cotacao.valor,
                        'cliente': element.cotacao.cliente,
                        'data': element.cotacao.data
                    }));
                }
            });
    
            // Converta o conjunto temporário de volta em uma matriz e atualize o estado
            setDadosCotacao(Array.from(elementosUnicos).map(item => JSON.parse(item)));
        });
    }, []);

    

    const handleLinhaClicada = (dados) => {
        const mensagem = encodeURIComponent('Aqui é a transportadora ' + JSON.parse(localStorage.dataUser).nome + ', gostariamos de negociar com o cliente ' + dados.cliente + ' o frete com cep origem: ' + dados["cep origem"] + ' & destino: ' + dados['cep destino'])
        window.open('https://wa.me//5511945122317?text='+mensagem, '_blank').focus();
        console.log('dados da linha -> ', dados);
      };

    return (
        <div className="HomeTransportadora">

            <div style={{marginBottom: '60px'}}>
            <TitlePage title="Cotações realizadas" subTitle="Veja os clientes que realizaram uma cotação com vocês" />
            <Table dados={dadosCotacao} colunas={colunasCotacao} botao={'Negociar com cliente'} onLinhaClicada={handleLinhaClicada}></Table>
            
            </div>
        
            <TitlePage title="Conheça nossos serviços especiais" subTitle="A logiflow possui um time altamente especialiazado em entregas, veja como podemos te ajudar" />
            <div style={{ display: 'flex', flexDirection: "row", gap: "2vw 3vh", width: '100%', flexWrap: 'wrap' }}>

                <ServiceCard Icon={<MdSupportAgent size={50} color=" #9E3FFD" />} title={"Integração e conectividade"}
                    
                    text={"A logiflow integra o seu sistema de transportadora à um hub enorme de plataformas, fornecendo uma conexão direta às maiores lojas virtuais e market places (magazine luiza, amazon, mercado livre)."}
                    customButtonText="Quero integrar meu sistema" />

                <ServiceCard Icon={<FaRegNewspaper size={50} color=" #9E3FFD" />} title={"Seguro de Cargas"}
                    redirect="/seguro"
                    text={"Nossa parceria com as seguradoras foi projetado para proporcionar tranquilidade e proteção financeira às transportadoras, ao mesmo tempo em que reduz significativamente os gastos com seguros."}
                    customButtonText="Veja nossas seguradoras" />

                <ServiceCard Icon={<FaSearch size={50} color=" #9E3FFD" />} title={"Rede de Subcontratação"}
                    text={"Combinando expertise em logística e tecnologia avançada, oferecemos uma abordagem estratégica para a subcontratação, garantindo que as transportadoras alcancem o equilíbrio ideal entre recursos internos e externos."}
                    customButtonText="Converse com um analista" />

                <ServiceCard Icon={<FaSearch size={50} color=" #9E3FFD" />} title={"Auditoria & pagamentos"}
                    text={"A logiflow realiza auditorias nas faturas de frete, verificando sua precisão e garantindo o pagamento correto à transportadora"}
                    customButtonText="Converse com um auditor" />

                <ServiceCard Icon={<FaTruck size={50} color=" #9E3FFD" />} title={"Avaliação de riscos"}
                    text={"Avaliação detalhadas do  percurso e produtos e a ser transportados, a fim de estimar a melhor cotação de seguro para o cliente"}
                    customButtonText="Analise seu risco" />
                    
                <ServiceCard Icon={<FaTruck size={50} color=" #9E3FFD" />} title={"Serviço de Lotação"}
                    redirect="/teste-cotacao/lotacao"
                    text={"O serviço de transporte por lotação oferece a vantagem de dedicar um caminhão inteiro exclusivamente às suas mercadorias. Isso significa que sua carga não será compartilhada com outros remetentes, proporcionando maior segurança e controle sobre o transporte."}
                    customButtonText="Faça uma cotação" />
                    
                <ServiceCard Icon={<FaTruck size={50} color=" #9E3FFD" />} title={"Serviço de carga Fracionada"}
                    redirect="/teste-cotacao/fracionada"
                    text={"Com uma abordagem eficiente e flexível, nosso serviço de transporte fracionado oferece uma maneira econômica e confiável de enviar cargas menores, garantindo que suas mercadorias cheguem ao destino com segurança."}
                    customButtonText="Faça uma cotação" />
            </div>
        </div>
    );
}

export default HomeTransportadora;
