import React, { useState } from 'react';
import Button from '../Button/Button';
import './ServiceCard.css';
import ModalSuport from '../ModalSuport/ModalSuport';
import { useNavigate } from 'react-router-dom';

export function ServiceCard(props) {
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate()
  const openModal = () => {
    // setModalVisible(true);
    if(props.redirect){
      navigate(props.redirect)
      return
    }
    const mensagem = encodeURIComponent('Olá me chamo ' + JSON.parse(localStorage.dataUser).nome + ', gostaria de fazer um orçamento de ' + props.title)
    window.open('https://wa.me//5511945122317?text='+mensagem, '_blank').focus();
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  return (
    <div className="ServiceCardContainer">
      {modalVisible && <ModalSuport onClose={closeModal} />}
      <div className='ServiceCardHeader'>
        <div className="cardIcon">
          {props.Icon}
        </div>
        <div className="ServiceCardTitle">
          {props.title}
        </div>
      </div>
      <div className="cardInfoText">
        <p className="ServiceCardText">{props.text}</p>
      </div>
      <div className="divButton">
        <Button title={props.customButtonText} width="100%" height="4vh" onClick={openModal} />
      </div>
    </div>
  );
}
