import './TitlePage.css';

function TitlePage(props) {
  return (
    <div className="TitlePage">
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'left', alignContent: 'flex-start', marginBottom: '6vh'}}>
            <a className='titlePage'>{props.title}</a>
            <a className='subTitlePage'>{props.subTitle}</a>
        </div>
      
    </div>
  );
}

export default TitlePage;
